import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router";
import ExercisePreviewBox from "../components/ExercisePreviewBox";
import HomeworkBox from "../components/HomeworkBox";
import HomeworkStudentBox from "../components/HomeworkStudentBox";
import {
  deleteScheduledHomework,
  getExercisesFromList,
  getExerciseUrl,
  getHomeworkFromId,
  GetTeacherHomework,
  giveStudentFeedback,
} from "../firebase";
import { UserContext } from "../providers/UserProvider";

const HomeworkViewPage = () => {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  const [homework, setHomework] = useState({});
  const [scheduledHomework, setScheduledHomework] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [historyHomework, setHistoryHomework] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showMoreScheduled, setShowMoreScheduled] = useState(false);
  const [totalStudents, setTotalStudents] = useState(0);
  const [completedStudentsNumber, setCompletedStudentsNumber] = useState(0);
  const [currentStudent, setCurrentStudent] = useState({});

  const [completedStudents, setCompletedStudents] = useState([]);
  const [notCompletedStudents, setNotCompletedStudents] = useState([]);

  const [exerciseName, setExerciseName] = useState("");
  const [showExercise, setShowExercise] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const [gifUrl, setGifUrl] = useState("");
  let { id } = useParams();
  const testDetails = {
    Title: "Test",
    Completed: "0/20",
    DueDate: "Wed 07",
  };
  useEffect(() => {
    document.title = "Daly Exercise+ | Homework View";
    if ("UserName" in data) {
      getHomeworkFromId(data.ClassId, id).then((res) => {
        setHomework(res);

        getExercisesFromList(res).then((exerciseVals) => {
          setExercises(exerciseVals);
        });
        getCompletedStudents(res);
      });
    }
  }, [data]);

  const getCompletedStudents = (homework) => {
    let completedCount = 0;
    if ("Status" in homework) {
      let status = homework.Status;
      let students = Object.entries(status).length;
      setTotalStudents(students);

      Object.entries(status).forEach((i) => {
        if (i[1].Status) {
          completedCount++;
          completedStudents.push(i[0]);
        } else {
          notCompletedStudents.push(i[0]);
        }
      });
    }

    setCompletedStudentsNumber(completedCount);
  };
  const goToRandom = () => {
    navigate("random");
  };

  const goToCustom = () => {
    navigate("custom");
  };

  const goToRecommend = () => {
    navigate("recommend");
  };

  const deleteHomework = async () => {
    await deleteScheduledHomework(data.ClassId, homework.Guid);
    navigate("/homework");
  };

  const changeSelectedExercise = async (exercise) => {
    let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
    setGifUrl(url);
    setExerciseName(exercise.Name);
    setShowExercise(true);
  };

  const handleCloseExercise = () => {
    setShowExercise(false);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const handleCloseFeedback = () => {
    setShowFeedback(false);
  };

  const feedbackClicked = (student) => {
    setCurrentStudent(student);
    setShowFeedback(true);
  };

  const deleteClicked = () => {
    setShowDelete(true);
  };

  const viewHomeworkExercises = () => {
    navigate("view");
  };

  const giveFeedbackToStudent = async (type) => {
    homework.Status[currentStudent.Guid].Feedback = type;
    await giveStudentFeedback(
      currentStudent.Guid,
      currentStudent.ClassId,
      type,
      id
    );
    handleCloseFeedback();
  };
  return (
    <Container className="full-page-length">
      <Modal
        show={showFeedback}
        onHide={handleCloseFeedback}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Give Feedback to ${currentStudent.FirstName} ${currentStudent.LastName}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Row>
              <Col
                className="d-flex justify-content-center grow"
                onClick={() => giveFeedbackToStudent("Heart")}
              >
                <img src="/icons/icon_heart.png" width="200" height="200" />
              </Col>
              <Col
                className="d-flex justify-content-center grow"
                onClick={() => giveFeedbackToStudent("WowText")}
              >
                <img src="/icons/icon_wowtext.png" width="200" height="200" />
              </Col>
            </Row>
            <Row>
              <Col
                className="d-flex justify-content-center grow"
                onClick={() => giveFeedbackToStudent("100Text")}
              >
                <img src="/icons/icon_100text.png" width="200" height="200" />
              </Col>
              <Col
                className="d-flex justify-content-center grow"
                onClick={() => giveFeedbackToStudent("ThumbsUp")}
              >
                <img src="/icons/icon_thumbsup.png" width="200" height="200" />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseFeedback}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Are you sure?`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {`Are you sure you wish to delete this homework?`}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button variant="danger" onClick={() => deleteHomework()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showExercise}
        onHide={handleCloseExercise}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {exerciseName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <ReactPlayer url={gifUrl} loop={true} playing={true} mute={true} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseExercise}>Close</Button>
        </Modal.Footer>
      </Modal>
      <h1 className="pageTitle title-margin">{homework.Title}</h1>
      <hr />

      <Stack direction="horizontal">
        <h3 className="headingColour">
          <b>Exercises</b>
        </h3>
        <div variant="primary" className="ms-auto headingColour fs-3">
          Sets x{homework.Sets}
        </div>
      </Stack>
      {exercises.map((x, i) => {
        return (
          <ExercisePreviewBox
            exercise={x}
            key={i}
            PreviewExercise={changeSelectedExercise}
          />
        );
      })}
      <div className="d-grid">
        <Button
          variant="primary mainButton"
          onClick={() => viewHomeworkExercises()}
          size="lg"
        >
          Try Workout
        </Button>
      </div>
      <hr />
      <Stack direction="horizontal">
        <h3 className="headingColour">
          <b>To Return</b>
        </h3>
        <div variant="primary" className="ms-auto headingColour fs-3">
          {totalStudents - completedStudentsNumber + "/" + totalStudents}
        </div>
      </Stack>
      {notCompletedStudents.map((student, i) => {
        return <HomeworkStudentBox Guid={student} key={i} Completed={false} />;
      })}
      <hr />
      <Stack direction="horizontal">
        <h3 className="headingColour">
          <b>Completed</b>
        </h3>

        <div variant="primary" className="ms-auto headingColour fs-3">
          {completedStudentsNumber + "/" + totalStudents}
        </div>
      </Stack>
      {completedStudents.map((student, i) => {
        return (
          <HomeworkStudentBox
            Guid={student}
            key={i}
            Completed={true}
            GiveFeedback={feedbackClicked}
            Feedback={homework ? homework.Status[student].Feedback : ""}
            TrafficLight={homework ? homework.Status[student].TrafficLight : ""}
          />
        );
      })}
      <hr />
      <div className="d-grid">
        <Button variant="danger" onClick={() => deleteClicked()} size="lg">
          Delete Workout
        </Button>
      </div>
    </Container>
  );
};

export default HomeworkViewPage;
