import { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Stack, Tab, Table, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";
import StrandCategoryBox from "../../components/new-components/StrandCategoryBox";
import WorkoutBox from "../../components/new-components/WorkoutBox";
import StudentBox from "../../components/StudentBox";
import { getClassroomStudents } from "../../functions/classroomFunctions";
import { deleteWorkout, getClassroomWorkouts } from "../../functions/workoutFunctions";
import { UserContext } from "../../providers/UserProvider";

function SavedWorkoutsPage() {
  const [className, setClassName] = useState("");
  const [workouts, setWorkouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data2 } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Classroom";

    if ("Guid" in data2) {
      getWorkouts(data2.Guid);
    }
  }, [data2]);

  const getWorkouts = async (userID) => {
    setLoading(true);
    const workoutData = await getClassroomWorkouts(userID);
    setWorkouts(workoutData);
    setLoading(false);
  };

  const deleteWorkoutClicked = async (workout) => {
    const workoutID = workout.Guid;
    console.log(workoutID);
    await deleteWorkout(workoutID, data2.Guid)
    await getWorkouts(data2.Guid);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Saved Workouts</h1>
          <div className="white-box">
            {loading ? (
              <div className="custom-loader" />
            ) : (
              <Row>
                {workouts.map((workout, i) => {
                  return (
                    <Col md={3} key={i}>
                      <WorkoutBox
                        workout={workout}
                        deleteFunction={deleteWorkoutClicked}
                      />
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        </Container>
      </div>
    </Container>
  );
}

export default SavedWorkoutsPage;
