import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AccountPropertyBox from "../components/AccountPropertyBox";
import InviteCodeBox from "../components/InviteCodeBox";
import Loading from "../components/Loading";
import {
  addCodeToUser,
  changeSubscriptionQuantity,
  createCodes,
  getAdminDetails,
  getSubscriptionDetails,
  manageSubscription,
  removeCodes,
  UploadAdminSchoolDetails,
} from "../firebase";
import { UserContext } from "../providers/UserProvider";

const AdminPage = () => {
  const [adminDetails, setAdminDetails] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [manageSubUrl, setManageSubUrl] = useState("");
  const [editSchool, setEditSchool] = useState(false);
  const [editSubscription, setEditSubscription] = useState(false);
  const [generatingCodes, setGeneratingCodes] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [numberOfSubscriptions, setNumberOfSubscriptions] = useState(0);
  const { data } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Daly Exercise+ | Admin";
    if ("UserName" in data) {
      manageSubscription().then((url) => setManageSubUrl(url));
      getSubscriptionDetails(data.Guid).then((result2) => {
        setSubscriptionDetails(result2);
        if (Object.keys(result2).length === 0) {
          navigate("/subscription");
          return;
        }
        getAdminDetails(data.Guid).then((result) => {
          if (!("PaidForCodes" in result)) {
            setGeneratingCodes(true);
            createCodes(result2.quantity, data.Guid).then(() => {
              getAdminDetails(data.Guid).then((result) => {
                if (result.PaidForCodes === 1) {
                  addCodeToUser(result.InviteCodes[0], data.UserName);
                  data.InviteCode = result.InviteCodes[0];
                } else {
                  navigate(`/admin/invite-codes`);
                }
                setAdminDetails(result);
                setGeneratingCodes(false);
              });
            });
          } else if (result.PaidForCodes !== result2.quantity) {
            let newCodes = result2.quantity - result.PaidForCodes;
            if (newCodes > 0) {
              setGeneratingCodes(true);
              createCodes(newCodes, data.Guid).then(() => {
                getAdminDetails(data.Guid).then((result) => {
                  if (result.PaidForCodes === 1) {
                    addCodeToUser(result.InviteCodes[0], data.UserName);
                    data.InviteCode = result.InviteCodes[0];
                  } else {
                    navigate(`/admin/invite-codes`);
                  }
                  setAdminDetails(result);
                });
                setGeneratingCodes(false);
              });
            } else if (data.Guid !== "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2") {
              removeCodes(result2.quantity + 1, data.Guid).then(() => {
                getAdminDetails(data.Guid).then((result) => {
                  setAdminDetails(result);
                });
              });
            }
          } else {
            setAdminDetails(result);
          }
        });
      });
    }
  }, [data, refresh]);

  const manageMySubscription = () => {
    if (subscriptionDetails.isFree) {
      setEditSubscription(!editSubscription);
    } else if (manageSubUrl !== "") {
      window.location.assign(manageSubUrl);
    }
  };

  const uploadChangedSubscription = async () => {
    try {
      var newSubs = parseInt(numberOfSubscriptions);
      if (
        numberOfSubscriptions !== subscriptionDetails.quantity &&
        numberOfSubscriptions > 0
      ) {
        let res = await changeSubscriptionQuantity(
          data.Guid,
          subscriptionDetails.id,
          numberOfSubscriptions
        );
        if (res) {
          setRefresh(!refresh);
        }
      }
    } catch (e) {}

    setEditSubscription(!editSubscription);
  };

  const clickEditSchool = () => {
    if (editSchool) {
      UploadAdminSchoolDetails(
        {
          SchoolName: adminDetails.SchoolName,
          Area: adminDetails.Area,
          County: adminDetails.County,
        },
        data.Guid
      );
    }
    setEditSchool(!editSchool);
  };
  return (
    <div className="full-page-length">
      {generatingCodes ? (
        <Container>
          <h1 className="pageTitle title-margin">Subscription</h1>
          <hr />
          <Stack className="mt-3">
            <Loading center={true} />
            <h2 className="pageTitle">Generating Codes</h2>
          </Stack>
        </Container>
      ) : (
        <Container>
          <h1 className="pageTitle title-margin">Subscription</h1>

          <hr />
          <h2 className="pageTitle">My Subscription</h2>
          <AccountPropertyBox
            edit={editSubscription}
            title="Number of Subscriptions"
            value={subscriptionDetails.quantity}
            setValue={(val) => {
              setNumberOfSubscriptions(val);
            }}
          />
          <AccountPropertyBox
            title="Subscription Type"
            value={
              subscriptionDetails.isFree
                ? "IPPN Free Account"
                : "€" +
                  (subscriptionDetails.price / 100).toString() +
                  " per " +
                  subscriptionDetails.interval
            }
          />
          <AccountPropertyBox
            title="Total Price"
            value={
              subscriptionDetails.isFree
                ? "€0"
                : "€" +
                  (
                    (subscriptionDetails.quantity * subscriptionDetails.price) /
                    100
                  ).toString() +
                  " per " +
                  subscriptionDetails.interval
            }
          />
          {editSubscription ? (
            <Button
              variant="primary"
              className="mainButton"
              onClick={uploadChangedSubscription}
              style={{ margin: 10 }}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="primary"
              className="mainButton"
              onClick={manageMySubscription}
              style={{ margin: 10 }}
            >
              Manage My Subscription
            </Button>
          )}

          <hr />
          <h2 className="pageTitle">Invite Codes</h2>
          <AccountPropertyBox
            title="Number of Codes"
            value={
              adminDetails.InviteCodes ? adminDetails.InviteCodes.length : 0
            }
          />

          <Link to={`/admin/invite-codes`} className="text-link">
            <Button
              variant="primary"
              className="mainButton"
              style={{ margin: 10 }}
            >
              Manage My Invite Codes
            </Button>
          </Link>

          <hr />
          <h2 className="pageTitle">My School</h2>
          <AccountPropertyBox
            title="School Name"
            value={adminDetails.SchoolName}
            edit={editSchool}
            setValue={(val) => {
              setAdminDetails({
                ...adminDetails,
                SchoolName: val,
              });
            }}
          />
          <AccountPropertyBox
            title="Area"
            value={adminDetails.Area}
            edit={editSchool}
            setValue={(val) => {
              setAdminDetails({
                ...adminDetails,
                Area: val,
              });
            }}
          />
          <AccountPropertyBox
            title="County"
            value={adminDetails.County}
            edit={editSchool}
            setValue={(val) => {
              setAdminDetails({
                ...adminDetails,
                County: val,
              });
            }}
            type="County"
          />
          <Button
            style={{ margin: 10 }}
            variant={editSchool ? "danger" : "primary"}
            className="mainButton"
            onClick={clickEditSchool}
          >
            {editSchool ? "Save" : "Edit"}
          </Button>
        </Container>
      )}
    </div>
  );
};

export default AdminPage;
