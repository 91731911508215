import { Accordion, Button, Card, Col, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import ReactPlayer from "react-player/lazy";
import { useState } from "react";
import { useEffect } from "react";
import { getExerciseUrl } from "../firebase";

const ExercisePreviewBox = (props) => {
  const [gifUrl, setGifUrl] = useState("");
  const viewClicked = () => {
    props.PreviewExercise(props.exercise);
  };
  return (
    <Card style={{ margin: 10 }}>
      <Stack className={"p-2"} direction="horizontal">
        <div>{props.exercise.Name}</div>
        <button type="button" className="custom-button ms-auto align-middle" onClick={() => viewClicked()}>
          View
        </button>
      </Stack>
    </Card>
  );
};

export default ExercisePreviewBox;
