import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Accordion,
  Stack,
  Modal,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import {
  auth,
  getAllDEHomeworkByYear,
  getAllExercisesByYear,
  getExerciseUrl,
  getRandomExercisesByYear,
} from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import HomeworkPreviewPage from "./HomeworkPreviewPage";
import ExercisePreviewBox from "../components/ExercisePreviewBox";
import ReactPlayer from "react-player";
import ExerciseCustomBox from "../components/ExerciseCustomBox";

function HomeworkRecommendationsPage() {
  const [homeworkName, setHomeworkName] = useState("");
  const [exercises, setExercises] = useState([]);
  const [deExercises, setDeExercises] = useState([]);
  const navigate = useNavigate();
  const { data } = useContext(UserContext);
  const [gifUrl, setGifUrl] = useState("");

  const [showExercise, setShowExercise] = useState(false);
  const [exerciseName, setExerciseName] = useState("");
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    document.title = "Daly Exercise+ | Homework Recommendations";
    if ("UserName" in data) {
      getAllDEHomeworkByYear(data.Year).then((val) => {
        setDeExercises(val);
      });
    }
  }, [data]);

  const changeSelectedExercise = async (exercise) => {
    let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
    setGifUrl(url);
    setExerciseName(exercise.Name);
    setShowExercise(true);
  };

  const handleCloseExercise = () => {
    setShowExercise(false);
  };

  const exerciseClicked = (exercise) => {
    setExercises(exercise.Exercises);
    setConfirm(true);
  };

  return (
    <Container className="full-page-length">
      {!confirm ? (
        <div>
          <h1 className="pageTitle title-margin">
            Daly Exercise Recommendations
          </h1>
          <hr />
          <Row>
            {deExercises.map((x, i) => {
              return (
                <Col key={i}>
                  <Card
                    onClick={() => exerciseClicked(x)}
                    style={{ margin: 10, height: "20rem", width: "20rem" }}
                    className="grow mx-auto tikTokColourBorder"
                  >
                    <div
                      style={{ height: "15rem" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Card.Img
                        variant="top"
                        src="/icons/high_res_logo_icon.png"
                        style={{ height: "11rem", width: "11rem" }}
                      />
                    </div>

                    <div className="text-center align-bottom rwg-font tikTokColourFont m-3  fs-5">
                      <b>{x.Title}</b>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <HomeworkPreviewPage
          name={homeworkName}
          exercises={exercises}
          edit={true}
          setName={setHomeworkName}
        />
      )}
    </Container>
  );
}

export default HomeworkRecommendationsPage;
