import Spinner from "react-bootstrap/Spinner";

function Loading(props) {
  return (
    <Spinner
      animation="border"
      role="status"
      className={props.center ? "m-auto mt-3 mb-3" : ""}
      style={{color: "#621fbb"}}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default Loading;
