import { useContext, useEffect, useState } from "react";
import { Accordion, Container, Stack, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  getAllCategoriesNew,
  getAllLessonsNew,
  getAllStrandsNew,
  getAllStrandTypes,
  getAllSubcategoriesNew,
} from "../../firebase";
import CategoryBox from "../../components/CategoryBox";
import SearchBarLink from "../../components/SearchBarLink";
import { getEventStrandsByYear, getStrandsByYear } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { Link } from "react-router-dom";

function ContentManagementPage() {
  const [loading, setLoading] = useState(true);
  const [strands, setStrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [lessons, setLessons] = useState([]);
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Content Management";
    if ("UserName" in data) {
      let promises = [
        getAllStrandTypes(),
        getAllCategoriesNew(),
        getAllSubcategoriesNew(),
        getAllLessonsNew(),
      ];

      setLoading(true);

      Promise.all(promises).then((res) => {
        let sortedStrands = res[0].sort(
          (a, b) => (a.StrandNumber > b.StrandNumber ? 1 : -1)
        );

        let updatedCategories = [];

        res[1].forEach((category) => {
          let strand = res[0].find((strand) =>
            strand.Categories?.includes(category.Guid)
          );
          updatedCategories.push({
            ...category,
            Strand: strand,
          });
        });

        updatedCategories = updatedCategories.sort((cat1, cat2) => {
          return (
            (cat1.Strand.StrandNumber - cat2.Strand.StrandNumber) * 1000 +
            (cat1.Order - cat2.Order) * 1
          );
        });

        let updatedSubCategories = [];

        res[2].forEach((subcategory) => {
          let category = updatedCategories.find((category) =>
            category.SubCategories.includes(subcategory.Guid)
          );
          updatedSubCategories.push({
            ...subcategory,
            Category: category,
          });
        });

        updatedSubCategories = updatedSubCategories.sort((cat1, cat2) => {
          if (cat1.Category === undefined) {
            return 100000;
          } else if (cat2.Category === undefined) {
            return -100000;
          }
          return (
            (cat1.Category.Strand.StrandNumber -
              cat2.Category.Strand.StrandNumber) *
              10000 +
            (cat1.Category.Order - cat2.Category.Order) * 100 +
            (cat1.Order - cat2.Order)
          );
        });
        setStrands(sortedStrands);
        setCategories(updatedCategories);
        setSubcategories(updatedSubCategories);
        setLessons(
          res[3].sort(
            (a, b) =>
              (a.Title < b.Title ? -100 : a.Title > b.Title ? 100 : 0) +
              (a.Year - b.Year)
          )
        );

        setLoading(false);
      });
    }
  }, [data]);

  const goToStrand = (id) => {
    navigate(`/content-management/strand/${id}`);
  };

  const goToCategory = (id) => {
    navigate(`/content-management/category/${id}`);
  };

  const goToSubCategory = (id) => {
    navigate(`/content-management/subcategory/${id}`);
  };

  const goToLesson = (id) => {
    navigate(`/content-management/lesson/${id}`);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Content Management</h1>
          <br />

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header bsPrefix="accordion-title">
                <h3 className="pageTitle title-margin">Strands</h3>
              </Accordion.Header>
              <Accordion.Body>
                {loading ? (
                  <div className="custom-loader"></div>
                ) : (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Order</th>
                        <th>Colour</th>
                        <th>Icon</th>
                        <th># of categories</th>
                        <th>Type</th>
                        <th>Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      {strands.map((strand, i) => {
                        return (
                          <tr key={i} onClick={() => goToStrand(strand.Guid)}>
                            <th>{strand.Title}</th>
                            <th>{strand.StrandNumber}</th>
                            <th>{strand.ColourName}</th>
                            <th>{strand.ImageUri}</th>
                            <th>
                              {strand.Categories ? strand.Categories.length : 0}
                            </th>
                            <th>{strand.Type}</th>
                            <th>{strand.Active ? "True" : "False"}</th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Link className="text-link" to={"strand/add"}>
            <button className={`custom-card-button`}>Add Strand</button>
          </Link>

          <br />
          <hr />
          <br />

          <Accordion>
            <Accordion.Item eventKey="1">
              <Accordion.Header bsPrefix="accordion-title">
                <h3 className="pageTitle title-margin">Categories</h3>
              </Accordion.Header>
              <Accordion.Body>
                {loading ? (
                  <div className="custom-loader"></div>
                ) : (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Strand</th>
                        <th>Title</th>
                        <th>Order</th>
                        <th># of subcategories</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map((category, i) => {
                        return (
                          <tr
                            key={i}
                            onClick={() => goToCategory(category.Guid)}
                          >
                            <th>{category.Strand.Title}</th>
                            <th>{category.Title}</th>
                            <th>{category.Order}</th>
                            <th>
                              {category.SubCategories
                                ? category.SubCategories.length
                                : 0}
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Link className="text-link" to={"category/add"}>
            {" "}
            <button className={`custom-card-button`}>Add Category</button>
          </Link>

          <br />
          <hr />
          <br />

          <Accordion>
            <Accordion.Item eventKey="2">
              <Accordion.Header bsPrefix="accordion-title">
                <h3 className="pageTitle title-margin">Sub Categories</h3>
              </Accordion.Header>
              <Accordion.Body>
                {loading ? (
                  <div className="custom-loader"></div>
                ) : (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Strand</th>
                        <th>Category</th>
                        <th>Title</th>
                        <th>Order</th>
                        <th># of lessons</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subcategories.map((category, i) => {
                        return (
                          <tr
                            key={i}
                            onClick={() => goToSubCategory(category.Guid)}
                          >
                            <th>
                              {category.Category === undefined
                                ? ""
                                : category.Category.Strand.Title}
                            </th>
                            <th>
                              {category.Category === undefined
                                ? ""
                                : category.Category.Title}
                            </th>
                            <th>{category.Title}</th>
                            <th>{category.Order}</th>
                            <th>
                              {category.Lessons ? category.Lessons.length : 0}
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Link className="text-link" to={"subcategory/add"}>
            <button className={`custom-card-button`}>Add Sub Category</button>
          </Link>

          <br />
          <hr />
          <br />
          <Accordion>
            <Accordion.Item eventKey="3">
              <Accordion.Header bsPrefix="accordion-title">
                <h3 className="pageTitle title-margin">Lessons</h3>
              </Accordion.Header>
              <Accordion.Body>
                {loading ? (
                  <div className="custom-loader"></div>
                ) : (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Title</th>
                        {/* <th>Description</th> */}
                        {/* <th>Equipment</th> */}
                        {/* <th>Url</th> */}
                        {/* <th>Url Embeded</th> */}
                        <th>Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lessons.map((lesson, i) => {
                        return (
                          <tr key={i} onClick={() => goToLesson(lesson.Guid)}>
                            <th>{lesson.Title}</th>
                            {/* <th>{lesson.Description}</th> */}
                            {/* <th>{lesson.Equipment}</th> */}
                            {/* <th>{lesson.Url}</th> */}
                            {/* <th>{lesson.UrlEmbeded}</th> */}
                            <th>{lesson.Year}</th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Link className="text-link" to={"lesson/add"}>
            <button className={`custom-card-button`}>Add Lesson</button>
          </Link>
        </Container>
      </div>
    </Container>
  );
}

export default ContentManagementPage;
