import { Card, Stack } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { getUserData } from "../firebase";

const HomeworkStudentBox = (props) => {
  const [details, setDetails] = useState({});
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    getUserData(props.Guid).then((res) => {
      setDetails(res);
      setImageUrl(`/avatars/${res.ImageName.toLowerCase()}.png`);
    });
  }, [props]);

  const giveFeedback = () => {
    let student = {
      Guid: props.Guid,
      FirstName: details.FirstName,
      LastName: details.LastName,
      ClassId: details.ClassId,
    };
    props.GiveFeedback(student);
  };
  return (
    <Card
      style={{ margin: 10 }}
      className={
        props.Completed ? props.TrafficLight.toLowerCase() + "Border grow" : ""
      }
      onClick={() => giveFeedback()}
    >
      <Stack className={"p-2"} direction="horizontal" gap={2}>
        <img src={imageUrl} width={60} height={60} />
        <div>{details.FirstName}</div>
        <div>{details.LastName}</div>
        <div className="ms-auto align-middle">
          {props.Completed ? (
            props.Feedback === "" ? (
              "Give Feedback"
            ) : (
              <img
                src={`/icons/icon_${props.Feedback.toLowerCase()}.png`}
                width={60}
                height={60}
              />
            )
          ) : (
            "Not Completed"
          )}
        </div>
      </Stack>
    </Card>
  );
};

export default HomeworkStudentBox;
