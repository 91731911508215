import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import Loading from "../../components/Loading";

function SetupPage() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Setup";
    new Promise(r => setTimeout(r, 5000)).then(() => navigate("/manage-school/accounts"));
  }, [navigate]);

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">
            We're just setting up your account...
          </h1>

          <br />
          <div className="w-100 d-flex align-items-center"><Loading center={true}/></div>
          
        </Container>
      </div>
    </Container>
  );
}

export default SetupPage;
