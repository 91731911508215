import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Container, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import HomeworkBox from "../components/HomeworkBox";
import { GetTeacherHomework } from "../firebase";
import { UserContext } from "../providers/UserProvider";

const HomeworkBasePage = () => {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  const [scheduledHomework, setScheduledHomework] = useState([]);
  const [historyHomework, setHistoryHomework] = useState([]);
  const [showMoreHistory, setShowMoreHistory] = useState(false);
  const [showMoreScheduled, setShowMoreScheduled] = useState(false);

  useEffect(() => {
    document.title = "Daly Exercise+ | Homework";
    if ("UserName" in data) {
      GetTeacherHomework(data.ClassId).then((val) => {
        setScheduledHomework(val.scheduled);
        setHistoryHomework(val.history);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const goToRandom = () => {
    navigate("random");
  };

  const goToCustom = () => {
    navigate("custom");
  };

  const goToRecommend = () => {
    navigate("recommend");
  };

  const goToHomework = (homework) => {
    navigate(`assigned/${homework.Guid}`);
  };
  return (
    <Container className="full-page-length">
      <h1 className="pageTitle title-margin">Classroom Workouts</h1>
      <hr />

      <h2 className="headingColour">Assign</h2>
      <Row className="mx-auto">
        <Col>
          <Card
            style={{ margin: 10, height: "20rem", width: "25rem" }}
            className="grow mx-auto activeColourBorder"
            onClick={goToRandom}
          >
            <Card.Img
              variant="top"
              src="/icons/icon_random.png"
              style={{ height: "15rem" }}
            />
            <div className="text-center rwg-font activeColourFont m-3 mt-0 fs-5">
              <b>Random Workout Generator</b>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            onClick={goToCustom}
            style={{ margin: 10, height: "20rem", width: "25rem" }}
            className="grow mx-auto darkBlueBorder"
          >
            <Card.Img
              variant="top"
              src="/icons/icon_tool_singular.png"
              style={{ height: "15rem" }}
            />
            <div className="text-center rwg-font darkBlueFont m-3 mt-0 fs-5">
              <b>Customise Your Workout</b>
            </div>
          </Card>
        </Col>
        {/* <Col>
          <Card
            style={{ margin: 10, height: "20rem", width: "20rem" }}
            className="grow mx-auto tikTokColourBorder"
            onClick={goToRecommend}
          >
            <div
              style={{ height: "15rem" }}
              className="d-flex justify-content-center align-items-center"
            >
              <Card.Img
                variant="top"
                src="/icons/high_res_logo_icon.png"
                style={{ height: "11rem", width: "11rem" }}
              />
            </div>

            <div className="text-center align-bottom rwg-font tikTokColourFont m-3  fs-5">
              <b>DE Recommendations</b>
            </div>
          </Card>
        </Col> */}
      </Row>
      <hr />

      <h2 className="headingColour">Scheduled</h2>
      <Row className="mx-auto justify-content-center">
        {scheduledHomework.map((item, i) => {
          return (
            <Col
              sm={100}
              xs={100}
              md={6}
              lg={4}
              key={i}
              style={{
                display: i < 3 || showMoreScheduled ? "initial" : "none",
              }}
            >
              <HomeworkBox details={item} link={goToHomework} />
            </Col>
          );
        })}
        {scheduledHomework.length < 1 ? (
          <h4 className="text-center">You've no workouts scheduled.</h4>
        ) : (
          <div className="d-grid">
            <Button
              variant={showMoreScheduled ? "danger" : "primary"}
              className="mainButton"
              size="lg"
              onClick={() => setShowMoreScheduled(!showMoreScheduled)}
            >
              {showMoreScheduled ? "Show Less" : "Show More"}
            </Button>
          </div>
        )}
      </Row>

      <hr />
      <Row className="mx-auto"></Row>
      <h2 className="headingColour">History</h2>
      <Row className="mx-auto justify-content-center">
        {historyHomework.map((item, i) => {
          return (
            <Col
              sm={100}
              xs={100}
              md={6}
              lg={4}
              key={i}
              style={{
                display: i < 3 || showMoreHistory ? "initial" : "none",
              }}
            >
              <HomeworkBox details={item} link={goToHomework} />
            </Col>
          );
        })}
        {historyHomework.length < 1 ? (
          <h4 className="text-center">Schedule some workouts!</h4>
        ) : (
          <div className="d-grid">
            <Button
              variant={showMoreHistory ? "danger" : "primary"}
              className="mainButton"
              size="lg"
              onClick={() => setShowMoreHistory(!showMoreHistory)}
            >
              {showMoreHistory ? "Show Less" : "Show More"}
            </Button>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default HomeworkBasePage;
