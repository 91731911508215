import { Button, Card, Col, Container, Row } from "react-bootstrap";
import QRcode, { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { GetClassCode } from "../firebase";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { useReactToPrint } from "react-to-print";

const ClasscodePage = () => {
  const [classcode, setClasscode] = useState("XXXXX");
  const [expireyDate, setExpireyDate] = useState(new Date());

  const { data } = useContext(UserContext);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const qrRef = useRef(null);

  const totalRef = useRef(null);
  useEffect(() => {
    document.title = "Daly Exercise+ | Classcode";
    if ("UserName" in data) {
      if (classcode === "XXXXX") {
        GetClassCode(data.ClassId).then((val) => {
          setClasscode(val.Code);
          // setExpireyDate(new Date(val.ExpireryDate));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const printInstructions = useReactToPrint({
    documentTitle: "DE+ Instructions",
    bodyClass: "instructions-print",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: false,
  });
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Manage Your Classroom</h1>

          <div ref={totalRef}>
            <br />
            <Row>
              <Col md={4}>
                <div className="qr-code-box qr-box-border-1">
                  <h3 className="qr-code-title">Classroom Invite</h3>
                  <img
                    className="qr-code-img"
                    src={qrRef.current?.children[0].toDataURL()}
                  ></img>
                </div>
              </Col>
              <Col md={4}>
                <div className="qr-code-box qr-box-border-2">
                  <h3 className="qr-code-title">IOS Download</h3>
                  <img
                    className="qr-code-img"
                    src={qrRef.current?.children[1].toDataURL()}
                  ></img>
                </div>
              </Col>
              <Col md={4}>
                <div className="qr-code-box qr-box-border-3">
                  <h3 className="qr-code-title">Android Download</h3>
                  <img
                    className="qr-code-img"
                    src={qrRef.current?.children[2].toDataURL()}
                  ></img>
                </div>
              </Col>
            </Row>
            {/* <hr /> */}
            <div className="white-box">
              <h1 className="page-title">How To Join A Classroom</h1>
              <ol className="instructions-list">
                <li className="instructions-text">
                  Download the Daly Exercise+ mobile app on either IOS or
                  Android using the QR codes above.
                </li>
                <li className="instructions-text">
                  Open the app and select "I am a Student".
                </li>
                <li className="instructions-text">
                  Select "Create You Account!" at the bottom of the page.
                </li>
                <li className="instructions-text">Select "Scan QR Code".</li>
                <li className="instructions-text">
                  Scan the "Classroom Invite" QR code above.
                </li>
                <li className="instructions-text">
                  Fill in your details to create your account!.
                </li>
                <li className="instructions-text">
                  Once created, click on your avatar to choose a new animal!
                </li>
              </ol>
            </div>
          </div>
          <div className="white-box">
            <div className="d-grid gap-2">
              <Button
                style={{ margin: 5 }}
                className="mainButton"
                onClick={() => {
                  printInstructions(null, () => totalRef.current);
                }}
                size="lg"
              >
                Print Instructions
              </Button>

              <Link to={`add-student`} className="text-link d-grid">
                <Button
                  variant="primary"
                  style={{ margin: 5 }}
                  className="mainButton"
                  size="lg"
                >
                  Add Pupil Manually
                </Button>
              </Link>
            </div>
          </div>

          {/* <p className="text-center">
        <b>Expires on {expireyDate.toLocaleDateString(undefined, options)}</b>
      </p> */}
          <div ref={qrRef} style={{ display: "none" }}>
            <QRCodeCanvas value={classcode} size={500} includeMargin={true} />
            <QRCodeCanvas
              value={
                "https://apps.apple.com/ie/app/daly-exercise/id1586325510?platform=iphone"
              }
              size={500}
              includeMargin={true}
            />
            <QRCodeCanvas
              value={
                "https://play.google.com/store/apps/details?id=com.dalyexercise.dalyexerciseplus"
              }
              size={500}
              includeMargin={true}
            />
          </div>

          <div></div>
        </Container>
      </div>
    </Container>
  );
};

export default ClasscodePage;
