import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Card, Row, Col, Stack } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import UnitLessonBox from "./UnitLessonBox";
const SearchBar = (props) => {
  const [title, setTitle] = useState("");

  const onFormSubmit = e => {
    if (e.which == 13 || e.keyCode == 13) {
      e.preventDefault();
      e.currentTarget.blur()
    }
   
  }
  return (
    <div className="mb-3">
        <Form.Control
          onKeyDown={(e) => onFormSubmit(e)}
          className="searchbar-text"
          placeholder={props.placeholder}
          onChange={(event) => props.setSearchValue(event.target.value)}
        />
      {/* <Row>
        {filterLessons().map((lesson, j) => {
          return (
            <Col xs={4} key={j}>
              <UnitLessonBox
                Lesson={lesson}
                notSubscribed={false}
              ></UnitLessonBox>
            </Col>
          );
        })}
      </Row> */}
    </div>
  );
};

export default SearchBar;
