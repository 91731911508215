import React, { useState } from "react";
import { Container, Form, Button, Stack, Modal } from "react-bootstrap";
import {
  auth,
  getClassData,
  getClassroomStudentsFromList,
  getExerciseUrl,
  getRandomExercisesByYear,
  uploadHomework,
} from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import ExercisePreviewBox from "../components/ExercisePreviewBox";
import { useEffect } from "react";
import ReactPlayer from "react-player";

function HomeworkPreviewPage(props) {
  const [numberOfSets, setNumberOfSets] = useState(1);
  const navigate = useNavigate();
  const { data } = useContext(UserContext);
  const [startDate, setStartDate] = useState();
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const [showStudents, setShowStudents] = useState(false);
  const [dateText, setDateText] = useState("");
  const [nameText, setNameText] = useState("");
  const [name, setName] = useState("");
  const [showExercise, setShowExercise] = useState(false);
  const [exerciseName, setExerciseName] = useState("");
  const handleCloseStudents = () => setShowStudents(false);
  const handleShowStudents = () => setShowStudents(true);
  const [gifUrl, setGifUrl] = useState("");
  const handleSets = () => {
    setNumberOfSets((numberOfSets % 3) + 1);
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Homework Preview";
    if ("UserName" in data) {
      getClassData(data.ClassId).then((result) => {
        getClassroomStudentsFromList(result.students).then((studentData) => {
          studentData.forEach((x) => {
            x.Selected = true;
          });
          setStudents(studentData);
          setSelectedStudents(studentData);
        });
      });
    }
  }, [data]);

  const changeSelectedExercise = async (exercise) => {
    let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
    setGifUrl(url);
    setExerciseName(exercise.Name);
    setShowExercise(true);
  };

  const handleCloseExercise = () => {
    setShowExercise(false);
  };

  const uploadCurrentHomework = async () => {
    if (!startDate) {
      setDateText("Please enter a date");
      return;
    }

    if (props.edit && (!name || name === "")) {
      setNameText("Please a title for your homework");
      return;
    }
    let date = startDate.toISOString();
    let studentList = students
      .map((x) => {
        return x.Selected ? x.Guid : null;
      })
      .filter(Boolean);
    if (studentList.length === 0) {
      studentList = [];
    }
    let exerciseList = props.exercises.map((x) => x.Guid).join("+");
    // console.log(
    //   students
    //     .map((x) => {
    //       return x.Selected ? x.Guid : null;
    //     })
    //     .filter(Boolean)
    // );
    await uploadHomework(
      date,
      data.ClassId,
      exerciseList,
      props.name,
      "",
      numberOfSets,
      studentList
    );
    navigate("/homework");
  };

  return (
    <Container className="full-page-length">
      <Modal
        show={showExercise}
        onHide={handleCloseExercise}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {exerciseName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <ReactPlayer url={gifUrl} loop={true} playing={true} muted={true} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseExercise}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showStudents}
        onHide={handleCloseStudents}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {students.map((x, i) => {
            return (
              <Form.Check
                type="checkbox"
                key={i}
                label={`${x.FirstName} ${x.LastName}`}
                defaultChecked={x.Selected}
                onChange={() => {
                  x.Selected = !x.Selected;
                }}
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseStudents}>Confirm</Button>
        </Modal.Footer>
      </Modal>
      {props.edit ? (
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            className="pageTitle fs-1"
            placeholder="Homework Name"
            aria-describedby="emailHelpBlock"
            onChange={(event) => setName(event.target.value)}
          />
          <Form.Text id="emailHelpBlock" muted>
            {nameText}
          </Form.Text>
        </Form.Group>
      ) : (
        <h1 className="pageTitle title-margin">{props.name}</h1>
      )}
      <hr />
      <div className="d-flex justify-content-center">
        {" "}
        <img
          src="/icons/high_res_logo_icon.png"
          style={{ height: "15rem", margin: "10" }}
        />
      </div>
      <h4 className="text-center">
        <b>Preview</b>
      </h4>
      <Form.Group controlId="dob">
        <Form.Label className="h3 headingColour">
          <b>Due Date</b>
        </Form.Label>
        <Form.Control
          style={{ margin: 10, marginBottom: 1 }}
          type="date"
          name="dob"
          placeholder="Date"
          selected={startDate}
          onChange={(e) => setStartDate(new Date(e.target.value))}
        />
        <Form.Text id="dob" muted style={{ margin: 10 }}>
          {dateText}
        </Form.Text>
      </Form.Group>

      <div className="d-grid mb-3 mt-2">
        <Button variant="primary" onClick={handleShowStudents}>
          Select Students
        </Button>
      </div>
      <hr />
      <Stack direction="horizontal">
        <h3 className="headingColour">
          <b>Exercises</b>
        </h3>
        <Button variant="primary" className="ms-auto" onClick={handleSets}>
          Sets x{numberOfSets}
        </Button>
      </Stack>
      {props.exercises.map((x, i) => {
        return (
          <ExercisePreviewBox
            exercise={x}
            key={i}
            PreviewExercise={changeSelectedExercise}
          />
        );
      })}
      <hr />
      <div className="d-grid">
        <Button variant="primary" onClick={uploadCurrentHomework}>
          Assign
        </Button>
      </div>
    </Container>
  );
}

export default HomeworkPreviewPage;
