import { Button, Container, Form, Stack } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import { getNews, uploadContentToDatabase, uploadFileToStorage } from "../firebase";
import { UserContext } from "../providers/UserProvider";
import NewsBox from "../components/NewsBox";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import WorksheetBox from "../components/WorksheetBox";

function GlenAdminPage() {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [equipment, setEquipment] = useState("");
  const [resources, setResources] = useState([]);
  const [resourceFiles, setResourceFiles] = useState([]);
  const [type, setType] = useState("Lesson");
  const [youtubeId, setYoutubeId] = useState("Lesson");
  const [year0, setYear0] = useState(false);
  const [year1, setYear1] = useState(false);
  const [year2, setYear2] = useState(false);
  const [year3, setYear3] = useState(false);

  const [unit, setUnit] = useState("Warm Ups (Warm Ups)");

  const {data} = useContext(UserContext);
  useEffect(() => {document.title = "Daly Exercise+ | Glen Admin";}, [data]);

  const typeList = ["Lesson", "Movement Break"];
  const unitList = [
    "Warm Ups (Warm Ups)",
    "Jumping (Athletics)",
    "Throwing (Athletics)",
    "Running (Athletics)",
    "Creative Dance (Dance)",
    "Sequence (Gymnastics)",
    "Mat & Bench (Gymnastics)",
    "Movement (Gymnastics)",
    "Kicking (Games)",
    "Ball Handling (Games)",
    "Carrying and Striking (Games)",
    "Non-Contact Games (Non-Contact Games)",
    "Active Break",
    "Mindfulness Break",
    "TikTok Break",
    "Easter PE Games",
  ];

  const uploadContent = async () => {
    if (
      !title ||
      !description ||
      !type ||
      !youtubeId ||
      !unit ||
      (!year0 && !year1 && year2 && year3)
    ) {
      alert(
        "Please fill in at least the title, descruption, type, youtube ID, unit and at least one year."
      );
      return;
    }

    let url = `https://youtu.be/${youtubeId}`;
    let embedUrl = `https://www.youtube.com/embed/${youtubeId}`;

    let totalContent = [];
    let content = {
      Title: title,
      Description: description,
      Equipment: equipment,
      Sparkle: true,
      Url: url,
      UrlEmbeded: embedUrl,
      Resources: resources.join("&"),
    };

    if (type === "Lesson") {
      if (year0) {
        var unitId = "";
        if (unit === unitList[0]) {
          unitId = "c63c0c0e-3294-42ec-a810-06de1aeb5c35";
        } else if (unit === unitList[1]) {
          unitId = "8cd89568-cbc2-45ad-b05d-6d10ea939cb8";
        } else if (unit === unitList[2]) {
          unitId = "edd997cf-33ce-46d6-bee8-9fdf213f7099";
        } else if (unit === unitList[3]) {
          unitId = "d5047dc3-303c-4b58-99ef-e7b1921fd105";
        } else if (unit === unitList[4]) {
          unitId = "115385db-0e3e-44b8-9490-58bb85a0d9fb";
        } else if (unit === unitList[5]) {
          unitId = "607492c0-1a7c-43c8-b59f-37b17e1df11d";
        } else if (unit === unitList[6]) {
          unitId = "368a8079-de58-4783-94d8-5ebe38d53d2d";
        } else if (unit === unitList[7]) {
          unitId = "3c240c9a-7901-486c-9f6a-0225d54cf69f";
        } else if (unit === unitList[8]) {
          unitId = "f6151734-592b-4bab-a2ad-18f86a87f7ae";
        } else if (unit === unitList[9]) {
          unitId = "ff1ff6ed-6222-4358-a5bd-d13c90015baa";
        } else if (unit === unitList[10]) {
          unitId = "bc728a59-0444-4333-a2f5-a1618947c303";
        } else if (unit === unitList[11]) {
          unitId = "c9cebd9e-9ebe-428f-88cd-bdb018975cb8";
        } else if (unit === unitList[15]) {
          unitId = "265b37b8-5eec-432e-81a7-271362482277";
        } else {
          alert("Chosen unit is not a lesson");
          return;
        }

        let id = uuidv4().toString();

        content = {
          ...content,
          UnitGuid: unitId,
          Guid: id,
        };

        totalContent.push(content);
      }

      if (year1) {
        var unitId = "";
        if (unit === unitList[0]) {
          unitId = "21dcd1e4-d6c4-4fc6-acf2-ebde05575552";
        } else if (unit === unitList[1]) {
          unitId = "628335ac-efd4-4eaa-aa4b-e2d5f3bdc48d";
        } else if (unit === unitList[2]) {
          unitId = "94e2996b-e17c-407c-82a6-b89da4049752";
        } else if (unit === unitList[3]) {
          unitId = "f9f928a7-fb2b-406e-bf08-4bec6467b23a";
        } else if (unit === unitList[4]) {
          unitId = "4b4cae48-b6c1-4c02-944c-740c9cc7a773";
        } else if (unit === unitList[5]) {
          unitId = "493c9389-ac42-4ca6-8d0e-49a05449f709";
        } else if (unit === unitList[6]) {
          unitId = "76ad69fb-13ef-4ed5-959b-da7f1bd873a3";
        } else if (unit === unitList[7]) {
          unitId = "6de5dff7-98f3-4551-8139-bde67fc58634";
        } else if (unit === unitList[8]) {
          unitId = "3f03e234-64a8-4b73-ab13-db6610356372";
        } else if (unit === unitList[9]) {
          unitId = "3d183ac7-39a7-4a41-ad1d-7a56612bed85";
        } else if (unit === unitList[10]) {
          unitId = "9c75515a-a990-4708-bd73-9d4f7e5f4d05";
        } else if (unit === unitList[11]) {
          unitId = "1e9cb41e-e171-400c-ab09-0433cbae3043";
        } else if (unit === unitList[15]) {
          unitId = "c7c6e539-82fe-4ee0-89c7-f4af474f32ec";
        } else {
          alert("Chosen unit is not a lesson");
          return;
        }

        let id = uuidv4().toString();

        content = {
          ...content,
          UnitGuid: unitId,
          Guid: id,
        };

        totalContent.push(content);
      }

      if (year2) {
        var unitId = "";
        if (unit === unitList[0]) {
          unitId = "029bfe71-c69b-4d62-8d45-e1301d8d3b8e";
        } else if (unit === unitList[1]) {
          unitId = "d484b0ff-fb90-430b-a1c9-d36491f5001b";
        } else if (unit === unitList[2]) {
          unitId = "2d2c53e5-c170-499f-91f0-cbf2fa88676c";
        } else if (unit === unitList[3]) {
          unitId = "885145b1-c9e6-4b3a-9ab3-15d071dd25c5";
        } else if (unit === unitList[4]) {
          unitId = "70cc33f2-c34c-4baa-98f8-cbd929d2f2ff";
        } else if (unit === unitList[5]) {
          unitId = "ed054847-d838-4c4b-a2ce-6c6167d73a0c";
        } else if (unit === unitList[6]) {
          unitId = "e0e48371-c10a-4386-af95-22a4f2a7be9f";
        } else if (unit === unitList[7]) {
          unitId = "008115c8-03af-427c-8b1c-7a5e76bf6c68";
        } else if (unit === unitList[8]) {
          unitId = "fbaccb75-bdba-45a8-b620-63fdc5bf0d2d";
        } else if (unit === unitList[9]) {
          unitId = "bc8d53d7-c92c-4811-af7a-4a93ea2f5bd8";
        } else if (unit === unitList[10]) {
          unitId = "5e1f0bb3-ae4b-4edc-a3f6-72d9b30c5612";
        } else if (unit === unitList[11]) {
          unitId = "ac1335b5-44e7-48e8-b159-83c7bf622494";
        } else if (unit === unitList[15]) {
          unitId = "d5a9df22-3755-4fc0-86d5-913a141e67f7";
        } else {
          alert("Chosen unit is not a lesson");
          return;
        }

        let id = uuidv4().toString();

        content = {
          ...content,
          UnitGuid: unitId,
          Guid: id,
        };

        totalContent.push(content);
      }
      if (year3) {
        var unitId = "";
        if (unit === unitList[0]) {
          unitId = "ad3a9b18-632c-40fd-8aa3-10781fec1a4b";
        } else if (unit === unitList[1]) {
          unitId = "ad9ab324-8fd1-4420-a378-987c03c5efa2";
        } else if (unit === unitList[2]) {
          unitId = "a992588c-3384-4762-8e86-cbfcb8688e3f";
        } else if (unit === unitList[3]) {
          unitId = "ecb16cf3-336d-4a37-9789-3023320c4034";
        } else if (unit === unitList[4]) {
          unitId = "244e05f1-885a-4eb3-87c2-9bfa035bcc97";
        } else if (unit === unitList[5]) {
          unitId = "f483a54c-0752-461a-b9bb-14dffd347b0a";
        } else if (unit === unitList[6]) {
          unitId = "0e382bf2-244f-43b6-b1bc-99eca54702d6";
        } else if (unit === unitList[7]) {
          unitId = "b6512d34-e952-4e28-9c60-9ca05a2ce97c";
        } else if (unit === unitList[8]) {
          unitId = "3253f303-9407-49b8-a2f9-2437e15bfd28";
        } else if (unit === unitList[9]) {
          unitId = "0c054140-dff8-4ff6-bff2-3cdf678f8d01";
        } else if (unit === unitList[10]) {
          unitId = "b8bcb0a6-03ac-4bdc-a03c-b7ef41df3261";
        } else if (unit === unitList[11]) {
          unitId = "1e33f727-cb3e-4d8f-aa0e-7b15739483b2";
        } else if (unit === unitList[15]) {
          unitId = "97205cb3-1536-46cf-87a6-0776f1f72d05";
        } else {
          alert("Chosen unit is not a lesson");
          return;
        }

        let id = uuidv4().toString();

        content = {
          ...content,
          UnitGuid: unitId,
          Guid: id,
        };

        totalContent.push(content);
      }

      let hash = uuidv4().toString();
      console.log(totalContent);
      await uploadContentToDatabase(totalContent, "Lessons", hash);
    }

    if (type === "Movement Break") {
      var unitId = "";
      if (unit === unitList[12]) {
        unitId = "8860e479-d529-4c53-bc47-7047a7922021";
      } else if (unit === unitList[13]) {
        unitId = "0c390060-2ed5-4cd9-8806-699a187f3200";
      } else if (unit === unitList[14]) {
        unitId = "839745fb-f56b-4b39-acae-053d73afecfd";
      } else {
        alert("Chosen unit is not a movement break");
        return;
      }
      if (year0) {
        let id = uuidv4().toString();

        content = {
          ...content,
          CategoryGuid: unitId,
          Guid: id,
          Year: 0,
        };

        totalContent.push(content);
      }

      if (year1) {
        let id = uuidv4().toString();

        content = {
          ...content,
          CategoryGuid: unitId,
          Guid: id,
          Year: 1,
        };

        totalContent.push(content);
      }

      if (year2) {
        let id = uuidv4().toString();

        content = {
          ...content,
          CategoryGuid: unitId,
          Guid: id,
          Year: 2,
        };

        totalContent.push(content);
      }
      if (year3) {
        let id = uuidv4().toString();

        content = {
          ...content,
          CategoryGuid: unitId,
          Guid: id,
          Year: 3,
        };

        totalContent.push(content);
      }

      let hash = uuidv4().toString();
      console.log(totalContent);
      await uploadContentToDatabase(totalContent, "Movement Breaks", hash);
    }
    resourceFiles.forEach((file) => {
      uploadFileToStorage("resources", file) 
    })
    setResourceFiles([])
    setResources([])
    alert("Uploaded Successfully");
  };

  const resourceUpload = (e) => {
    if (e.target.files) {
      var file = e.target.files[0];
      var resource = file.name
      setResources([...resources, resource]);
      setResourceFiles([...resourceFiles, file]);
    }
  };

  const removeResource = (resource) => {
    setResourceFiles(
      resourceFiles.filter((file) => file.name !== resource.title)
    );
    setResources(resources.filter((x) => x !== resource));
  };

  return (
    <Container className="full-page-length">
      <h1 className="pageTitle title-margin">Add Content</h1>
      <hr />
      <h2 className="headingColour">
        <b>Add Content</b>
      </h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder="Title"
            onChange={(event) => setTitle(event.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            placeholder="Description"
            as="textarea"
            rows={3}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{"Equipment, separate with \" & \" (Space&Space)"}</Form.Label>
          <Form.Control
            placeholder="Equipment1 & Equipment2"
            onChange={(event) => setEquipment(event.target.value)}
          />
        </Form.Group>
        <div>
          <div className="mb-3">Resources</div>
          {resources.map((resource, i) => {
            return (
              <div key={i}>
                <WorksheetBox
                  worksheet={{title: resource}}
                  delete={true}
                  deleteFunction={() => removeResource(resource)}
                />
                <br />
              </div>
            );
          })}
        </div>

        <Form.Group className="mb-3" controlId="fileUpload">
          <Form.Label>Resource Upload</Form.Label>
          <Form.Control
            type="file"
            placeholder="Add Resource"
            onChange={(e) => resourceUpload(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Type</Form.Label>
          <Select
            onChange={(value) => setType(value.value)}
            options={typeList.map((type) => {
              return {
                value: type,
                label: type,
              };
            })}
          ></Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Youtube ID (Digits at end of URL)</Form.Label>
          <Form.Control
            placeholder="ID"
            onChange={(event) => setYoutubeId(event.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Years</Form.Label>
          <Form.Check
            id="year-0"
            label="Junior and Senior"
            checked={year0}
            onChange={() => setYear0(!year0)}
          />
          <Form.Check
            id="year-1"
            label="1st and 2nd"
            checked={year1}
            onChange={() => setYear1(!year1)}
          />
          <Form.Check
            id="year-2"
            label="3rd and 4th"
            checked={year2}
            onChange={() => setYear2(!year2)}
          />
          <Form.Check
            id="year-3"
            label="5th and 6th"
            checked={year3}
            onChange={() => setYear3(!year3)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Unit</Form.Label>
          <Select
            onChange={(value) => setUnit(value.value)}
            options={unitList.map((unit) => {
              return {
                value: unit,
                label: unit,
              };
            })}
          ></Select>
        </Form.Group>
        <Stack>
          <Button
            variant="primary"
            className="mainButton"
            onClick={() => uploadContent()}
          >
            Add Content
          </Button>
        </Stack>
      </Form>
    </Container>
  );
}

export default GlenAdminPage;
