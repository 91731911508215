import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CategoryBox from "../../../components/CategoryBox";
import EditableBox from "../../../components/EditableBox";
import {
  getStrandCategories,
  getStrandNew,
  updateStrand,
  uploadStrand,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

function AddStrandPage() {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  let { id } = useParams();

  const [strand, setStrand] = useState({
    Title: "",
    StrandNumber: 0,
    Guid: "",
    ImageUri: "icon_logo",
    ColourName: "Green",
    Active: false,
    Categories: [],
  });
  const [categories, setCategories] = useState([]);
  const [edit, setEdit] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Daly Exercise+ | Strand Details";
  }, [data]);

  const upload = async (e) => {
    e.preventDefault();
    let successfulUpload = await uploadStrand(strand);
    if (successfulUpload) {
      navigate(-1);
    }
  };

  const updateTitle = (val) => {
    setStrand({
      ...strand,
      Title: val,
    });
  };

  const updateColour = (val) => {
    setStrand({
      ...strand,
      ColourName: val,
    });
  };

  const updateImage = (val) => {
    setStrand({
      ...strand,
      ImageUri: val,
    });
  };

  const updateOrder = (val) => {
    setStrand({
      ...strand,
      StrandNumber: parseInt(val),
    });
  };

  const updateActive = (val) => {
    setStrand({
      ...strand,
      Active: val,
    });
  };

  const updateType = (val) => {
    setStrand({
      ...strand,
      Type: val,
    });
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Add Strand</h1>
          <br />
          <Form className="content-item-container" onSubmit={(e) => upload(e)}>
            <Row>
              <Col md={4}>
                <Form.Group className="content-item-box">
                  <h3 className="content-item-title">Title</h3>
                  <Form.Control
                    required
                    value={strand.Title}
                    disabled={!edit}
                    onChange={(event) => updateTitle(event.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box">
                  <h3 className="content-item-title">Colour</h3>
                  <Form.Select
                    required
                    onChange={(event) => updateColour(event.target.value)}
                    disabled={!edit}
                  >
                    <option>{strand.ColourName}</option>
                    <option value="TextPrimary">Black</option>
                    <option value="Green">Green</option>
                    <option value="Blue">Blue</option>
                    <option value="Purple">Purple</option>
                    <option value="Yellow">Yellow</option>
                    <option value="Red">Red</option>
                    <option value="Blue">Blue</option>
                    <option value="ActiveColour">Pink</option>
                    <option value="CalmingColour">Light Blue</option>
                    <option value="TikTokColour">Logo Colour</option>
                    <option value="ChristmasColour">Christmas (Pinkish)</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box">
                  <h3 className="content-item-title">Icon</h3>
                  <Form.Select
                    required
                    onChange={(event) => updateImage(event.target.value)}
                    disabled={!edit}
                  >
                    <option disabled>{strand.ImageUri}</option>
                    <option value="icon_athletics">icon_athletics</option>
                    <option value="icon_alerting">icon_alerting</option>
                    <option value="icon_run">icon_athletics</option>
                    <option value="icon_brainbreak_tab">
                      icon_brainbreak_tab
                    </option>
                    <option value="icon_calming">icon_calming</option>
                    <option value="icon_christmas">icon_christmas</option>
                    <option value="icon_classroom_tab">
                      icon_classroom_tab
                    </option>
                    <option value="icon_dance">icon_dance</option>
                    <option value="icon_delete">icon_delete</option>
                    <option value="icon_easter">icon_easter</option>
                    <option value="icon_games">icon_games</option>
                    <option value="icon_graded">icon_graded</option>
                    <option value="icon_group">icon_group</option>
                    <option value="icon_gymnastics">icon_gymnastics</option>
                    <option value="icon_halloween">icon_halloween</option>
                    <option value="icon_heart">icon_heart</option>
                    <option value="icon_help">icon_help</option>
                    <option value="icon_home">icon_home</option>
                    <option value="icon_homework_tab">icon_homework_tab</option>
                    <option value="icon_lessons_tab">icon_lessons_tab</option>
                    <option value="icon_logo">icon_logo</option>
                    <option value="icon_nc_games">icon_nc_games</option>
                    <option value="icon_new">icon_new</option>
                    <option value="icon_pencil">icon_pencil</option>
                    <option value="icon_random">icon_random</option>
                    <option value="icon_sports_day">icon_sports_day</option>
                    <option value="icon_thumbsup">icon_thumbsup</option>
                    <option value="icon_tiktok">icon_tiktok</option>
                    <option value="icon_timer">icon_timer</option>
                    <option value="icon_tool">icon_tool</option>
                    <option value="icon_warmup">icon_warmup</option>
                    <option value="icon_wave">icon_wave</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box">
                  <h3 className="content-item-title">ID</h3>
                  <Form.Control value={"Randomly Generated"} disabled />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box" required>
                  <h3 className="content-item-title">
                    Order (-1 for Holiday Strands)
                  </h3>
                  <Form.Control
                    type="number"
                    value={strand.StrandNumber}
                    disabled={!edit}
                    onChange={(event) => updateOrder(event.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box" required>
                  <h3 className="content-item-title">Is Active</h3>
                  <Form.Check
                    disabled={!edit}
                    defaultChecked={strand.Active}
                    onChange={(event) => updateActive(event.target.checked)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box">
                  <h3 className="content-item-title">Type</h3>
                  <Form.Select
                    required
                    onChange={(event) => updateType(event.target.value)}
                    disabled={!edit}
                  >
                    <option value="Lesson">Lesson</option>
                    <option value="Movement Break">Movement Break</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={8}></Col>
              <Col md={4}>
                <button type="submit" className={`custom-card-button`}>
                  Upload
                </button>
              </Col>
              <Col md={12}>
                <CategoryBox
                  strand={strand}
                  key={strand.Guid}
                  test={true}
                  noClick={true}
                ></CategoryBox>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </Container>
  );
}

export default AddStrandPage;
