import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import InviteCodeBox from "../components/InviteCodeBox";
import { getAdminDetails } from "../firebase";
import { UserContext } from "../providers/UserProvider";

const InviteCodesPage = () => {
  const [adminDetails, setAdminDetails] = useState({});
  const { data } = useContext(UserContext);
  useEffect(() => {
    document.title = "Daly Exercise+ | Invite Codes";
    if ("UserName" in data) {
      getAdminDetails(data.Guid).then((result) => {
        setAdminDetails(result);
      });
    }
  }, [data]);
  return (
    <Container className="full-page-length">
      <h1 className="pageTitle title-margin">Invite Codes</h1>
      <hr />
      <p className="text-center">
        To assign a code to an account, please enter the email address of the
        account into one the boxes below.
      </p>
      <p className="text-center">
        For new accounts, we will send an email explaining how to setup their
        account.
      </p>

      {adminDetails.InviteCodes
        ? adminDetails.InviteCodes.map((value, i) => {
            return <InviteCodeBox key={i} InviteCode={value} />;
          })
        : ""}
    </Container>
  );
};

export default InviteCodesPage;
