import React, { useEffect, useState } from "react";

const AsyncComponent = ({ boolFunction, children }) => {
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await boolFunction();
        setResult(result);
      } catch (error) {
        console.error("Error checking if category is empty:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [boolFunction, children]);

  // Optionally, show a loading message or spinner while the async check is in progress
  if (loading) {
    return <div></div>;
  }

  // Only render if the category is empty
  if (!result) {
    return <div></div>;
  }

  return children;
};

export default AsyncComponent;
