import { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Stack, Tab, Table, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";
import StrandCategoryBox from "../../components/new-components/StrandCategoryBox";
import StudentBox from "../../components/StudentBox";
import { getClassroomStudents } from "../../functions/classroomFunctions";
import { UserContext } from "../../providers/UserProvider";

function ClassroomWorkoutsPage() {
  const [className, setClassName] = useState("");
  const [students, setStudents] = useState([]);
  const { data2 } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Classroom";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2]);

  const goToWorkoutsType = (type) => {
    navigate(type);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Classroom Workouts</h1>
          <br />
          <Row className="structured-lesson-list-container ">
            <Col md={4}>
              <StrandCategoryBox
                title="Random Workout"
                icon="icon_random"
                color="calmingColour"
                content="Random Workout"
                click={() => goToWorkoutsType("random")}
              ></StrandCategoryBox>
            </Col>
            <Col md={4}>
              <StrandCategoryBox
                title="Custom Workout"
                icon="icon_pencil"
                color="yellow"
                content="Custom Workout"
                click={() => goToWorkoutsType("custom")}
              ></StrandCategoryBox>
            </Col>
            <Col md={4}>
              <StrandCategoryBox
                title="Saved Workouts"
                icon="icon_tool_singular"
                color="textPrimary"
                content="Saved Workouts"
                click={() => goToWorkoutsType("saved")}
              ></StrandCategoryBox>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default ClassroomWorkoutsPage;
