import { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  getAllCategoriesNew,
  getAllLessons,
  getAllMovementBreakStrands,
  getAllStrandsNew,
  getAllSubcategoriesNew,
  uploadAllCategoriesNew,
  uploadAllLessonsNew,
  uploadAllSubCategoriesNew,
} from "../../firebase";
import CategoryBox from "../../components/CategoryBox";
import SearchBarLink from "../../components/SearchBarLink";
import { getEventStrandsByYear, getStrandsByYear } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import StructuredLessonItem from "../../components/StructuredLessonItem";
import StrandCategoryBox from "../../components/new-components/StrandCategoryBox";
import { Link } from "react-router-dom";
import AsyncComponent from "../../components/generic/AsyncComponent";
import { checkIfStrandIsEmpty } from "../../functions/lessonFunctions";

function UpdatedMovementBreakListPage() {
  const [strandsText, setStrandsText] = useState("");
  const [strands, setStrands] = useState([]);
  const { data, data2 } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Movement Break Strands";
    if ("Year" in data2) {
      if (data2.Year === 0) {
        setStrandsText("Junior & Senior");
      } else if (data2.Year === 1) {
        setStrandsText("1st & 2nd");
      } else if (data2.Year === 2) {
        setStrandsText("3rd & 4th");
      } else if (data2.Year === 3) {
        setStrandsText("5th & 6th");
      } else if (data2.Year === 10) {
        setStrandsText("Mild SEN");
      } else if (data2.Year === 11) {
        setStrandsText("Moderate SEN");
      } else if (data2.Year === 12) {
        setStrandsText("Severe-Profound SEN");
      }
      setLoading(true);
      getAllMovementBreakStrands().then((res) => {
        if (res.length > 0) {
          setStrands(res.sort((a, b) => a.StrandNumber > b.StrandNumber));
        }
        setLoading(false);
      });
    }
  }, [data, data2]);

  const goToStrand = (guid) => {
    navigate(guid);
  };

  const goToWorkoutsType = (type) => {
    navigate("/workouts/" + type);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <Stack direction="horizontal">
            <h1 className="pageTitle title-margin w-100">
              Movement Breaks for {strandsText}
            </h1>
            <Link className="search-icon-wrapper" to={"/search"}>
              <img
                alt="Search Icon"
                title="Video Search"
                src="/icons/icon_search.png"
                width="70"
                height="50"
                className=""
              ></img>
            </Link>
          </Stack>
          <br />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <div>
              <Row className="structured-lesson-list-container justify-content-center">
                {strands?.map((strand, i) => {
                  if (strand.Active) {
                    return (
                      <AsyncComponent
                        boolFunction={async () =>
                          !(await checkIfStrandIsEmpty(strand, data2.Year))
                        }
                      >
                        <Col md={4} key={i}>
                          <StrandCategoryBox
                            title={strand.Title}
                            icon={strand.ImageUri}
                            color={strand.ColourName}
                            content={strand.Title}
                            click={() => goToStrand(strand.Guid)}
                          />
                        </Col>
                      </AsyncComponent>
                    );
                  }
                  return "";
                })}
              </Row>
              {data2.Year < 12 ? (
                <Row className="structured-lesson-list-container justify-content-center">
                  <Col md={4}>
                    <StrandCategoryBox
                      title="Random Workout"
                      icon="icon_random"
                      color="calmingColour"
                      content="Random Workout"
                      click={() => goToWorkoutsType("random")}
                    ></StrandCategoryBox>
                  </Col>
                  <Col md={4}>
                    <StrandCategoryBox
                      title="Custom Workout"
                      icon="icon_create"
                      color="yellow"
                      content="Custom Workout"
                      click={() => goToWorkoutsType("custom")}
                    ></StrandCategoryBox>
                  </Col>
                  <Col md={4}>
                    <StrandCategoryBox
                      title="Saved Workouts"
                      icon="icon_classroom_workout"
                      color="textPrimary"
                      content="My Workouts"
                      click={() => goToWorkoutsType("saved")}
                    ></StrandCategoryBox>
                  </Col>
                </Row>
              ) : (
                <div className="structured-content-text text-center">
                  <br/>
                  There are currently no Movement Breaks for {strandsText}, however we hope to add them soon!
                </div>
              )}
            </div>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default UpdatedMovementBreakListPage;
