import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col, Button, Modal, Stack } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link } from "react-router-dom";
import ScheduleItem from "../../components/ScheduleItem";
import {
  deleteFromScheduleNew,
  getDateSchedule,
  getDaySchedule,
  removeItemFromSchedule,
} from "../../firebase";
import { UserContext } from "../../providers/UserProvider";

function UpdatedSchedulePage() {
  const [value, setValue] = useState(new Date());
  const [scheduleItems, setScheduleItems] = useState({});
  const [show, setShow] = useState(false);
  const [removeItemTitle, setRemoveItemTitle] = useState("");
  const [removeItemId, setRemoveItemId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { data } = useContext(UserContext);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Schedule";
    if ("UserName" in data) {
      getSchedule(new Date().setHours(0, 0, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const getSchedule = (e) => {
    var date = new Date(e);
    setValue(date);

    var dateString = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    getDateSchedule(data.Guid, dateString).then((result) => {
      setScheduleItems(result);
    });
  };

  const clickRemoveLessonFromSchedule = (e, id, title) => {
    setRemoveItemTitle(title);
    setRemoveItemId(id);
    handleShow();
  };

  const removeLessonFromSchedule = async () => {
    handleClose();
    var dateString = `${value.getFullYear()}-${
      value.getMonth() + 1
    }-${value.getDate()}`;
    await deleteFromScheduleNew(removeItemId, data.Guid, dateString);
    getSchedule(value);
  };

  const refreshSchedule = async () => {
    var dateString = `${value.getFullYear()}-${
      value.getMonth() + 1
    }-${value.getDate()}`;
    getDateSchedule(data.Guid, dateString).then((result) => {
      setScheduleItems(result);
    });
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Schedule</h1>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Do you wish to remove {removeItemTitle} from your schedule?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={removeLessonFromSchedule}>
                Remove
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="white-box">
            <Stack direction="horizontal">
              <h2 className="pageTitle">
                {value.toLocaleDateString(undefined, options)}
              </h2>
              <span
                className="material-symbols-outlined ms-auto grow"
                title="Refresh Schedule"
                onClick={(e) => refreshSchedule()}
              >
                refresh
              </span>
            </Stack>
          </div>
          <Row className="full-height">
            <Col md={6}>
              <div className="white-box equal-height">
                <Calendar onChange={getSchedule} value={value} />
                <div className="gap-2 d-grid">
                  <hr />
                  <Link to={`/lessons`} className="text-link d-grid">
                    <button variant="primary" className="custom-button-big">
                      Add a Lesson
                    </button>
                  </Link>
                  <br/>
                  <Link to={`/movement-breaks`} className="text-link d-grid">
                    <button variant="primary" className="custom-button-big">
                      Add a Movement Break
                    </button>
                  </Link>
                </div>
                <hr className="d-block d-sm-none" />
              </div>
            </Col>
            <Col md={6}>
              <div className="white-box equal-height">
                {Object.values(scheduleItems).length > 0 ? (
                  Object.values(scheduleItems).map((item, i) => {
                    return (
                      <Row key={i}>
                        <Col>
                          <ScheduleItem Item={item} />
                        </Col>
                        <Col
                          md={50}
                          lg={3}
                          xs={50}
                          sm={50}
                          className="align-items-center d-grid"
                        >
                          <Button
                            variant="danger"
                            onClick={(e) =>
                              clickRemoveLessonFromSchedule(
                                e,
                                item.Guid,
                                item.Title
                              )
                            }
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <h4 className="text-center my-auto">
                    You've nothing scheduled today
                  </h4>
                )}
                {/* <div className="sd-flex h-auto">
                  <hr />
                  <button
                    variant="primary"
                    className="custom-button-big w-100 custom-button-variant-good mt-auto"
                  >
                    Print Schedule
                  </button>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default UpdatedSchedulePage;
