import { Navbar, Container, Nav, Button } from "react-bootstrap";
import React from "react";
import logo from "../high_res_logo.png";
import cjflogo from"../cjf_logo.jpg";

import { Link } from "react-router-dom";

const LoggedOutNav = () => {
  return (
    <Navbar bg="white" expand="sm">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src={logo}
            width="120"
            height="50"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Brand as={Link} to="/">
         <div className="logo-divider"></div>
        </Navbar.Brand>
        <Navbar.Brand as={Link} to="https://www.cjfallon.ie/">
          <img
            alt=""
            src={cjflogo}
            width="96"
            height="40"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="justify-content-end ">
          <Nav.Link
              as={Link}
              to="/"
              className=" d-flex align-items-center"
            >
              <div className="">About</div>
            </Nav.Link>
          <Nav.Link
              as={Link}
              to="/pricing"
              className=" d-flex align-items-center"
            >
              <div className="">Pricing</div>
            </Nav.Link>
            
            <Nav.Link
              as={Link}
              to="/register/school"
              className=" d-flex align-items-center"
            >
              <div className="">School Sign Up</div>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/register/teacher"
              className="nav-highlight d-flex align-items-center"
            >
              <div>Free Trial</div>
            </Nav.Link>
            <Nav.Link as={Link} to="/login">
              <Button className="mainButton">Login</Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default LoggedOutNav;
