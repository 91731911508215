import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Row,
  Col,
  Stack,
} from "react-bootstrap";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../high_res_logo.png";
import cjflogo from "../cjf_logo.jpg";

const LoggedInFooter = () => {
  const navigate = useNavigate();

  const goToLink = (url) => {
    navigate(url);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  };

  return (
    <footer className="footer footer-background">
      <Container className="d-none d-sm-block">
        <Row>
          <Col md={6}>
            <div className="footer-logos">
              <Stack direction="horizontal">
                <Link to="/" onClick={() => goToLink("/")}>
                  <img
                    alt=""
                    src={logo}
                    width="150"
                    height="60"
                    className="d-inline-block align-top"
                  />{" "}
                </Link>
                <div className="logo-divider-thin"></div>
                <Link to="https://www.cjfallon.ie/">
                  <img
                    alt=""
                    src={cjflogo}
                    width="120"
                    height="45"
                    className="d-inline-block align-top"
                  />{" "}
                </Link>
              </Stack>
            </div>
          </Col>

          <Col md={3}></Col>

          <Col md={3}>
            <Link to={"/account"} className="text-link">
              <div className="footer-link" onClick={() => goToLink("/account")}>
                Account
              </div>
            </Link>
            <Link to={"/data-safety"} className="text-link">
              <div
                className="footer-link"
                onClick={() => goToLink("/data-safety")}
              >
                Data Request
              </div>
            </Link>
            <Link
              to={"https://www.dalyexercise.ie/app-contact/"}
              className="text-link"
            >
              <div className="footer-link">Help</div>
            </Link>
          </Col>
        </Row>
      </Container>

      {/* Mobile Stuff */}

      <Container className="d-block d-sm-none">
        <Row className="mt-3 mb-3">
          <Col xs={12} className="mb-3 d-flex justify-content-center">
            <div className="footer-logos mt-3">
              <Stack direction="horizontal">
                <Link to="/" onClick={() => goToLink("/")}>
                  <img
                    alt=""
                    src={logo}
                    width="130"
                    height="60"
                    className="d-inline-block align-top"
                  />{" "}
                </Link>
                <div className="logo-divider-thin"></div>
                <Link to="https://www.cjfallon.ie/">
                  <img
                    alt=""
                    src={cjflogo}
                    width="120"
                    height="45"
                    className="d-inline-block align-top"
                  />{" "}
                </Link>
              </Stack>
            </div>
          </Col>
          <Col xs={4} className="text-center">
            {" "}
            <Link to={"/account"} className="text-link">
              <div className="footer-link" onClick={() => goToLink("/account")}>
                Account
              </div>
            </Link>
          </Col>
          <Col xs={4} className="text-center">
            <Link to={"/data-safety"} className="text-link">
              <div
                className="footer-link"
                onClick={() => goToLink("/data-safety")}
              >
                Data Request
              </div>
            </Link>
          </Col>
          <Col xs={4} className="text-center">
            {" "}
            <Link
              to={"https://www.dalyexercise.ie/app-contact/"}
              className="text-link"
            >
              <div className="footer-link">Help</div>
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default LoggedInFooter;
