import { useEffect } from "react";
import { useState } from "react";
import { Card, Button, InputGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  addCodeToUser,
  getInviteCodeDetails,
  getUserEmail,
  removeCodeFromUser,
} from "../firebase";

const InviteCodeBox = (props) => {
  const [email, setEmail] = useState("");
  const [used, setUsed] = useState(false);
  const [addedEmail, setAddedEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [refresh, setRefresh] = useState("");

  useEffect(() => {
    getInviteCodeDetails(props.InviteCode).then((result) => {
      setUsed(result.IsTaken);
      if (result.IsTaken) {
        getUserEmail(result.TeacherGuid).then((email) => {
          setEmail(email);
        });
      }
    });
  }, [refresh]);

  const addCode = async () => {
    if (
      !addedEmail.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      setEmailError("Email Not Valid");
      return false;
    } else {
      setEmailError("");
      let result = await addCodeToUser(props.InviteCode, addedEmail);
      if ("Success" in result) {
        setRefresh(!refresh);
      } else {
        setEmailError("Something went wrong");
      }
    }
  };

  const removeCode = async () => {
    let result = await removeCodeFromUser(props.InviteCode);
    if ("Success" in result) {
      setRefresh(!refresh);
      setAddedEmail("");
    }
  };
  return (
    <Card style={{ margin: 10 }}>
      <Card.Header className="">Invite Code</Card.Header>
      <Card.Body>
        <Card.Title className="">{props.InviteCode}</Card.Title>
        {used ? (
          <>
            <p>{`Used By: ${email}`}</p>
            <Button variant="danger" onClick={removeCode}>
              Remove User
            </Button>
          </>
        ) : (
          <>
            <Form>
              <InputGroup>
                <InputGroup.Text>Assign To Email</InputGroup.Text>
                <Form.Control
                  value={addedEmail}
                  onChange={(e) => setAddedEmail(e.target.value)}
                  id="inlineFormInputGroupUsername"
                  placeholder="Email"
                />
              </InputGroup>
              <Form.Text id="emailHelpBlock" muted>
                {emailError}
              </Form.Text>
              <br />
            </Form>

            <Button variant="primary" onClick={addCode}>
              Assign
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default InviteCodeBox;
