import { useEffect, useState } from "react";
import { Card, Button, Stack, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const FMSItem = ({
  score,
  setScore,
  description,
  category,
  comment,
  setComment,
}) => {
  return (
    <tr>
      <td className="align-middle"><b>{category}</b></td>
      <td className="align-middle">{description}</td>

      <td className="align-middle p-0 fms-checkbox-cell">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div
            onClick={() => setScore(1)}
            className={`fms-checkbox fms-checkbox-1${
              score === 1 ? "-selected" : ""
            }`}
          >
            <div>1</div>
          </div>
          <div
            onClick={() => setScore(2)}
            className={`fms-checkbox fms-checkbox-2${
              score === 2 ? "-selected" : ""
            }`}
          >
            <div>2</div>
          </div>
          <div
            onClick={() => setScore(3)}
            className={`fms-checkbox fms-checkbox-3${
              score === 3 ? "-selected" : ""
            }`}
          >
            <div>3</div>
          </div>
          <div
            onClick={() => setScore(4)}
            className={`fms-checkbox fms-checkbox-4${
              score === 4 ? "-selected" : ""
            }`}
          >
            <div>4</div>
          </div>
          <div
            onClick={() => setScore(5)}
            className={`fms-checkbox fms-checkbox-5${
              score === 5 ? "-selected" : ""
            }`}
          >
            <div>5</div>
          </div>
        </div>
      </td>
      <td className="align-middle">
        <Form.Group controlId="exampleForm.ControlTextarea1" inline>
          <Form.Control
            as="textarea"
            rows={1}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Group>
      </td>
    </tr>
  );
};

export default FMSItem;
