import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CategoryBox from "../../../components/CategoryBox";
import {
  deleteCategory,
  deleteStrand,
  getAllStrandsNew,
  getAllStrandTypes,
  getCategoryNew,
  getStrandCategories,
  getStrandNew,
  getSubcategories,
  updateCategory,
  updateStrand,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

function CategoryDetailsPage() {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  let { id } = useParams();

  const [category, setCategory] = useState({});
  const [categoryCopy, setCategoryCopy] = useState({});
  const [subcategories, setSubcategories] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allStrands, setAllStrands] = useState([]);
  const [strand, setStrand] = useState({});
  const [strandCopy, setStrandCopy] = useState({});

  useEffect(() => {
    document.title = "Daly Exercise+ | Category Details";
    if ("UserName" in data) {
      setLoading(true);
      getCategoryNew(id).then((res) => {
        setCategory(res);
        setCategoryCopy(res);
        getSubcategories(res.SubCategories).then((subcategories) => {
          if (subcategories.length > 0) {
            setSubcategories(subcategories.sort((a, b) => a.Order - b.Order));
          }

          setLoading(false);
        });

        getAllStrandTypes().then((strands) => {
          setAllStrands(strands.sort((x) => x.StrandNumber));
          let catStrand = strands.find((x) => x.Categories?.includes(id));
          setStrand(catStrand);
          setStrandCopy(catStrand);
        });
      });
    }
  }, [data, id]);

  const changeEdit = async (e) => {
    e.preventDefault();
    if (edit) {
      let updateSuccessful = await updateCategory(
        categoryCopy,
        strandCopy,
        strand
      );
      if (updateSuccessful) {
        setStrand({
          ...strandCopy,
        });
        setCategory({
          ...categoryCopy,
        });
      } else {
        return;
      }
    }
    setEdit(!edit);
  };

  const cancelEdit = (e) => {
    e.preventDefault();
    setCategoryCopy({
      ...category,
    });
    setEdit(!edit);
  };

  const updateTitle = (val) => {
    setCategoryCopy({
      ...categoryCopy,
      Title: val,
    });
  };

  const updateOrder = (val) => {
    setCategoryCopy({
      ...categoryCopy,
      Order: parseInt(val),
    });
  };

  const updateActive = (val) => {
    setCategoryCopy({
      ...categoryCopy,
      Active: val,
    });
  };

  const updateStrand = (id) => {
    setStrandCopy(allStrands.find((x) => x.Guid === id));
  };

  const deleteCategoryClicked = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you wish to delete this category?")) {
      let success = await deleteCategory(category);
      if (success) {
        navigate("/content-management");
      }
    }
  };

  const goToSubCategory = (id) => {
    navigate(`/content-management/subcategory/${id}`);
  };

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Category Details</h1>
          <br />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <Form className="content-item-container">
              <Row>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Title</h3>
                    <Form.Control
                      value={categoryCopy.Title}
                      disabled={!edit}
                      onChange={(event) => updateTitle(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">ID</h3>
                    <Form.Control value={categoryCopy.Guid} disabled />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Order</h3>
                    <Form.Control
                      type="number"
                      value={categoryCopy.Order}
                      disabled={!edit}
                      onChange={(event) => updateOrder(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Strand</h3>
                    <Form.Select
                      defaultValue={strand.Guid}
                      onChange={(event) => updateStrand(event.target.value)}
                      disabled={!edit}
                      //   value={strandCopy}
                    >
                      <option disabled value={strand.Guid}>
                        {strand.Title}
                      </option>
                      {allStrands.map((strand, i) => (
                        <option key={i} value={strand.Guid}>
                          {strand.Title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Is Active</h3>
                    <Form.Check
                      disabled={!edit}
                      defaultChecked={categoryCopy.Active}
                      onChange={(event) => updateActive(event.target.checked)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}></Col>
                <Col md={4}>
                  <button
                    type="button"
                    className={`custom-card-button`}
                    onClick={(e) => changeEdit(e)}
                  >
                    {edit ? "Save" : "Edit"}
                  </button>
                </Col>
                <Col md={4}>
                  {edit ? (
                    <button
                      type="button"
                      className={`custom-card-button`}
                      onClick={(e) => cancelEdit(e)}
                    >
                      Cancel
                    </button>
                  ) : (
                    <div></div>
                  )}
                </Col>
                <Col md={4}>
                  <button
                    type="button"
                    className={`custom-card-button custom-card-button-variant-delete`}
                    onClick={(e) => deleteCategoryClicked(e)}
                  >
                    Delete
                  </button>
                </Col>
              </Row>
              <hr />
              <br />
              <h3 className="content-item-title">Sub Categories</h3>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Order</th>
                    <th># of lessons</th>
                  </tr>
                </thead>
                <tbody>
                  {subcategories.map((subcategory, i) => {
                    return (
                      <tr
                        key={i}
                        onClick={() => goToSubCategory(subcategory.Guid)}
                      >
                        <th>{subcategory.Title}</th>
                        <th>{subcategory.Order}</th>
                        <th>
                          {subcategory.Lessons ? subcategory.Lessons.length : 0}
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Form>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default CategoryDetailsPage;
