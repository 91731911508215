import { useEffect } from "react";
import { useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { helpLink } from "../helper-functions/ExternalLinks";

const AccountPropertyBox = (props) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (imageUrl === "") {
      switch (props.title) {
        case "Class Name":
          setImageUrl("/icons/icon_pencil.png");
          break;
        case "Year Group":
          setImageUrl("/icons/icon_lessons_tab.png");
          break;
        case "Account":
          setImageUrl("/icons/icon_account.png");
          break;
        case "Help":
          setImageUrl("/icons/icon_help.png");
          break;
        case "Number of Subscriptions":
          setImageUrl("/icons/icon_group.png");
          break;
        case "Number of Codes":
          setImageUrl("/icons/icon_group.png");
          break;
        case "Subscription Type":
          setImageUrl("/icons/icon_thumbsup.png");
          break;
        case "Total Price":
          setImageUrl("/icons/icon_account.png");
          break;
        case "School Name":
          setImageUrl("/icons/icon_home.png");
          break;
        case "Area":
          setImageUrl("/icons/icon_heart_beat.png");
          break;
        default:
          setImageUrl("/icons/icon_help.png");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card
      style={{ margin: 10 }}
      className={props.type === "help" ? "grow help" : ""}
      onClick={() => (props.type === "help" ? helpLink() : "")}
    >
      <Row className="align-items-center">
        <Col
          xs={2}
          style={{ display: "flex" }}
          className="justify-content-md-center"
        >
          <img alt="" src={imageUrl} width="80" height="80" className="m-1" />
        </Col>
        <Col xm={5}>
          <Card.Body>{props.title}</Card.Body>
        </Col>
        <Col className="text-end">
          {!props.edit ? (
            <Card.Body className="m-1">{props.value}</Card.Body>
          ) : props.type === "County" ? (
            <Form.Select
              onChange={(event) => props.setValue(event.target.value)}
            >
              <option value="Antrim">Antrim</option>
              <option value="Armagh">Armagh</option>
              <option value="Carlow">Carlow</option>
              <option value="Cavan">Cavan</option>
              <option value="Clare">Clare</option>
              <option value="Cork">Cork</option>
              <option value="Derry">Derry</option>
              <option value="Donegal">Donegal</option>
              <option value="Down">Down</option>
              <option value="Dublin">Dublin</option>
              <option value="Fermanagh">Fermanagh</option>
              <option value="Galway">Galway</option>
              <option value="Kerry">Kerry</option>
              <option value="Kildare">Kildare</option>
              <option value="Kilkenny">Kilkenny</option>
              <option value="Laois">Laois</option>
              <option value="Leitrim">Leitrim</option>
              <option value="Limerick">Limerick</option>
              <option value="Longford">Longford</option>
              <option value="Louth">Louth</option>
              <option value="Mayo">Mayo</option>
              <option value="Meath">Meath</option>
              <option value="Monaghan">Monaghan</option>
              <option value="Offaly">Offaly</option>
              <option value="Roscommon">Roscommon</option>
              <option value="Sligo">Sligo</option>
              <option value="Tipperary">Tipperary</option>
              <option value="Tyrone">Tyrone</option>
              <option value="Waterford">Waterford</option>
              <option value="Westmeath">Westmeath</option>
              <option value="Wexford">Wexford</option>
              <option value="Wicklow">Wicklow</option>
            </Form.Select>
          ) : props.type === "YearGroup" ? (
            <Form.Select
              
              defaultValue={props.selectNumber}
              onChange={(event) => props.setValue(event.target.value)}
            >
              <option value={0}>Junior or Senior</option>
              <option value={1}>1st or 2nd Class</option>
              <option value={2}>3rd or 4th Class</option>
              <option value={3}>5th or 6th Class</option>
            </Form.Select>
          ) : (
            <Form.Control
              type="text"
              placeholder={props.value}
              aria-describedby="emailHelpBlock"
              onChange={(event) => props.setValue(event.target.value)}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default AccountPropertyBox;
