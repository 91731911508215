import React, { useEffect, useState } from "react";
import { Container, Form, Button, Modal } from "react-bootstrap";
import { addStudentAsTeacher, auth } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";

function AddStudentPage() {
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const {data} = useContext(UserContext);
  const navigate = useNavigate();
  const handleValidation = async (event) => {
    let formIsValid = true;
    if (firstName === "") {
      formIsValid = false;
      setFirstNameError("Please enter a firstname");
    } else {
      setFirstNameError("");
    }

    if (surname === "") {
      formIsValid = false;
      setSurnameError("Please enter a surname");
    } else {
      setSurnameError("");
    }

    if (password === "") {
      formIsValid = false;
      setpasswordError("Please enter password");
    } else {
      setpasswordError("");
    }

    if (password !== repeatPassword) {
      formIsValid = false;
      setpasswordError("Passwords do not match");
    } else {
      setpasswordError("");
    }

    if (password.length < 6) {
      formIsValid = false;
      setpasswordError("Password must be greater than 6 characters");
    } else {
      setpasswordError("");
    }

    return formIsValid;
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const registerSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      handleShow();
    }
  };

  const registerStudent = async (e) => {
    handleClose();
    let result = await addStudentAsTeacher(
      firstName,
      surname,
      password,
      data.ClassId
    );
    if (result) {
      navigate("/classroom");
    } else {
      setFirstNameError("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Add Student";
  }, [data])

  return (
    <Container className="full-page-length">
      <h1 className="pageTitle title-margin">Add a Student to your Classroom</h1>
      <hr />
      <Form onSubmit={registerSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Student's Firstname</Form.Label>
          <Form.Control
            placeholder="Firstname"
            aria-describedby="emailHelpBlock"
            onChange={(event) => setFirstName(event.target.value)}
          />
          <Form.Text id="emailHelpBlock" muted>
            {firstNameError}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Student's Surname</Form.Label>
          <Form.Control
            placeholder="Surname"
            aria-describedby="surnameHelpBlock"
            onChange={(event) => setSurname(event.target.value)}
          />
          <Form.Text id="surnameHelpBlock" muted>
            {surnameError}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
            aria-describedby="passwordHelpBlock"
          />
          <Form.Text id="passwordHelpBlock" muted>
            {passwordError}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRepeatPassword">
          <Form.Label>Repeat Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(event) => setRepeatPassword(event.target.value)}
          />
        </Form.Group>
        <div className="d-grid">
          <Button variant="primary" type="submit" className="mainButton">
            Register
          </Button>
        </div>
      </Form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wish to add {firstName} {surname} with password{" "}
          {password} to your class?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={registerStudent}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AddStudentPage;
