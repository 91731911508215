import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { auth, resetUserPassword } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [emailError, setemailError] = useState("");
  const [hasReset, setHasReset] = useState(false);
  const navigate = useNavigate();
  const handleValidation = async (event) => {
    let formIsValid = true;

    if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
      formIsValid = false;
      setemailError("Email Not Valid");
      return false;
    } else {
      setemailError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const resetPassword = async () => {
    if (handleValidation) {
      if (await resetUserPassword(email)) {
        setHasReset(true);
      } else {
        setemailError("Something went wrong please try again.");
      }
    }
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Forgot Password";
  }, [])

  return (
    <Container className="register-form-box full-page-length">
      <h1 className="pageTitle title-margin">Forgot Password</h1>
      <hr />
      {hasReset ? (
        <div className="m-3 text-center fs-5">
          An email has been sent with further instructions.
        </div>
      ) : (
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Please enter your email:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              aria-describedby="emailHelpBlock"
              onChange={(event) => setEmail(event.target.value)}
            />
            <Form.Text id="emailHelpBlock" muted>
              {emailError}
            </Form.Text>
          </Form.Group>

          <div className="d-grid">
            <Button className="mainButton" onClick={() => resetPassword()}>
              Confirm
            </Button>
          </div>
          <Link to={`/login`} className="text-link d-grid m-3 text-center">
            <div>Login?</div>
          </Link>
        </Form>
      )}
    </Container>
  );
}

export default ForgotPasswordPage;
