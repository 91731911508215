import { useEffect } from "react";
import { useState } from "react";
import { Card, Row, Col, Stack } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const CategoryBox = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const [borderColour, setBorderColour] = useState("");
  const [isMB, setMB] = useState(false);
  const [imageSize, setImageSize] = useState("80");

  useEffect(() => {
    setImageUrl(`/icons/${props.strand?.ImageUri}.png`);

    switch (props.strand?.ColourName) {
      case "TextPrimary":
        setBorderColour("#404040");
        break;
      case "Green":
        setBorderColour("#159A27");
        break;
      case "Blue":
        setBorderColour("#36A9FB");
        break;
      case "Purple":
        setBorderColour("#AC00BF");
        break;
      case "Yellow":
        setBorderColour("#B99417");
        break;
      case "Red":
        setBorderColour("#D40101");
        break;
      case "ActiveColour":
        setBorderColour("#DA6CFB");
        break;
      case "CalmingColour":
        setBorderColour("#1FB3FF");
        break;
      case "TikTokColour":
        setBorderColour("#982D87");
        break;
      case "ChristmasColour":
        setBorderColour("#DA6CFB");
        break;
      default:
        setBorderColour(props.strand.ColourName);
    }

    if (props.MB) {
      setMB(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  return props.noClick ? (
    <Card style={{ marginBottom: 10, borderColor: borderColour }}>
      <Stack direction="horizontal" gap={1} className="my-auto">
        <img
          alt=""
          src={imageUrl}
          width={imageSize}
          height={imageSize}
          className="m-1"
        />

        <div className="fs-4">{props.strand.Title}</div>

        <div className="ms-auto fs-5 text-muted">
          {isMB
            ? ""
            : `${
                props.strand.StrandNumber > -1
                  ? "Strand: " + props.strand.StrandNumber
                  : "Holiday Exercises"
              }`}
        </div>
        <div className="mx-3">
          <AiOutlineArrowRight
            size="2em"
            color={borderColour}
          ></AiOutlineArrowRight>
        </div>
      </Stack>
    </Card>
  ) : (
    <Link className="text-link" to={`${props.strand.Guid}`}>
      <Card style={{ margin: 10, borderColor: borderColour }} className="grow">
        <Stack direction="horizontal" gap={1} className="my-auto">
          <img
            alt=""
            src={imageUrl}
            width={imageSize}
            height={imageSize}
            className="m-1"
          />

          <div className="fs-4">{props.strand.Title}</div>

          <div className="ms-auto fs-5 text-muted">
            {isMB
              ? ""
              : `${
                  props.strand.StrandNumber > -1
                    ? "Strand: " + props.strand.StrandNumber
                    : "Holiday Exercises"
                }`}
          </div>
          <div className="mx-3">
            <AiOutlineArrowRight
              size="2em"
              color={borderColour}
            ></AiOutlineArrowRight>
          </div>
        </Stack>
      </Card>
    </Link>
  );
};

export default CategoryBox;
