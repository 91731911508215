import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import UserProvider from "./providers/UserProvider";
import Application from "./Application";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/new-components/ScrollToTop";

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Application />
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
