import { useEffect } from "react";
import { Modal } from "react-bootstrap";

const CustomModal = (props) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.text}</Modal.Body>
      <Modal.Body>{props.subtext}</Modal.Body>
      <Modal.Footer>
        <button className="custom-button custom-button-variant-bad" onClick={props.onClose}>
          Close
        </button>
        <button className="custom-button custom-button-variant-good" onClick={props.onConfirm}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
