import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CategoryBox from "../../../components/CategoryBox";
import SearchBar from "../../../components/SearchBar";
import {
  deleteCategory,
  deleteStrand,
  deleteSubCategory,
  getAllCategoriesNew,
  getAllLessonsNew,
  getAllStrandsNew,
  getAllStrandTypes,
  getAllSubcategoryLessons,
  getCategoryNew,
  getStrandCategories,
  getStrandNew,
  getSubcategories,
  getSubcategoryLessons,
  getSubCategoryNew,
  updateCategory,
  updateStrand,
  updateSubCategory,
  uploadSubCategory,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

function AddSubCategoryPage() {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  let { id } = useParams();

  const [searchValue, setSearchValue] = useState("");
  const [subcategory, setSubcategory] = useState({
    Title: "",
    Order: 0,
    Guid: "",
    Active: false,
    Lessons: [],
  });
  const [category, setCategory] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allStrands, setAllStrands] = useState([]);
  const [strand, setStrand] = useState({});
  const [currentCategories, setCurrentCategories] = useState([]);

  useEffect(() => {
    document.title = "Daly Exercise+ | Add Sub Category";
    if ("UserName" in data) {
      setLoading(true);

      getAllCategoriesNew().then((categories) => {
        setAllCategories(categories.sort((x) => x.Order));
        let subCatCat = categories.find((x) => x.SubCategories?.includes(id));
        setCategory(subCatCat);
        getAllStrandTypes().then((strands) => {
          setAllStrands(strands.sort((x) => x.StrandNumber));
          let catStrand = strands[0];
          setStrand(catStrand);
          updateStrandCategories(catStrand, categories);
          setLoading(false);
        });
      });
    }
  }, [data]);

  const upload = async (e) => {
    e.preventDefault();
    let successfulUpload = await uploadSubCategory(subcategory, category);

    if (successfulUpload) {
      navigate(-1);
    }
  };

  const updateTitle = (val) => {
    setSubcategory({
      ...subcategory,
      Title: val,
    });
  };

  const updateOrder = (val) => {
    setSubcategory({
      ...subcategory,
      Order: parseInt(val),
    });
  };

  const updateActive = (val) => {
    setSubcategory({
      ...subcategory,
      Active: val,
    });
  };

  const updateStrand = (id) => {
    let newStrand = allStrands.find((x) => x.Guid === id);
    setStrand(newStrand);
    updateStrandCategories(newStrand, allCategories);
  };

  const updateCategory = (id) => {
    setCategory(allCategories.find((x) => x.Guid === id));
  };

  const updateStrandCategories = (strand, categories) => {
    if (!("Categories" in strand) || strand.Categories?.length === 0) {
      return [];
    }
    let newCats = strand.Categories?.map((id) => {
      return categories.find((cat) => cat.Guid === id);
    });
    newCats = newCats.filter(function (element) {
      return element !== undefined;
    });
    setCategory(newCats[0]);
    setCurrentCategories(newCats);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Add Sub Category</h1>
          <br />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <Form
              className="content-item-container"
              onSubmit={(e) => upload(e)}
            >
              <Row>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Title</h3>
                    <Form.Control
                      required
                      value={subcategory.Title}
                      onChange={(event) => updateTitle(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">ID</h3>
                    <Form.Control value={"Randomly Generated"} disabled />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Order</h3>
                    <Form.Control
                      required
                      type="number"
                      value={subcategory.Order}
                      onChange={(event) => updateOrder(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Strand</h3>
                    <Form.Select
                      onChange={(event) => updateStrand(event.target.value)}
                    >
                      {allStrands.map((strand, i) => (
                        <option key={i} value={strand.Guid}>
                          {strand.Title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Category</h3>
                    <Form.Select
                      onChange={(event) => updateCategory(event.target.value)}
                      required
                    >
                      {currentCategories.map((category, i) => (
                        <option key={i} value={category.Guid}>
                          {category.Title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Is Active</h3>
                    <Form.Check
                      required
                      defaultChecked={subcategory.Active}
                      onChange={(event) => updateActive(event.target.checked)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <button type="submit" className={`custom-card-button`}>
                    Upload
                  </button>
                </Col>
              </Row>
              <hr />
            </Form>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default AddSubCategoryPage;
