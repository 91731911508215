import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CategoryBox from "../../../components/CategoryBox";
import EditableBox from "../../../components/EditableBox";
import {
    getAllStrandsNew,
  getAllStrandTypes,
  getStrandCategories,
  getStrandNew,
  updateStrand,
  uploadCategory,
  uploadStrand,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

function AddCategoryPage() {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  let { id } = useParams();

  const [category, setCategory] = useState({
    Title: "",
    Order: 0,
    Guid: "",
    Active: false,
    SubCategories: [],
  });
  const [edit, setEdit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [allStrands, setAllStrands] = useState([]);
  const [strand, setStrand] = useState({});
  useEffect(() => {
    document.title = "Daly Exercise+ | Category Upload";
    if ("UserName" in data) {
      getAllStrandTypes().then((strands) => {
        setAllStrands(strands.sort((x) => x.StrandNumber));
        setStrand(strands.sort((x) => x.StrandNumber)[0])
      });
    }
  }, [data]);

  const upload = async (e) => {
    e.preventDefault();
    let successfulUpload = await uploadCategory(category, strand);
    if (successfulUpload) {
      navigate(-1);
    }
  };

  const updateTitle = (val) => {
    setCategory({
      ...category,
      Title: val,
    });
  };

  const updateOrder = (val) => {
    setCategory({
      ...category,
      Order: parseInt(val),
    });
  };

  const updateActive = (val) => {
    setCategory({
      ...category,
      Active: val,
    });
  };

  const updateStrand = (id) => {
    setStrand(allStrands.find((x) => x.Guid === id));
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Add Category</h1>
          <br />
          <Form className="content-item-container" onSubmit={(e) => upload(e)}>
            <Row>
              <Col md={4}>
                <Form.Group className="content-item-box">
                  <h3 className="content-item-title">Title</h3>
                  <Form.Control
                    required
                    value={category.Title}
                    disabled={!edit}
                    onChange={(event) => updateTitle(event.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box">
                  <h3 className="content-item-title">ID</h3>
                  <Form.Control value={"Randomly Generated"} disabled />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box" required>
                  <h3 className="content-item-title">Order</h3>
                  <Form.Control
                    type="number"
                    value={category.StrandNumber}
                    disabled={!edit}
                    onChange={(event) => updateOrder(event.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box" required>
                  <h3 className="content-item-title">Is Active</h3>
                  <Form.Check
                    disabled={!edit}
                    defaultChecked={category.Active}
                    onChange={(event) => updateActive(event.target.checked)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="content-item-box">
                  <h3 className="content-item-title">Strand</h3>
                  <Form.Select
                    onChange={(event) => updateStrand(event.target.value)}
                    disabled={!edit}
                    //   value={strandCopy}
                  >
                    {allStrands.map((strand, i) => (
                      <option key={i} value={strand.Guid}>
                        {strand.Title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <button type="submit" className={`custom-card-button`}>
                  Upload
                </button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </Container>
  );
}

export default AddCategoryPage;
