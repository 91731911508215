import { useEffect, useState } from "react";
import { Card, Button, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import StructuredLessonListBox from "./StructuredLessonListBox";

const StructuredLessonListGroup = (props) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const contentSelected = (i) => {
    props.click(props.group, i);
  };

  const [edit, setEdit] = useState(false);

  const editClicked = async () => {
    if (await props.editGroup(props.group.order)){
      setEdit(!edit);
    }
    
    
  };
  return (
    <div className={`p-0 structured-lesson-list-box`}>
      <Stack
        direction="horizontal"
        className={`px-3 structured-lesson-list-title grow${
          props.group.selected ? " structured-lesson-list-selected" : ""
        }`}
        onClick={() => contentSelected(0)}
      >
        <div> {props.group.title}</div>

        {props.edit ? (
          <Button className="ms-auto mainButton" onClick={() => editClicked()}>
            {edit ? "Save Group" : "Edit Group"}
          </Button>
        ) : (
          <></>
        )}
      </Stack>
      <div className="px-2">
        {props.group.lessons?.map((lesson, i) => {
          return (
            <StructuredLessonListBox
              lesson={lesson}
              key={i}
              click={() => contentSelected(i)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StructuredLessonListGroup;
