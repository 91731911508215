import { Card, Row, Stack } from "react-bootstrap";
import Image from "react-bootstrap/Image";

const HomeworkBox = (props) => {
  const options = {
    weekday: "short",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  };
  return (
    <Card
      style={{ margin: 10 }}
      className="grow"
      onClick={() => props.link(props.details)}
    >
      <Row>
        <Stack direction="horizontal">
          <Image
            src="/icons/icon_heart_beat.png"
            width="50"
            height="50"
          ></Image>
          <div className="m-3 ms-0"><b>{props.details.Title}</b></div>
          <div className="ms-auto m-3">{props.details.Completed}</div>
        </Stack>
      </Row>
      <Row>
        <Stack direction="horizontal">
          <div className="m-3">
            Due{" "}
            {new Date(props.details.DueDate).toLocaleDateString(
              undefined,
              options
            )}
          </div>
        </Stack>
      </Row>
    </Card>
  );
};

export default HomeworkBox;
