import { forwardRef, useEffect, useState } from "react";
import { Card, Container, Dropdown, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getExerciseUrl } from "../../firebase";

const WorkoutBox = ({ workout, deleteFunction }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    getExerciseUrl(`${workout.Thumbnail}`).then((res) => {
      setThumbnailUrl(res);
    });
  }, [workout]);
  const navigate = useNavigate();

  const getWorkoutLength = () => {
    let minutes = workout.Sets * workout.Exercises.length;
    return minutes;
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <div
      className={`workout-share-button ${
        copied ? "share-copied" : ""
      } copy-button`}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="center-icon copy-button">
        <span className="material-symbols-outlined copy-icon copy-button">
          more_horiz
        </span>
      </div>
    </div>
  ));

  const workoutClicked = (e) => {
    if (
      !e.target.classList.contains("copy-button") &&
      !e.target.classList.contains("workout-dropdown")
    ) {
      navigate(`/workouts/${workout.Guid}`);
    }
  };

  const copyLink = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(
      `https://dalyexerciseplus.ie/workouts/${workout.Guid}`
    );
    setCopied(true);
  };

  const deleteWorkout = (e) => {
    e.preventDefault();
    const conf = window.confirm(
      "Are you sure you wish to delete this workout?"
    );

    if (conf) {
      deleteFunction(workout);
    }
  };

  return (
    <Card
      style={{ margin: 10 }}
      className="grow"
      onClick={(e) => workoutClicked(e)}
    >
      <Dropdown className="workout-dropdown ">
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
        ></Dropdown.Toggle>
        <Dropdown.Menu className="workout-dropdown workout-dropdown-placement">
          <Dropdown.Item
            className="workout-dropdown"
            as="button"
            onClick={copyLink}
          >
            Copy Link
          </Dropdown.Item>
          <Dropdown.Divider className="workout-dropdown" />
          <Dropdown.Item
            className="workout-dropdown"
            as="button"
            onClick={deleteWorkout}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Card.Img
        variant="top"
        src={thumbnailUrl}
        style={{ height: "250px", objectFit: "cover" }}
      />
      <div className="m-2">
        <h2 className="pageTitle title-margin text-truncate">{workout.Name}</h2>
        <h3 className=" text-center title-margin text-truncate">
          {getWorkoutLength()} minutes
        </h3>
      </div>
    </Card>
  );
};

export default WorkoutBox;
