import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router";
import ExercisePreviewBox from "../components/ExercisePreviewBox";
import HomeworkBox from "../components/HomeworkBox";
import HomeworkStudentBox from "../components/HomeworkStudentBox";
import {
  deleteScheduledHomework,
  getExercisesFromList,
  getExerciseUrl,
  getHomeworkFromId,
  GetTeacherHomework,
  giveStudentFeedback,
} from "../firebase";
import { UserContext } from "../providers/UserProvider";
import { useTimer } from "react-timer-hook";

const HomeworkTestPage = () => {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  const [homework, setHomework] = useState({});
  const [scheduledHomework, setScheduledHomework] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [historyHomework, setHistoryHomework] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showMoreScheduled, setShowMoreScheduled] = useState(false);
  const [totalStudents, setTotalStudents] = useState(0);
  const [completedStudentsNumber, setCompletedStudentsNumber] = useState(0);
  const [currentStudent, setCurrentStudent] = useState({});

  const [completedStudents, setCompletedStudents] = useState([]);
  const [notCompletedStudents, setNotCompletedStudents] = useState([]);

  const [exerciseName, setExerciseName] = useState("");
  const [playExercise, setPlayExercise] = useState(false);
  const [finished, setFinished] = useState(false);

  const [set, setSet] = useState(0);
  const [exerciseNum, setExerciseNum] = useState(0);

  const [gifUrl, setGifUrl] = useState("");
  let { id } = useParams();
  const [onBreak, setOnBreak] = useState(true);

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiretyTimestamp: new Date().setSeconds(new Date().getSeconds() + 300),
    onExpire: () => moveToNextExercise(),
    autoStart: false,
  });

  useEffect(() => {
    document.title = "Daly Exercise+ | Homework";
    if ("UserName" in data) {
      getHomeworkFromId(data.ClassId, id).then((res) => {
        setHomework(res);

        getExercisesFromList(res).then((exerciseVals) => {
          setExercises(exerciseVals);
          changeSelectedExercise(exerciseVals[0], false);
          pause();
        });
      });
    }
  }, [data]);

  const getNewTimer = (length, start) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + length);
    restart(time, start);
  };

  const changeSelectedExercise = async (exercise, start) => {
    if (onBreak) {
      let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
      setGifUrl(url);
      setExerciseName(exercise.Name);
      getNewTimer(exercise.Length, start);
      setOnBreak(false);
    } else {
      let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
      setExerciseName("Take a break!");
      getNewTimer(30, start);
      setOnBreak(true);
    }
  };

  const goBackToHomework = () => {
    navigate(-1);
  };

  const playerClicked = () => {
    if (playExercise) {
      pause();
    } else {
      resume();
    }
    setPlayExercise(!playExercise);
  };

  const moveToNextExercise = async () => {
    var start = true;
    if (exerciseNum + 1 >= exercises.length) {
      if (onBreak) {
        if (set + 1 >= homework.Sets) {
          setSet(0);
          setExerciseNum(0);
          start = false;
          setPlayExercise(false);
          setFinished(true);
          return;
        } else {
          setSet(set + 1);
        }
        setExerciseNum(0);

        await changeSelectedExercise(exercises[0], start);
      } else {
        if (set + 1 >= homework.Sets) {
          setSet(0);
          setExerciseNum(0);
          start = false;
          setPlayExercise(false);
          setFinished(true);
          return;
        }
        await changeSelectedExercise(exercises[0], start);
      }
      resume();
    } else {
      if (onBreak) {
        await changeSelectedExercise(exercises[exerciseNum + 1], true);
        setExerciseNum(exerciseNum + 1);
      } else {
        await changeSelectedExercise(exercises[exerciseNum + 1], true);
      }
    }
  };
  return (
    <Container className="full-page-length">
      <h1 className="pageTitle title-margin">{homework.Title}</h1>
      <hr />
      {finished ? (
        <div className="d-flex justify-content-center align-items-center text-center p-5">
          <div style={{ fontSize: "70px" }} className="headingColour">
            <span>All Finished!</span>
          </div>
        </div>
      ) : (
        <Row>
          {onBreak ? (
            <></>
          ) : (
            <Col xs={6}>
              <div
                className="d-flex justify-content-center full-screen-player"
                onClick={() => playerClicked()}
              >
                <ReactPlayer
                  className="react-player-full"
                  url={gifUrl}
                  loop={true}
                  playing={playExercise}
                  mute={true}
                />
              </div>
            </Col>
          )}
          <Col
            className="d-flex justify-content-center align-items-center text-center"
            xs={onBreak ? 12 : 6}
          >
            <div className="p-5">
              <div style={{ fontSize: "50px" }} className="headingColour">
                <span>{exerciseName}</span>
              </div>
              <hr />
              <div style={{ fontSize: "50px" }}>
                <span>Time Left</span>
              </div>
              <div style={{ fontSize: "80px" }}>
                <span>{seconds}</span>
              </div>
              <div style={{ fontSize: "30px" }}>
                <span>{"Set: " + (set + 1) + "/" + homework.Sets}</span>
              </div>
              <div style={{ fontSize: "30px" }}>
                <span>
                  {"Exercise: " + (exerciseNum + 1) + "/" + exercises.length}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <hr />
      <div className="d-grid">
        <Button variant="danger" onClick={() => goBackToHomework()} size="lg">
          Go Back
        </Button>
      </div>
    </Container>
  );
};

export default HomeworkTestPage;
