import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CategoryBox from "../../../components/CategoryBox";
import SearchBar from "../../../components/SearchBar";
import {
  deleteCategory,
  deleteStrand,
  deleteSubCategory,
  getAllCategoriesNew,
  getAllLessonsNew,
  getAllStrandsNew,
  getAllStrandTypes,
  getAllSubcategoryLessons,
  getCategoryNew,
  getStrandCategories,
  getStrandNew,
  getSubcategories,
  getSubcategoryLessons,
  getSubCategoryNew,
  updateCategory,
  updateStrand,
  updateSubCategory,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

function SubCategoryDetailsPage() {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  let { id } = useParams();

  const [searchValue, setSearchValue] = useState("");
  const [subcategory, setSubcategory] = useState({});
  const [subcategoryCopy, setSubcategoryCopy] = useState({});
  const [category, setCategory] = useState({});
  const [categoryCopy, setCategoryCopy] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allStrands, setAllStrands] = useState([]);
  const [strand, setStrand] = useState({});
  const [strandCopy, setStrandCopy] = useState({});
  const [lessons, setLessons] = useState([]);
  const [allLessons, setAllLessons] = useState([]);
  const [lessonsCopy, setLessonsCopy] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [addLesson, setAddLesson] = useState(false);

  useEffect(() => {
    document.title = "Daly Exercise+ | Sub Category Details";
    if ("UserName" in data) {
      getSubCategoryNew(id).then((res) => {
        setSubcategory(res);
        setSubcategoryCopy(res);
        getAllSubcategoryLessons(res.Lessons).then((lessons) => {
          if (lessons.length > 0) {
            let sorted = lessons.sort(
              (a, b) =>
                (a.Title < b.Title ? -1 : a.Title > b.Title ? 1 : 0) +
                (a.Year - b.Year) * 100
            );
            setLessons(sorted);
            setLessonsCopy(sorted);
          }
          setLoading(false);
        });

        getAllCategoriesNew().then((categories) => {
          setAllCategories(categories.sort((x) => x.Order));
          let subCatCat = categories.find((x) => x.SubCategories?.includes(id));
          setCategory(subCatCat);
          setCategoryCopy(subCatCat);
          getAllStrandTypes().then((strands) => {
            setAllStrands(strands.sort((x) => x.StrandNumber));
            let catStrand = strands.find((x) =>
              x.Categories?.includes(subCatCat.Guid)
            );
            setStrand(catStrand);
            setStrandCopy(catStrand);
            updateStrandCategories(catStrand, categories);
          });
        });
      });
    }
  }, [data, id]);

  const changeEdit = async (e) => {
    e.preventDefault();
    if (edit) {
      let updateSuccessful = await updateSubCategory(
        subcategoryCopy,
        categoryCopy,
        category
      );
      if (updateSuccessful) {
        setStrand({
          ...strandCopy,
        });
        setCategory({
          ...categoryCopy,
        });
        setSubcategory({
          ...subcategoryCopy,
        });

        setLessons({
          ...lessonsCopy,
        });
      } else {
        return;
      }
    }
    setEdit(!edit);
  };

  const cancelEdit = (e) => {
    e.preventDefault();
    setSubcategoryCopy({
      ...subcategory,
    });
    setLessonsCopy([...lessons]);
    setCategoryCopy({
      ...category,
    });
    setStrandCopy({
      ...strand,
    });
    setAddLesson(false);
    setEdit(false);
    setSearchValue("");
  };

  const updateTitle = (val) => {
    setSubcategoryCopy({
      ...subcategoryCopy,
      Title: val,
    });
  };

  const updateOrder = (val) => {
    setSubcategoryCopy({
      ...subcategoryCopy,
      Order: parseInt(val),
    });
  };

  const updateActive = (val) => {
    setSubcategoryCopy({
      ...subcategoryCopy,
      Active: val,
    });
  };

  const removeLesson = (id) => {
    let copy = [...lessonsCopy];
    let listCopy = [...subcategoryCopy.Lessons];
    let ind1 = lessonsCopy.findIndex((x) => x.Guid === id);
    let ind2 = listCopy.indexOf(id);
    listCopy.splice(ind2, 1);
    copy.splice(ind1, 1);
    setLessonsCopy(copy);
    setSubcategoryCopy({
      ...subcategoryCopy,
      Lessons: listCopy,
    });
  };

  const updateStrand = (id) => {
    let newStrand = allStrands.find((x) => x.Guid === id);
    setStrandCopy(newStrand);
    updateStrandCategories(newStrand, allCategories);
  };

  const updateCategory = (id) => {
    setCategoryCopy(allCategories.find((x) => x.Guid === id));
  };

  const deleteSubCategoryClicked = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you wish to delete this sub category?")) {
      let success = await deleteSubCategory(subcategory);
      if (success) {
        navigate("/content-management");
      }
    }
  };

  const getFilteredLessons = (lessons) => {
    if (searchValue === "") {
      return lessons;
    }

    let filtered = lessons?.filter((lesson) =>
      lesson.Title?.toLowerCase().includes(searchValue.toLowerCase())
    );

    filtered = filtered?.sort(
      (a, b) =>
        (a.Title < b.Title ? -1 : a.Title > b.Title ? 1 : 0) +
        (a.Year - b.Year) * 100
    );

    if (filtered.length === 0) {
      filtered.push({
        Title: "empty",
      });
    }

    return filtered;
  };

  const getCategoryStrand = () => {
    if (category === {} || allStrands.length === 0) {
      return {};
    }
    return allStrands.find((strand) =>
      strand.Categories.includes(category.Guid)
    );
  };

  const updateStrandCategories = (strand, categories) => {
    if (!("Categories" in strand) || strand.Categories?.length === 0) {
      return [];
    }
    let newCats = strand.Categories?.map((id) => {
      return categories.find((cat) => cat.Guid === id);
    });
    newCats = newCats.filter(function (element) {
      return element !== undefined;
    });
    setCurrentCategories(newCats);
  };

  const addLessonClicked = async () => {
    if (allLessons.length === 0) {
      let all = await getAllLessonsNew();
      setAllLessons(
        all.sort(
          (a, b) =>
            (a.Title < b.Title ? -1 : a.Title > b.Title ? 1 : 0) +
            (a.Year - b.Year) * 100
        )
      );
    }
    setAddLesson(!addLesson);
  };

  const addLessonToSub = (lesson) => {
    let copy = [...lessonsCopy];
    let listCopy = [...subcategoryCopy.Lessons];
    copy.push(lesson);
    listCopy.push(lesson.Guid);
    setLessonsCopy(copy);
    setSubcategoryCopy({
      ...subcategoryCopy,
      Lessons: listCopy,
    });
  };

  const goToLesson = (id) => {
    navigate(`/content-management/lesson/${id}`);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Sub Category Details</h1>
          <br />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <Form className="content-item-container">
              <Row>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Title</h3>
                    <Form.Control
                      value={subcategoryCopy.Title}
                      disabled={!edit}
                      onChange={(event) => updateTitle(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">ID</h3>
                    <Form.Control value={subcategoryCopy.Guid} disabled />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Order</h3>
                    <Form.Control
                      type="number"
                      value={subcategoryCopy.Order}
                      disabled={!edit}
                      onChange={(event) => updateOrder(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Strand</h3>
                    <Form.Select
                      onChange={(event) => updateStrand(event.target.value)}
                      disabled={!edit}
                      //   value={strandCopy}
                    >
                      <option value={strand.Guid}>{strand.Title}</option>
                      {allStrands.map((strand, i) => (
                        <option key={i} value={strand.Guid}>
                          {strand.Title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Category</h3>
                    <Form.Select
                      onChange={(event) => updateCategory(event.target.value)}
                      disabled={!edit}
                      //   value={strandCopy}
                    >
                      <option value={category.Guid}>{category.Title}</option>
                      {currentCategories.map((category, i) => (
                        <option key={i} value={category.Guid}>
                          {category.Title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Is Active</h3>
                    <Form.Check
                      disabled={!edit}
                      defaultChecked={subcategoryCopy.Active}
                      onChange={(event) => updateActive(event.target.checked)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <button
                    type="button"
                    className={`custom-card-button`}
                    onClick={(e) => changeEdit(e)}
                  >
                    {edit ? "Save" : "Edit"}
                  </button>
                </Col>
                <Col md={4}>
                  <button
                    disabled={!edit}
                    type="button"
                    className={`custom-card-button`}
                    onClick={(e) => cancelEdit(e)}
                  >
                    Cancel
                  </button>
                </Col>
                <Col md={4}>
                  <button
                    type="button"
                    className={`custom-card-button custom-card-button-variant-delete`}
                    onClick={(e) => deleteSubCategoryClicked(e)}
                  >
                    Delete
                  </button>
                </Col>
              </Row>
              <hr />
              <br />
              <Row>
                <Col md={10}>
                  <h3 className="content-item-title">Lessons</h3>
                </Col>
                <Col md={2}>
                  <button
                    type="button"
                    disabled={!edit}
                    className={`custom-card-button m-auto text-center`}
                    onClick={(e) => addLessonClicked()}
                  >
                    {addLesson ? "Hide" : "Add Lesson"}
                  </button>
                </Col>
              </Row>
              <br />
              {addLesson ? (
                <div>
                  {" "}
                  <h3 className="content-item-title">Add Lesson</h3>
                  <br />
                  <SearchBar
                    placeholder="Search For A Lesson"
                    setSearchValue={setSearchValue}
                  />
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Year</th>
                        <th>Add</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getFilteredLessons(allLessons).map((lesson, i) => {
                        return (
                          <tr key={i}>
                            <th>{lesson.Title}</th>
                            <th>{lesson.Year}</th>
                            <th>
                              {subcategoryCopy?.Lessons?.includes(
                                lesson.Guid
                              ) ? (
                                <div>Added</div>
                              ) : (
                                <button
                                  disabled={!edit}
                                  type="button"
                                  className={` custom-card-button`}
                                  onClick={(e) => addLessonToSub(lesson)}
                                >
                                  Add
                                </button>
                              )}
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <br />
                  <hr />
                  <br />
                </div>
              ) : (
                <div></div>
              )}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Year</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {lessonsCopy.map((lesson, i) => {
                    return (
                      <tr key={i}>
                        <th onClick={() => goToLesson(lesson.Guid)}>
                          {lesson.Title}
                        </th>
                        <th onClick={() => goToLesson(lesson.Guid)}>
                          {lesson.Year}
                        </th>
                        <th>
                          <button
                            disabled={!edit}
                            type="button"
                            className={` custom-card-button custom-card-button-variant-delete`}
                            onClick={(e) => removeLesson(lesson.Guid)}
                          >
                            Remove
                          </button>
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Form>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default SubCategoryDetailsPage;
