import { useEffect } from "react";
import { useState } from "react";
import { Card, Row, Col, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

function ScheduleItem(props) {
  const [imageUrl, setImageUrl] = useState("");
  const [borderColour, setBorderColour] = useState("");
  useEffect(() => {
    setImageUrl(`/icons/${props.Item.ImageUri}.png`);

    switch (props.Item.Colour) {
      case "TextPrimary":
        setBorderColour("#404040");
        break;
      case "Green":
        setBorderColour("#159A27");
        break;
      case "Blue":
        setBorderColour("#36A9FB");
        break;
      case "Purple":
        setBorderColour("#AC00BF");
        break;
      case "Yellow":
        setBorderColour("#B99417");
        break;
      case "Red":
        setBorderColour("#D40101");
        break;
      case "ActiveColour":
        setBorderColour("#DA6CFB");
        break;
      case "CalmingColour":
        setBorderColour("#1FB3FF");
        break;
      case "TikTokColour":
        setBorderColour("#982D87");
        break;
      case "ChristmasColour":
        setBorderColour("#DA6CFB");
        break;
      default:
        setBorderColour(props.Item.Colour);
      // code block
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Item]);

  return (
    <Link
      className="text-link"
      to={
        props.Item.Type === "Movement Break"
          ? `/movement-breaks/movement-break/${props.Item.ContentId}`
          : `/lessons/lesson/${props.Item.ContentId}`
      }
    >
      <Card style={{ margin: 10, borderColor: borderColour }} className="grow">
        <Stack direction="horizontal" gap={1} className="my-auto">
          <img alt="" src={imageUrl} width="50" height="50" className="m-1" />
          <div className="fs-6 ms-auto me-3">
            <b>{props.Item.Title}</b>
          </div>
        </Stack>
      </Card>
    </Link>
  );
}

export default ScheduleItem;
