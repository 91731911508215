import { useEffect } from "react";
import { useState } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { uploadFileToStorage } from "../firebase";
import WorksheetBox from "./WorksheetBox";

function AddWorksheetToStructuredLessonGroupBox(props) {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  const [worksheets, setWorksheets] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState({});
  const [worksheetFiles, setWorksheetFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState({});

  const fileUpload = (e) => {
    if (e.target.files) {
      var file = e.target.files[0];
      var worksheet = {
        title: file.name,
      };
      setWorksheets([...worksheets, worksheet]);
      setWorksheetFiles([...worksheetFiles, file]);
    }
  };

  const uploadWorksheets = async () => {
    var allSheets = await Promise.all(
      worksheetFiles.map(async (w, i) => {
        console.log(w);
        let url = await uploadFileToStorage("structured-lesson-worksheets", w);
        return {
          title: w.name,
          url: url,
        };
      })
    );

    let segment = {
      title: "Worksheets",
      strand: "Worksheets",
      worksheets: allSheets,
    };

    props.uploadWorksheets(segment);
    setWorksheets([]);
    setWorksheetFiles([]);
  };

  return (
    <Container>
      <h1 className="pageTitle title-margin mb-3" id={"lesson-" + props.order}>
        Worksheets
      </h1>
      <div className="structured-content-text-box">
        {worksheets.map((worksheet, i) => {
          return (
            <div key={i}>
              <WorksheetBox worksheet={worksheet} />
              <br />
            </div>
          );
        })}

        <Form.Group className="" controlId="fileUpload">
          <Form.Label>File Upload</Form.Label>
          <Form.Control
            type="file"
            placeholder="Upload File"
            onChange={(e) => fileUpload(e)}
          />
        </Form.Group>
        <div>
          <Button
            variant="primary"
            className="mainButton mt-3"
            onClick={() => uploadWorksheets()}
          >
            SAVE Worksheets
          </Button>
        </div>
      </div>
      <br />
    </Container>
  );
}

export default AddWorksheetToStructuredLessonGroupBox;
