export const getEmptyAccount = () => {
  let account = {
    AccessID: "",
    ContactNumber: "",
    CreationDate: new Date(),
    Email: "",
    FirstName: "",
    Guid: "",
    Role: "",
    SchoolID: "",
    Stage: "new",
    Surname: "",
    SubscribedToMarketing: false,
    ClassroomName: "",
    Year: 0,
  };

  return account;
};
