import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Button, Card, Col, Container, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import HomeworkBox from "../components/HomeworkBox";
import ScheduleItem from "../components/ScheduleItem";
import UnitLessonBox from "../components/UnitLessonBox";
import {
  addCodeToUser,
  createCodes,
  getAdminDetails,
  getChristmasGames,
  getDateSchedule,
  getDaySchedule,
  getRandomLessonByYear,
  getRandomMovementBreakByYear,
  getSubscriptionDetails,
  GetTeacherHomework,
  removeCodes,
  verifyInviteCode,
} from "../firebase";
import { UserContext } from "../providers/UserProvider";

const Dashboard = () => {
  const [scheduleItems, setScheduleItems] = useState({});
  const [scheduledHomework, setScheduledHomework] = useState([]);
  const [randomLesson, setRandomLesson] = useState([]);
  const [codesLeft, setCodesLeft] = useState(0);
  const [randomMovementBreak, setRandomMovementBreak] = useState([]);
  const [historyHomework, setHistoryHomework] = useState([]);
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  const [adminDetails, setAdminDetails] = useState({});
  const [subscribed, setSubscribed] = useState(false);
  const navigate = useNavigate();
  const { data2 } = useContext(UserContext);
  useEffect(() => {
    document.title = "Daly Exercise+ | Dashboard";
    if ("Guid" in data2) {
      

      // GetTeacherHomework(data2.classId).then((val) => {
      //   setScheduledHomework(val.scheduled);
      //   setHistoryHomework(val.history);
      // });
      getSchedule(new Date());
      // getChristmasGames(data.Year).then((res) => {
      //   setRandomLesson(res);
      // });

      // getRandomMovementBreakByYear(data.Year).then((res) =>
      //   setRandomMovementBreak(res)
      // );

      
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2]);
  const getSchedule = (e) => {
    var dateString = `${e.getFullYear()}-${e.getMonth() + 1}-${e.getDate()}`;

    getDateSchedule(data2.Guid, dateString).then((result) => {
      setScheduleItems(result);
    });
  };

  const goToHomework = (homework) => {
    navigate(`/homework/assigned/${homework.Guid}`);
  };

  const viewLongTermPlanning = () => {
    let lessonPlan = "";
    if (data2.Year === 0) {
      lessonPlan =
        process.env.PUBLIC_URL +
        "long-term-plans/Long Term Plan Infant Class.pdf";
    }
    if (data2.Year === 1) {
      lessonPlan = "long-term-plans/Long Term Plan 1st and 2nd.pdf";
    }
    if (data2.Year === 2) {
      lessonPlan =
        process.env.PUBLIC_URL +
        "long-term-plans/Long Term Plan 3rd and 4th.pdf";
    }
    if (data2.Year === 3) {
      lessonPlan =
        process.env.PUBLIC_URL + "long-term-plans/Long Term Plan 5th & 6th.pdf";
    }

    const link = document.createElement("a");
    link.href = lessonPlan;
    link.download = "Lesson Plan.pdf";
    document.body.appendChild(link);
    link.click();
  };

  const iosLink = () => {
    let url =
      "https://apps.apple.com/ie/app/daly-exercise/id1586325510?platform=iphone";
    window.open(url, "_blank").focus();
  };

  const instagramLink = () => {
    let url = "https://www.instagram.com/dalyexercise/";
    window.open(url, "_blank").focus();
  };

  const tiktokLink = () => {
    let url = "https://www.tiktok.com/@dalyexercise";
    window.open(url, "_blank").focus();
  };

  const androidLink = () => {
    let url =
      "https://play.google.com/store/apps/details?id=com.dalyexercise.dalyexerciseplus";
    window.open(url, "_blank").focus();
  };

  

  const manageCodes = () => {
    if (subscribed) {
      navigate("/manage-school/school");
    } else {
      navigate("/subscription");
    }
  };

  const goToEditNews = () => {
    navigate("/news/edit");
  };
  return (
    <Container fluid>
      <div className="dashboard-body full-page-length">
        <Container fluid>
          <Stack className="dashboard-header-2" direction="horizontal">
            <h3>
              <b>Dashboard</b>
            </h3>
            <div className="dashboard-date ms-auto text-end h3">
              <b>{new Date().toLocaleDateString(undefined, options)}</b>
            </div>
          </Stack>
          <Col>
            <Row>
              <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                <Link to={"/structured-lessons"} className={"text-link"}>
                  <Card
                    style={{ height: "200px" }}
                    className="mb-3 p-3 plan-card-highlight grow"
                  >
                    <Stack direction="horizontal" className="my-auto">
                      <Stack className="my-auto text-center">
                        <div className="h2">Structured</div>
                        <div className="h2">Lessons!</div>
                      </Stack>
                      <img
                        src="/icons/icon_games.png"
                        style={{ height: "7rem", width: "7rem" }}
                      ></img>
                    </Stack>
                  </Card>
                </Link>
              </Col>
              <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                <Card
                  style={{ height: "200px" }}
                  onClick={() => manageCodes()}
                  className="mb-3 p-3 welcome-back-card grow"
                >
                  <Stack direction="horizontal" className="my-auto">
                    {subscribed ? (
                      <Stack className="my-auto text-center">
                        <div className="headingColour h2">My School</div>
                      </Stack>
                    ) : (
                      <Stack className="my-auto text-center">
                        <div className="headingColour h2">Subscribe Now!</div>
                      </Stack>
                    )}

                    <img
                      src="/icons/high_res_logo_icon.png"
                      style={{ width: "35%" }}
                    ></img>
                  </Stack>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                <Card
                  style={{ height: "200px" }}
                  className="mb-3 p-3 grow instagram-store-card"
                  onClick={() => instagramLink()}
                >
                  <Stack direction="horizontal" className="my-auto">
                    <Stack className="my-auto text-center p-auto">
                      <div className=" h2">Checkout our</div>
                      <div className=" h2">Instagram!</div>
                    </Stack>

                    <img
                      src="/icons/icon_instagram.png"
                      style={{ width: "40%" }}
                    ></img>
                  </Stack>
                </Card>
              </Col>
              <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                <Card
                  style={{ height: "200px" }}
                  className="mb-3 p-3 grow tiktok-store-card"
                  onClick={() => tiktokLink()}
                >
                  <Stack direction="horizontal" className="my-auto">
                    <Stack className="my-auto text-center p-auto">
                      <div className=" h2">Checkout our</div>
                      <div className=" h2">TikTok!</div>
                    </Stack>

                    <img
                      src="/icons/icon_tiktok.png"
                      style={{ width: "40%" }}
                    ></img>
                  </Stack>
                </Card>
              </Col>
              {/* <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                <Card
                  style={{ height: "200px" }}
                  className="mb-3 p-3 grow android-store-card"
                  onClick={() => androidLink()}
                >
                  <Row className="my-auto">
                    <Col>
                      <Stack className="my-auto text-center">
                        <div className=" h3">Download our</div>
                        <div className=" h3">Android App</div>
                      </Stack>
                    </Col>
                    <Col className="my-auto mx-auto fill">
                      <img
                        src="/qr_codes/DE+Android_link.png"
                        style={{ width: "100%" }}
                      ></img>
                    </Col>
                  </Row>
                </Card>
              </Col> */}
            </Row>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Card style={{ minHeight: "450px" }} className="mb-3">
                  <Stack>
                    <Stack direction="horizontal" className="p-3">
                      <h2 className="pageTitle m-2">
                        <b>Schedule</b>
                      </h2>
                      <div className="ms-auto h5 m-2 my-auto text-end">
                        <b>What's on today?</b>
                      </div>
                    </Stack>
                    <hr style={{ marginTop: 0 }} className="light-line" />
                    <Row>
                      {Object.values(scheduleItems).length > 0 ? (
                        <Col
                          className="px-3 col-border-right"
                          lg={6}
                          md={12}
                          sm={12}
                        >
                          {Object.values(scheduleItems).map((item, i) => {
                            if (i < 3)
                              return (
                                <div key={i}>
                                  <ScheduleItem Item={item} />
                                </div>
                              );
                          })}
                        </Col>
                      ) : (
                        <h4 className="text-center my-3">
                          <b>You've nothing scheduled today</b>
                        </h4>
                      )}

                      {Object.values(scheduleItems).length > 0 ? (
                        <Col className="px-3" lg={6} md={12} sm={12}>
                          {Object.values(scheduleItems).map((item, i) => {
                            if (i >= 3 && i < 6) {
                              return (
                                <div key={i}>
                                  <ScheduleItem Item={item} />
                                </div>
                              );
                            }
                          })}
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>

                    <div className="p-3 mt-auto">
                      <Link to={`/schedule`} className="text-link d-grid">
                        <Button variant="primary" className="mainButton">
                          <b>My Schedule</b>
                        </Button>
                      </Link>
                    </div>
                  </Stack>
                </Card>
              </Col>
              {/* <Col xl={3} lg={12} md={12} sm={12} xs={12}>
                <Card
                  style={{ minHeight: "450px" }}
                  className="mb-3 welcome-back-card"
                >
                  <Stack>
                    <Stack direction="horizontal" className="p-3">
                      <div className="headingColour m-2 h2">
                        <b>Daly News</b>
                      </div>
                      <div className="ms-auto h5 m-2 my-auto text-end">
                        <b>What's new?</b>
                      </div>
                    </Stack>
                    <hr style={{ marginTop: 0 }} className="light-line" />
                    <div className="px-3">
                      <Stack direction="horizontal">
                        <img
                          src="/icons/icon_christmas.png"
                          style={{ height: "2.5rem", width: "2.5rem" }}
                        ></img>
                        <div className="m-2 h5">
                          The Christmas Lessons and Movement Breaks will be back
                          next year!
                        </div>
                      </Stack>
                      <hr />
                    </div>
                    <div className="px-3">
                      <Stack direction="horizontal">
                        <img
                          src="/avatars/avatar27.png"
                          style={{
                            height: "5rem",
                            width: "5rem",
                            margin: "-10px",
                          }}
                        ></img>
                        <div className="m-2 h5">
                          The Halloween Lessons will be back next year!
                        </div>
                      </Stack>
                      <hr />
                    </div>
                    {data.Guid === "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2" ? (
                      <Button
                        variant="primary"
                        className="mainButton m-3"
                        onClick={() => goToEditNews()}
                      >
                        Edit News
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Card>
              </Col> */}

              {/* <Col xl={3} lg={12} md={12} sm={12} xs={12}>
                <Card
                  style={{ minHeight: "450px" }}
                  className="mb-3 welcome-back-card"
                >
                  <Stack>
                    <Stack direction="horizontal" className="p-3">
                      <h2 className="pageTitle m-2">Quizzes</h2> */}
              {/* <div className="ms-auto h5 m-2 my-auto text-end">
                        <b>New ones Weekly!</b>
                      </div> */}
              {/* </Stack>
                    <hr style={{ marginTop: 0 }} className="light-line" />
                    <img
                      src="/icons/icon_graded.png"
                      style={{ width: "15rem", margin: "auto" }}
                    ></img>
                    <a
                      className="text-link d-grid"
                      href="https://firebasestorage.googleapis.com/v0/b/dalyfitness-3abef.appspot.com/o/quizzes%2FQuiz.pdf?alt=media"
                      target={"_blank"}
                    >
                      <Button variant="primary" className="mainButton m-3">
                        <b>Download</b>
                      </Button>
                    </a>
                  </Stack>
                </Card>
              </Col> */}
              {/* <Col xl={6} sm={12}>
                <Card style={{ minHeight: "450px" }} className="mb-3">
                  <Stack>
                    <Stack direction="horizontal" className="p-3">
                      <h2 className="pageTitle m-2">
                        <b>Workouts</b>
                      </h2>
                      <div className="ms-auto h5 m-2 my-auto text-end">
                        <b>What's due?</b>
                      </div>
                    </Stack>

                    <hr style={{ marginTop: 0 }} className="light-line" />

                    <div className="px-2 pt-2">
                      {scheduledHomework.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              display: i < 2 ? "initial" : "none",
                            }}
                          >
                            <HomeworkBox details={item} link={goToHomework} />
                          </div>
                        );
                      })}
                    </div>
                    {scheduledHomework.length === 0 ? (
                      <h4 className="text-center my-3">
                        <b>You've no upcoming workouts</b>
                      </h4>
                    ) : (
                      ""
                    )}
                    <div className="p-3 mt-auto">
                      <Link to={`/homework`} className="text-link d-grid">
                        <Button variant="primary" className="mainButton">
                          <b>My Workouts</b>
                        </Button>
                      </Link>
                    </div>
                  </Stack>
                </Card>
              </Col> */}
              {/* <Col md={6} sm={12}>
                <Card style={{ minHeight: "450px" }} className="mb-3">
                  <Stack>
                    <Stack direction="horizontal" className="p-3">
                      <h2 className="christmas-colour m-2">
                        <b>Christmas Lessons</b>
                      </h2>
                      <img
                        className="ms-auto mx-3"
                        src="/icons/icon_christmas.png"
                        style={{ height: "3rem", width: "3rem" }}
                      ></img>
                    </Stack>

                    <hr style={{ marginTop: 0 }} className="light-line" />

                    <Row className="">
                      {randomLesson.map((lesson, i) => {
                        return (
                          <Col md={6} key={i} xs={12}>
                            <UnitLessonBox Lesson={lesson} />
                          </Col>
                        );
                      })}
                    </Row>

                    <div className="p-3 pt-0 mt-auto">
                      <Link to={`/lessons`} className="text-link d-grid">
                        <Button variant="primary" className="">
                          <b>View More</b>
                        </Button>
                      </Link>
                    </div>
                  </Stack>
                </Card>
                    </Col>*/}
            </Row>
          </Col>
        </Container>
      </div>
    </Container>
  );
};

export default Dashboard;
