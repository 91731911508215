import { useEffect } from "react";

const ListItem = (props) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  return (
    <div className={`list-item`}>
      <div className={`list-item-title`}>{props.title}</div>
      <div className={`list-item-text`}>{props.text}</div>
      <button
        disabled={props.buttonDisabled}
        className={`list-item-button`}
        onClick={() => props.callToAction()}
      >
        {props.buttonText}
      </button>
    </div>
  );
};

export default ListItem;
