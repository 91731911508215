function getRandomElement(array) {
  const randomIndex = Math.floor(Math.random() * array.length);
  const randomElement = array[randomIndex];
  return randomElement;
}

export function getRandomColor() {
  const colors = [
    "activeColour",
    "calmingColour",
    "darkBlue",
    "yellow",
    "green",
    "red",
  ];

  return getRandomElement(colors);
}

export async function getVideoThumbnail(embedURl) {
    let data = await fetch(`https://vimeo.com/api/oembed.json?url=${embedURl}&width=640`)
    let json = await data.json()
    // console.log(json)
    return json.thumbnail_url
}
