import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getVideoThumbnail } from "../helper-functions/UtilFunctions";

const UnitLessonBox = (props) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("https://placehold.co/600x340");
  useEffect(() => {
    setThumbnailUrl("https://placehold.co/600x340?text=" + props.Lesson.Title)
    getVideoThumbnail(props.Lesson.VimeoEmbedLink).then(res => {
      setThumbnailUrl(res)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  // const imageUrl = props.MB
  //   ? `https://img.youtube.com/vi${props.Lesson.Url.substring(
  //       props.Lesson.Url.lastIndexOf("/")
  //     )}/mqdefault.webp`
  //   : `https://img.youtube.com/vi${props.Lesson.Url.substring(
  //       props.Lesson.Url.lastIndexOf("/")
  //     )}/maxresdefault.jpg`;
  // const imageUrl = await;
  const navigate = useNavigate();
  const boxClicked = () => {
    if (props.notSubscribed) {
      navigate("/manage-school/subscription");
      return;
    }
    if ("customClick" in props) {
      props.customClick(props.Lesson);
      return;
    } else {
      const url = props.notSubscribed
        ? `/manage-school/subscription`
        : props.MB
        ? `/movement-breaks/movement-break/${props.Lesson.Guid}`
        : `/lessons/lesson/${props.Lesson.Guid}`;
      navigate(url);
    }
  };
  return (
    <Card
      onClick={() => boxClicked()}
      style={{ margin: 10 }}
      className={"grow " + (props.notSubscribed ? "disabled" : "")}
    >
      <Card.Img
        variant="top"
        src={thumbnailUrl}
        style={{ maxHeight: "260px", maxWidth: "700px", objectFit: "fit" }}
      />
      <Card.Title className="text-center text-truncate px-3">
        {props.notSubscribed ? "Subscribe to view more!" : props.Lesson.Title}
      </Card.Title>
    </Card>
  );
};

export default UnitLessonBox;
