import { Button, Container, Form, Stack } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import { getNews } from "../firebase";
import { UserContext } from "../providers/UserProvider";
import NewsBox from "../components/NewsBox";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";

function EditNewsPage() {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const {data} = useContext(UserContext);
  useEffect(() => {
    document.title = "Daly Exercise+ | Edit News";
    getNews().then((res) => {
      setNewsList(res);
      setLoading(false);
    });
  }, [data]);

  

  const iconList = [
    "high_res_logo_icon.png",
    "icon_100text.png",
    "icon_account.png",
    "icon_alerting.png",
    "icon_brainbreak_tab.png",
    "icon_calming.png",
    "icon_christmas.png",
    "icon_classroom_tab.png",
    "icon_dance.png",
    "icon_delete.png",
    "icon_games.png",
    "icon_graded.png",
    "icon_group.png",
    "icon_gymnastics.png",
    "icon_heart_beat.png",
    "icon_heart.png",
    "icon_help.png",
    "icon_home.png",
    "icon_homework_tab.png",
    "icon_lessons_tab.png",
    "icon_nc_games.png",
    "icon_notifications.png",
    "icon_pencil.png",
    "icon_random.png",
    "icon_run.png",
    "icon_schedule_tab.png",
    "icon_thumbsup.png",
    "icon_tiktok.png",
    "icon_timer.png",
    "icon_tool_singular.png",
    "icon_tool.png",
    "icon_wave.png",
    "icon_wowtext.png",
    "low_res_logo_icon.png",
    "play_store_logo.png",
  ];

  const uploadNews = () => {
    let id = uuidv4().toString();
    let news = {
      content: content,
      imageName: image,
      id: id,
    };
    console.log(news);
  };
  return (
    <Container className="full-page-length">
      <h1 className="pageTitle title-margin">Edit News</h1>

      <hr />
      {newsList.map((news, i) => {
        <NewsBox news={news} key={i} isAdmin={true} />;
      })}
      <hr />
      <h1 className="pageTitle title-margin">Add News</h1>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>News Content</Form.Label>
          <Form.Control
            placeholder="News Content"
            onChange={(event) => setContent(event.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>News Icon</Form.Label>
          <Select
            onChange={(value) => setImage(value.value)}
            options={iconList.map((icon) => {
              return {
                value: icon,
                label: (
                  <div>
                    <img
                      src={`/icons/${icon}`}
                      style={{
                        height: "5rem",
                        width: "5rem",
                      }}
                    ></img>
                  </div>
                ),
              };
            })}
          ></Select>
        </Form.Group>
        <Stack>
          <Button
            variant="primary"
            className="mainButton"
            onClick={() => uploadNews()}
          >
            Add News
          </Button>
        </Stack>
      </Form>
    </Container>
  );
}

export default EditNewsPage;
