import { useContext, useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import SearchBar from "../../components/SearchBar";
import UnitLessonBox from "../../components/UnitLessonBox";
import { UserContext } from "../../providers/UserProvider";
import { getAllLessonsByYear } from "../../functions/lessonFunctions";

function SearchPage() {
  const [lessons, setLessons] = useState({});
  const [subscribed, setSubscribed] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const {hasSubscription, data2 } = useContext(UserContext);
  useEffect(() => {
    document.title = "Daly Exercise+ | Categories";
    setSubscribed(hasSubscription);
    if ("Year" in data2){
        getAllContent(data2.Year)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2]);

  const getAllContent = async (year) => {
    setLoading(true);
    
    let content = await getAllLessonsByYear(year);
    setLessons(content);
    setLoading(false);
  };

  const getFilteredLessons = (lessons) => {
    if (searchTitle === "") {
      return [];
    }

    let filtered = lessons?.filter((lesson) =>
      lesson.Title?.toLowerCase().includes(searchTitle.toLowerCase())
    );

    if (filtered.length === 0) {
      filtered.push({
        Title: "empty",
      });
    }

    return filtered;
  };

  const goToLesson = (lesson) => {
    navigate(`/lessons/search/${lesson.Guid}`);
  };

  return (
    <Container fluid className="mx-0 px-0">
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Video Search</h1>
          <br />
          <SearchBar
            lessons={[]}
            placeholder="Search for a Video!"
            setSearchValue={setSearchTitle}
          />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <Accordion defaultActiveKey={0} alwaysOpen>
              <Accordion.Item eventKey={0}>
                <Accordion.Header bsPrefix="accordion-title">
                  <h3 className="pageTitle title-margin">{searchTitle === "" ? "Content" : `${searchTitle} : (${getFilteredLessons(lessons).length})` }</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {getFilteredLessons(lessons)
                      .sort((a, b) => a.Title.localeCompare(b.Title))
                      .sort(
                        (a, b) =>
                          Number("Sparkle" in b && b.Sparkle) -
                          Number("Sparkle" in a && a.Sparkle)
                      )
                      .map((lesson, j) => {
                        if (lesson.Title === "empty") {
                          return (
                            <Col xs={12} key={j}>
                              <h3 className="pageTitle">
                                No Videos With This Title
                              </h3>
                            </Col>
                          );
                        }
                        return (
                          <Col sm={4} key={j}>
                            <UnitLessonBox
                              customClick={goToLesson}
                              Lesson={lesson}
                              notSubscribed={j !== 0 && !subscribed}
                            ></UnitLessonBox>
                          </Col>
                        );
                      })}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          <br />
        </Container>
      </div>
    </Container>
  );
}

export default SearchPage;
