import { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Stack, Tab, Table, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";
import StudentBox from "../../components/StudentBox";
import {
  getClassroomStudents,
  getFMSAreas,
  getFMSCategories,
} from "../../functions/classroomFunctions";
import { UserContext } from "../../providers/UserProvider";
import FMSCategoryBox from "../../components/new-components/FMSCategoryBox";

function ClassroomPage({ tab }) {
  const [className, setClassName] = useState("");
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [fmsCategories, setFmsCategories] = useState([]);
  const { data2 } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Classroom";

    if ("Guid" in data2) {
      initializeClassroom(data2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2]);

  const initializeClassroom = async (data) => {
    setLoading(true);
    setClassName(data.ClassroomName);
    let students = await getClassroomStudents(data.Guid);
    setStudents(students);

    let categories = await getFMSCategories();
    setFmsCategories(categories);
    setLoading(false);
  };

  const addStudentClicked = () => {
    navigate("/classroom/add");
  };

  const goToTab = (tab) => {
    navigate("/classroom/" + tab);
  };

  const checkIfStudentIsFinished = (student, fmsID, fmsAreas) => {
    let finished = false;
    if (
      student["FMSScores"] === undefined ||
      !(fmsID in student["FMSScores"])
    ) {
      finished = false;
    } else {
      let count = 0;
      Object.values(student["FMSScores"][fmsID]).forEach((area) => {
        if ("Score" in area && area.Score !== 0) {
          count += 1;
        }
      });

      if (count === fmsAreas.length) {
        finished = true;
      }
    }

    return finished;
  };

  const checkIfFMSIsFinished = async (students, fmsID) => {
    let total = 0;
    let areas = await getFMSAreas(fmsID);
    students.forEach((student) => {
      if (checkIfStudentIsFinished(student, fmsID, areas)) {
        total += 1;
      }
    });

    return total === students.length;
  };

  // const fmsCategories = ["Arms", "Legs", "Balance"];
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">My Classroom</h1>
          <br />
          <Tabs
            activeKey={tab}
            className="manage-school-tabs"
            fill
            variant="pills"
            onSelect={(key) => goToTab(key)}
          >
            <Tab eventKey="students" title="Students">
              <div className="white-box">
                <Stack direction="horizontal">
                  <h3 className="title-margin page-title">Students</h3>
                  <button
                    className="custom-button-big custom-button-variant-good ms-auto"
                    onClick={addStudentClicked}
                  >
                    Add Students
                  </button>
                </Stack>
                <br />
                {loading ? (
                  <div className="custom-loader" />
                ) : (
                  <Row>
                    {students.map((student, i) => {
                      return (
                        <Col xs={6} sm={4} md={3} xl={2} xxl={2} key={i}>
                          <StudentBox Student={student}></StudentBox>
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </div>
            </Tab>
            <Tab eventKey="fms" title="FMS">
              <div className="white-box">
                <Stack direction="horizontal">
                  <h3 className="title-margin page-title">
                    Fundamental Movement Skills
                  </h3>
                </Stack>
                <br />
                <Row>
                  {fmsCategories.map((cat, i) => {
                    return (
                      <Col md={4} key={i}>
                        <FMSCategoryBox
                          title={cat.Title}
                          id={cat.Guid}
                          finishedPromise={checkIfFMSIsFinished(students, cat.Guid)}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Tab>
          </Tabs>
        </Container>
      </div>
    </Container>
  );
}

export default ClassroomPage;
