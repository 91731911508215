import { httpsCallable } from "firebase/functions";
import { getAdminDetails, getSubscriptionDetails, functions } from "../firebase";

export async function getOldUserDetails(id) {
  try {
    let oldAdminDetails = await getAdminDetails(id);
    return oldAdminDetails;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function getOldSubscription(id) {
  try {
    let oldSubscriptionDetails = await getSubscriptionDetails(id);
    return oldSubscriptionDetails;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function migrateSchool(adminID, schoolID) {
  try {
    const addTeacherToSchoolFunction = httpsCallable(
      functions,
      "moveOldSubscriptionToNewSystem"
    );
    console.log(adminID, schoolID);
    let response = await addTeacherToSchoolFunction({
      AdminID: adminID,
      SchoolID: schoolID,
    });
    console.log(response.data)
    return response.data.Status === "Success";
  } catch (e) {
    console.log(e);
    return false;
  }
}
