import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { sendDataRequestEmail } from "../firebase";

const DataSafetyPage = () => {
  const {data} = useContext(UserContext);
  useEffect(() => {
    document.title = "Daly Exercise+ | Data Safety";
  }, [data]);

  

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [requestType, setRequestType] = useState("dataRequest");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [requestSent, setRequestSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Add your logic to handle form submission (e.g., send request to server).
    let res = await sendDataRequestEmail(data.Guid, data.UserName, email, requestType, additionalInfo)
    if (res){
        setRequestSent(true)
    } else {
        alert("Something went wrong, please try again.")
    }
  };
  return (
    <Container className="full-page-length">
      <h1 className="pageTitle  title-margin">Data Request and Deletion</h1>
      <hr />

      <h5>
        The safety of your data is extremely important to us at Daly Exercise+.
        If you wish to view or delete your data please fill out this form below
        and we will get back to you as soon as we can.
      </h5>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email" className="mt-3">
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="requestType" className="mt-3">
          <Form.Label>Request Type:</Form.Label>
          <Form.Control
            as="select"
            value={requestType}
            onChange={(e) => setRequestType(e.target.value)}
            required
          >
            <option value="dataRequest">Data Request</option>
            <option value="dataDeletion">Data Deletion</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="additionalInfo" className="mt-3">
          <Form.Label>Additional Information (if any):</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          />
        </Form.Group>
        <div className="mt-3">
          {requestSent ? (
            <h4>Thank you, we will contact you shortly.</h4>
          ) : (
            <Button variant="primary" className="mainButton mt-3" type="submit">
              Submit Request
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
};

export default DataSafetyPage;
