import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { auth } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";

function RegisterPage() {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Daly Exercise+ | Register";
  }, [])

  const handleValidation = async (event) => {
    let formIsValid = true;

    if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      formIsValid = false;
      setemailError("Email Not Valid");
      return false;
    } else {
      setemailError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      if (password !== repeatPassword) {
        setpasswordError("Passwords do not match");
      } else if (password === "" || password === null) {
        setpasswordError("Please input a password");
      } else {
        setpasswordError("");
        createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            navigate("/subscription");
          })
          .catch((error) => {
            var errorMessage = error.message;
            setemailError(errorMessage);
          });
      }
    }
  };

  return (
    <Container className="register-form-box full-page-length">
      <h1 className="pageTitle title-margin">Teacher Sign Up</h1>
      <h4 className="text-center">
       Create an account and start a 6 week free trial today!
      </h4>
      <hr />
      <Form onSubmit={registerSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            aria-describedby="emailHelpBlock"
            onChange={(event) => setEmail(event.target.value)}
          />
          <Form.Text id="emailHelpBlock" muted>
            {emailError}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Create a Password</Form.Label>
          <Form.Control
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            aria-describedby="passwordHelpBlock"
          />
          <Form.Text id="passwordHelpBlock" muted>
            {passwordError}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRepeatPassword">
          <Form.Label>Repeat Password</Form.Label>
          <Form.Control
            type="password"
            onChange={(event) => setRepeatPassword(event.target.value)}
          />
        </Form.Group>
        <div className="d-grid">
          <Button variant="primary" type="submit" className="mainButton">
            Sign Up
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default RegisterPage;
