import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import CategoryBox from "../components/CategoryBox";
import StructuredLessonItem from "../components/StructuredLessonItem";
import { getEventStrandsByYear, getStrandsByYear } from "../firebase";
import { UserContext } from "../providers/UserProvider";

function StructuredLessonListPage() {
  const [strandsText, setStrandsText] = useState("");
  const [strands, setStrands] = useState([]);
  const { data, setYear } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Structured Lessons";
  }, [data]);

  const goToLesson = (id, year, updatedLessons = false) => {
    setYear(year);
    if (updatedLessons) {
      navigate(`/structured-lessons/${id}`);
      return;
    }
    navigate(id);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        {/* <Container>
          <h1 className="pageTitle title-margin">Sports Day</h1>
          <br />
          <Row className="structured-lesson-list-container">
            <Col md={4}>
              <StructuredLessonItem
                title="Junior & Senior"
                content="Junior & Senior Sports Day"
                click={() => goToLesson("Bbf0Zc7useZEpi30MW53", 0, true)}
                color="green"
              />
            </Col>
            <Col md={4}>
              <StructuredLessonItem
                title="1st & 2nd"
                content="1st & 2nd Sports Day"
                click={() => goToLesson("YwhGSkevvi82gFfiLvpD", 1, true)}
                color="red"
              />
            </Col>
            <Col md={4}>
              <StructuredLessonItem
                title="3rd & 4th"
                content="3rd & 4th Sports Day"
                click={() => goToLesson("CKF13eVUjDIE0npmFPht", 2, true)}
                color="yellow"
              />
            </Col>
            <Col md={4}>
              <StructuredLessonItem
                title="5th & 6th"
                content="5th & 6th Sports Day"
                click={() => goToLesson("Nyrs7VTEiaENASKcp3la", 3, true)}
                color="purple"
              />
            </Col>
          </Row>
        </Container>

        <br />
        <hr />
        <br /> */}
        <Container>
          <h1 className="pageTitle title-margin">Structured Lessons for 2024/2025</h1>
          <br />
          <Row className="structured-lesson-list-container ">
            <Col md={4}>
              <StructuredLessonItem
                title="Junior & Senior"
                content="Junior & Senior Curriculum"
                click={() => goToLesson("ScyF4p5IWmW0W1nN3jdu", 0, true)}
                color="green"
              />
            </Col>
            <Col md={4}>
              <StructuredLessonItem
                title="1st & 2nd"
                content="1st & 2nd Curriculum"
                click={() => goToLesson("ltmdjbUAhX827TpQZrbq", 1, true)}
                color="red"
              />
            </Col>
            <Col md={4}>
              <StructuredLessonItem
                title="3rd & 4th"
                content="3rd & 4th Curriculum"
                click={() => goToLesson("33w5lRGDmjeDbiZhVvuI", 2, true)}
                color="yellow"
              />
            </Col>
            <Col md={4}>
              <StructuredLessonItem
                title="5th & 6th"
                content="5th & 6th Curriculum"
                click={() => goToLesson("Lr9JFFnnTg8KY5forR28", 3, true)}
                color="purple"
              />
            </Col>
          </Row>
        </Container>
        {/* <br />
        <hr />
        <br />
        <Container>
          <h1 className="pageTitle title-margin">Structured Lessons</h1>
          <br />
<<<<<<< Updated upstream
          <h3 className="text-green text-center highlight-grow">The new LTPs for 2024 will be released on Tuesday September 10th!</h3>
=======
          <h3 className="text-green text-center highlight-grow">The new LTPs for 2024 will be released on Friday 13th September!</h3>
>>>>>>> Stashed changes
          <br />
          <Row className="structured-lesson-list-container ">
            <Col md={4}>
              <StructuredLessonItem
                title="Junior & Senior"
                content="Junior & Senior Curriculum"
                click={() => goToLesson("8W2eNLtri3CX4XaXEnK3", 0)}
                color="green"
              />
            </Col>
            <Col md={4}>
              <StructuredLessonItem
                title="1st & 2nd"
                content="1st & 2nd Curriculum"
                click={() => goToLesson("qWbaRb1CtKOJ406D5cm1", 1)}
                color="red"
              />
            </Col>
            <Col md={4}>
              <StructuredLessonItem
                title="3rd & 4th"
                content="3rd & 4th Curriculum"
                click={() => goToLesson("s6GSbHwLF3lwXc4gJyxl", 2)}
                color="yellow"
              />
            </Col>
            <Col md={4}>
              <StructuredLessonItem
                title="5th & 6th"
                content="5th & 6th Curriculum"
                click={() => goToLesson("EZsaJTyK1vFlWWD5fahY", 3)}
                color="purple"
              />
            </Col>
          </Row>
        </Container> */}
      </div>
    </Container>
  );
}

export default StructuredLessonListPage;
