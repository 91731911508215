import { useEffect, useState } from "react";
import { Card, Button, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import StructuredLessonListBox from "./StructuredLessonListBox";
import StructuredLessonListBoxMobile from "./StructuredLessonListBoxMobile";

const StructuredLessonListGroupMobile = (props) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const contentSelected = (i) => {
    props.click(props.group, i);
  };
  return (
    <div className={`p-0 structured-lesson-list-box-m`}>
      <div
        className={`px-3 structured-lesson-list-title-m grow`}
        onClick={() => contentSelected(0)}
      >
        {props.group.title}
      </div>
      <div className="px-2">
        {props.group.lessons?.sort((a, b) => a.order -b.order).map((lesson, i) => {
          return (
            <StructuredLessonListBoxMobile
              lesson={lesson}
              key={i}
              click={() => contentSelected(i)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StructuredLessonListGroupMobile;
