import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function Login(props) {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");
  const navigate = useNavigate();
  const handleValidation = async (event) => {
    let formIsValid = true;

    if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      formIsValid = false;
      setemailError("Email Not Valid");
      return false;
    } else {
      setemailError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Login";
  }, [])

  const loginSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          //var user = userCredential.user;
          if (props.NoRedirect) {
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          var errorMessage = error.message;
          setpasswordError(errorMessage);
        });
    }
  };

  return (
    <Container className="register-form-box full-page-length">
      <h1 className="pageTitle title-margin">Log In</h1>
      <hr />
      <Form onSubmit={loginSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            aria-describedby="emailHelpBlock"
            onChange={(event) => setEmail(event.target.value)}
          />
          <Form.Text id="emailHelpBlock" muted>
            {emailError}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
            aria-describedby="passwordHelpBlock"
          />
          <Form.Text id="passwordHelpBlock" muted>
            {passwordError}
          </Form.Text>
        </Form.Group>
        <div className="d-grid">
          <Button variant="primary" type="submit" className="mainButton">
            Sign In
          </Button>
        </div>
      </Form>

      <Link
        to={`/forgot-password`}
        className="text-link d-grid m-3 text-center"
      >
        <div>Forgot your password?</div>
      </Link>
    </Container>
  );
}

export default Login;
