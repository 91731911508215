import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import UnitLessonBox from "./UnitLessonBox";

function AddLessonToStructuredLessonGroupBox(props) {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  const [title, setTitle] = useState("");
  const [selectedLesson, setSelectedLesson] = useState({});
  const filterLessons = () => {
    if (!("lessons" in props) || title === "") {
      return [];
    }
    return props.lessons?.filter((lesson) =>
      lesson.Title?.toLowerCase().includes(title.toLowerCase())
    );
  };

  const saveLesson = () => {
    if (selectedLesson === {}) {
      return;
    }
    props.saveLesson(selectedLesson);
  };

  return (
    <Row className="add-lesson-container p-3">
      <Col md={4}>
        <div>
          <h1 className="pageTitle title-margin mb-3">New Lesson</h1>
          <div className="text-center m-3 p-3">
            {"Title" in selectedLesson
              ? selectedLesson.Title
              : "No Lesson Selected"}
          </div>
          <div className="d-grid structured-content-text-box mt-3">
            <Button
              variant="primary"
              className="mainButton mt-3"
              onClick={() => saveLesson()}
            >
              Save
            </Button>
          </div>
        </div>
      </Col>
      <Col md={8}>
        <div className="p-3">
          <Form.Control
            className="title-edit-form"
            placeholder="Type in the name of the lesson..."
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <Row>
          {filterLessons().map((lesson, j) => {
            return (
              <Col xs={6} key={j}>
                <UnitLessonBox
                  Lesson={lesson}
                  notSubscribed={false}
                  customClick={setSelectedLesson}
                ></UnitLessonBox>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}

export default AddLessonToStructuredLessonGroupBox;
