import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import {
  addEmailsToAdmins,
  addSubscriptionTypeToAdmins,
  getAdminsDetailsForAnalytics,
  getNews,
  removeSubscriptionFromUser,
  uploadContentToDatabase,
  uploadFileToStorage,
} from "../firebase";
import { UserContext } from "../providers/UserProvider";
import NewsBox from "../components/NewsBox";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import WorksheetBox from "../components/WorksheetBox";
import { useNavigate } from "react-router";
import CustomCard from "../components/generic/CustomCard";
import ListItem from "../components/generic/ListItem";

const ManageCouponAccountsPage = () => {
  const navigate = useNavigate();
  const [paidAdminAccounts, setPaidAdminAccounts] = useState([]);
  const [couponAdminAccounts, setCouponAdminAccounts] = useState([]);
  const [trialAdminAccounts, setTrialAdminAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data } = useContext(UserContext);

  useEffect(() => {
    document.title = "Daly Exercise+ | Glen Admin";
    if (data.Guid !== "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2") {
      navigate("/");
    }
    setLoading(true);
    getAdminsDetailsForAnalytics().then((res) => {
      setPaidAdminAccounts(res.paid);
      setCouponAdminAccounts(res.coupon);
      setTrialAdminAccounts(res.trial);
      setLoading(false);
    });
  }, [data]);

  const removeAccountSubscription = async (account) => {
    let ans = window.confirm(
      "Are you sure you wish to remove the subsctiption for: " +
        account.Email +
        "?"
    );
    if (ans) {
      let succ = await removeSubscriptionFromUser(account);
      if (succ) {
        await getAdminsDetailsForAnalytics().then((res) => {
          setPaidAdminAccounts(res.paid);
          setCouponAdminAccounts(res.coupon);
          setTrialAdminAccounts(res.trial);
          setLoading(false);
        });
      }
    }
  };

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Coupon Accounts</h1>
          <br />
          <Row>
            {couponAdminAccounts.map((account, i) => {
              return (
                <Col key={i} md={12}>
                  <ListItem
                    title={account.Email}
                    text={"Subscriptions: " + account.InviteCodes.length}
                    buttonText={"Remove Subscription"}
                    callToAction={() => removeAccountSubscription(account)}
                  ></ListItem>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default ManageCouponAccountsPage;
