import { useEffect } from "react";
import { Button, Stack, Form } from "react-bootstrap";

function AddStructuredLessonGroupButton(props) {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div className={`p-0 structured-lesson-list-box`}>
      <Stack
        className={`px-3 structured-lesson-list-title`}
        direction="horizontal"
      >
        <Form.Control
          placeholder="New Group"
          onChange={(event) => props.updateVal(event.target.value)}
        />
        <Button className="mainButton ms-3" onClick={() => props.saveGroup()}>
          Save
        </Button>
      </Stack>
    </div>
  );
}

export default AddStructuredLessonGroupButton;
