import { useContext, useEffect } from "react";
import { useState } from "react";
import {
  Container,
  Button,
  Card,
  Row,
  Modal,
  Form,
  Tabs,
  Tab,
  Accordion,
  Table,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import {
  deleteStudent,
  getFMSAreas,
  getFMSCategories,
  getStudent,
  updateStudent,
} from "../../functions/classroomFunctions";
import { UserContext } from "../../providers/UserProvider";
import FMSItem from "../../components/new-components/FMSItem";

function UpdatedStudentPage() {
  const [imageUrl, setImageUrl] = useState("");
  const [student, setStudent] = useState({});

  const [loading, setLoading] = useState(false);

  const [fmsDetails, setFmsDetails] = useState([]);

  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const navigate = useNavigate();

  const { data2 } = useContext(UserContext);

  let { id } = useParams();
  useEffect(() => {
    document.title = "Daly Exercise+ | Student";
    if ("Guid" in data2) {
      getStudentDetails(data2.Guid, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2]);

  const getStudentDetails = async (teacherID, studentID) => {
    setLoading(true);
    let student = await getStudent(teacherID, studentID);
    setImageUrl(`/avatars/${student.Avatar}`);
    setStudent(student);
    await getFmsDetails();
    setLoading(false);
  };

  const removeStudent = async () => {
    handleCloseDelete();
    await deleteStudent(data2.Guid, id);
    navigate("/classroom");
  };

  const getFmsDetails = async () => {
    let fmsPromises = [];
    let fms = await getFMSCategories();

    fms.forEach((f) => {
      fmsPromises.push(getFMSAreas(f.Guid));
    });

    let areas = await Promise.all(fmsPromises);
    let combinedFmsDetails = fms.map((f, i) => {
      return {
        ...f,
        Areas: areas[i],
      };
    });

    setFmsDetails(combinedFmsDetails);
  };

  const checkIfStudentIsFinished = (fms, student) => {
    let finished = false;
    if (
      student["FMSScores"] === undefined ||
      !(fms.Guid in student["FMSScores"])
    ) {
      finished = false;
    } else {
      let count = 0;
      Object.values(student["FMSScores"][fms.Guid]).forEach((area) => {
        if ("Score" in area && area.Score !== 0) {
          count += 1;
        }
      });

      if (count === fms.Areas.length) {
        finished = true;
      }
    }

    return finished;
  };

  const verifyStudentFMSScore = (student, fmsID, areaID) => {
    try {
      return student.FMSScores[fmsID][areaID].Score;
    } catch (e) {
      // console.log(e);
      return 0;
    }
  };

  const verifyStudentFMSComment = (student, fmsID, areaID) => {
    try {
      return student.FMSScores[fmsID][areaID].Comment;
    } catch (e) {
      // console.log(e);
      return "";
    }
  };

  const updateStudentFMSScore = (score, fmsID, areaID, student) => {
    let studentCopy = {
      ...student,
    };
    if (!("FMSScores" in student)) {
      studentCopy["FMSScores"] = {
        [fmsID]: {
          [areaID]: {
            Score: score,
            Comment: "",
          },
        },
      };
    } else if (!(fmsID in student["FMSScores"])) {
      studentCopy["FMSScores"][fmsID] = {
        [areaID]: {
          Score: score,
          Comment: "",
        },
      };
    } else if (!(areaID in student["FMSScores"][fmsID])) {
      studentCopy["FMSScores"][fmsID][areaID] = {
        Score: score,
        Comment: "",
      };
    } else {
      studentCopy["FMSScores"][fmsID][areaID]["Score"] = score;
    }

    setStudent({
      ...studentCopy,
    });
  };

  const updateStudentFMSComment = (comment, fmsID, areaID, student) => {
    let studentCopy = {
      ...student,
    };
    if (!("FMSScores" in student)) {
      studentCopy["FMSScores"] = {
        [fmsID]: {
          [areaID]: {
            Score: 0,
            Comment: comment,
          },
        },
      };
    } else if (!(fmsID in student["FMSScores"])) {
      studentCopy["FMSScores"][fmsID] = {
        [areaID]: {
          Score: 0,
          Comment: comment,
        },
      };
    } else if (!(areaID in student["FMSScores"][fmsID])) {
      studentCopy["FMSScores"][fmsID][areaID] = {
        Score: 0,
        Comment: comment,
      };
    } else {
      studentCopy["FMSScores"][fmsID][areaID]["Comment"] = comment;
    }

    setStudent({
      ...studentCopy,
    });
  };

  const countFinishedFMS = (fmsDetails, student) => {
    let total = 0;
    fmsDetails.forEach((fms) => {
      if (checkIfStudentIsFinished(fms, student)) {
        total += 1;
      }
    });

    return total;
  };

  const saveScores = async () => {
    await updateStudent(data2.Guid, student);
    await getStudentDetails(data2.Guid, id);
  };

  const cancelScores = async () => {
    await getStudentDetails(data2.Guid, id);
  };

  return (
    <div className="dashboard-body">
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wish to remove {student.FirstName} {student.LastName}{" "}
          from your classroom?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={removeStudent}>
            Confrim
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <h1 className="pageTitle title-margin">{student.Name}</h1>
        <br />
        <Tabs className="manage-school-tabs" fill variant="pills">
          <Tab eventKey="about" title="About">
            {loading ? (
              <div className="custom-loader" />
            ) : (
              <div className="white-box">
                <Card style={{ width: "24rem" }} className="border-0 ">
                  <Card.Img variant="top" src={imageUrl} />
                  <Card.Title className="text-center">
                    Student: {student.Name}
                  </Card.Title>

                  <Button
                    variant="danger"
                    className="m-1 button-no-colour mt-3"
                    onClick={handleShowDelete}
                  >
                    Remove Student
                  </Button>
                </Card>
              </div>
            )}
          </Tab>
          <Tab eventKey="fms" title="FMS">
            {loading ? (
              <div className="custom-loader" />
            ) : (
              <div>
                <div className="fms-button-container">
                  <h4 className="pageTitle title-margin">{student.Name}</h4>
                  <h4
                    className={`title-margin ms-3 ${
                      countFinishedFMS(fmsDetails, student) ===
                      fmsDetails.length
                        ? "text-green"
                        : "text-red"
                    }`}
                  >
                    {`${countFinishedFMS(fmsDetails, student)} /  ${
                      fmsDetails.length
                    }`}
                  </h4>

                  <button
                    className="custom-button-big custom-button-variant-good ms-auto me-3"
                    onClick={saveScores}
                  >
                    Save
                  </button>
                  <button
                    className="custom-button-big custom-button-variant-bad"
                    onClick={cancelScores}
                  >
                    Cancel
                  </button>
                </div>
                <Accordion
                  alwaysOpen
                  defaultActiveKey={[...Array(30).keys()]}
                  //   className="content-item-container content-item-container-variant-1 "
                >
                  {fmsDetails.map((fms, i) => {
                    return (
                      <Accordion.Item eventKey={i} key={i}>
                        <Accordion.Header>
                          <div
                            className={`${
                              checkIfStudentIsFinished(fms, student)
                                ? "text-green"
                                : "text-red"
                            }`}
                          >
                            {fms.Title}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th className="w-10">Cateogry</th>
                                <th className="w-40">Task</th>
                                <th className="w-25">Score</th>
                                <th className="w-25">Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fms.Areas.map((area, j) => (
                                <FMSItem
                                  comment={verifyStudentFMSComment(
                                    student,
                                    fms.Guid,
                                    area.Guid
                                  )}
                                  setComment={(comment) =>
                                    updateStudentFMSComment(
                                      comment,
                                      fms.Guid,
                                      area.Guid,
                                      student
                                    )
                                  }
                                  score={verifyStudentFMSScore(
                                    student,
                                    fms.Guid,
                                    area.Guid
                                  )}
                                  setScore={(score) =>
                                    updateStudentFMSScore(
                                      score,
                                      fms.Guid,
                                      area.Guid,
                                      student
                                    )
                                  }
                                  key={j}
                                  description={area.Description}
                                  category={area.Category}
                                />
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            )}
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default UpdatedStudentPage;
