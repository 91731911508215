import { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const PricingPage = () => {
  const embedId = "kGjpThGcjgM";

  const getIconUrl = () => {
    return;
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Pricing";
  }, [])
  return (
    <Container className="full-page-length" fluid>
      <div className="white-body">
        <Container>
          <h1 className="pageTitle  title-margin">Pricing</h1>

          <br />
          <Row>
          <Col sm={4} className="d-flex justify-content-center">
              <Card className="pricing-card-logo d-flex justify-content-center">
                <Card.Img
                  variant="top"
                  src="/icons/high_res_logo_icon.png"
                  className="pricing-card-picture-logo"
                />
                <Card.Body>
                  <Card.Text className="">
                    <br/>
                  <h5 className="">
                  All of our subscription options come with a <b className="headingColour">6 week free trial</b>.
                  Allowing you and your school to try out Daly Exercise+!
                  <br/>
                  <br/>
                  Simply create your account, put in your schools details, put in
                  your card details and the trial will automatically
                  start!
                </h5>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className="d-flex justify-content-center">
              <Card className="pricing-card-1 d-flex justify-content-center">
                <Card.Img
                  variant="top"
                  src="/avatars/avatar15.png"
                  className="pricing-card-picture"
                />
                <Card.Body>
                  <Card.Text className="text-center h3">
                    Individual Subscription
                  </Card.Text>
                  <br />
                  <Card.Text className="">
                    Subscribe to get access to over 500 custom lessons, movement
                    breaks, and homework exercises!
                  </Card.Text>
                  <br />
                  <Card.Text className="text-center h5">
                    <b>
                      €9.99 per month or <br />
                      €74.99 per year
                    </b>
                  </Card.Text>
                  <br />
                  <Link to={`/register/teacher`} className="text-link">
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="primary"
                        className="main-button-green button-padding"
                      >
                        Sign Up
                      </Button>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className="d-flex justify-content-center">
              <Card className="pricing-card-2">
                <Card.Img
                  variant="top"
                  src="/icons/icon_group.png"
                  className="pricing-card-picture"
                />
                <Card.Body>
                  <Card.Text className="text-center h3 headingColour">
                    School Subscription
                  </Card.Text>
                  <br />
                  <Card.Text className="">
                    Get access for your whole school! Sign up as an admin and
                    assign invite codes to each teacher who joins!
                  </Card.Text>
                  <br />
                  <Card.Text className="text-center h5">
                    <b>
                      €74.99 per person
                      <br /> per year
                    </b>
                  </Card.Text>
                  <br />
                  <Link to={`/register/school`} className="text-link">
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="primary"
                        className="main-button-yellow button-padding"
                      >
                        Sign Up
                      </Button>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <br />
          <br />
        </Container>
      </div>
    </Container>
  );
};

export default PricingPage;
