import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { v4 as uuidv4 } from "uuid";

import { httpsCallable } from "firebase/functions";

import { firestoreDb, functions } from "../firebase";
import { getEmptySchool } from "../models/school";
import { updateSubscriptionAdminUsers } from "./subscriptionFunctions";

export async function getSchoolUnassignedCodeCount(schoolID) {
  try {
    const q = query(
      collection(firestoreDb, `schools/${schoolID}/accessIDs`),
      where("Assigned", "==", false)
    );
    let snapshot = await getDocs(q);
    return snapshot.docs.length;
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function updateSchoolDetails(schoolID, details) {
  try {
    const q = query(doc(firestoreDb, `schools/${schoolID}`));
    let res = await updateDoc(q, details);
    return res;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function getSchoolDetails(schoolID) {
  try {
    const q = query(doc(firestoreDb, `schools/${schoolID}`));
    let snapshot = await getDoc(q);
    let details = snapshot.data();
    return details;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function getSchoolUsers(schoolID) {
  try {
    const q = query(collection(firestoreDb, `schools/${schoolID}/users`));
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((doc) => doc.data());

    return docs;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getSchoolAccessIDs(schoolID) {
  try {
    const q = query(collection(firestoreDb, `schools/${schoolID}/accessIDs`));
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((doc) => doc.data());

    return docs;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function checkIfUserSubscribed(userID, schoolID) {
  try {
    if (!userID || !schoolID) {
      return false;
    }
    const q = query(
      collection(firestoreDb, `schools/${schoolID}/accessIDs`),
      where("AccountID", "in", [userID])
    );
    let snapshot = await getDocs(q);
    return snapshot.docs.length >= 1;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function checkIfTeacherHasSchool(userID, schoolID) {
  try {
    if (!userID || !schoolID) {
      return false;
    }
    const q = query(doc(firestoreDb, `schools/${schoolID}/users/${userID}`));
    let snapshot = await getDoc(q);
    return snapshot.exists();
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function addTeacherToSchool(teacherEmail, schoolID) {
  try {
    const addTeacherToSchoolFunction = httpsCallable(
      functions,
      "addTeacherToSchool"
    );
    let response = await addTeacherToSchoolFunction({
      TeacherEmail: teacherEmail,
      SchoolID: schoolID,
    });
    return response.data.Status === "Success";
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function removeTeacherFromSchool(schoolID, teacherID) {
  try {
    let subscriptionRemoved = await removeSubscriptionFromTeacher(
      schoolID,
      teacherID
    );
    if (!subscriptionRemoved) {
      return false;
    }
    const schoolRef = query(
      doc(firestoreDb, `schools/${schoolID}/users/${teacherID}`)
    );
    let res = await deleteDoc(schoolRef);

    // const userRef = query(doc(firestoreDb, `users/${teacherID}`));
    // let res2 = await updateDoc(userRef, { SchoolID: "" });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function removeSubscriptionFromTeacher(schoolID, teacherID) {
  try {
    const q = query(
      collection(firestoreDb, `schools/${schoolID}/accessIDs`),
      where("AccountID", "in", [teacherID])
    );
    let snapshot = await getDocs(q);
    if (snapshot.docs.length === 0) {
      return true;
    }
    let id = snapshot.docs[0].data().Guid;

    const accessID = query(
      doc(firestoreDb, `schools/${schoolID}/accessIDs/${id}`)
    );
    let res2 = await updateDoc(accessID, { AccountID: "", Assigned: false });

    const userRef = query(
      doc(firestoreDb, `schools/${schoolID}/users/${teacherID}`)
    );
    let res3 = await updateDoc(userRef, { HasAccess: false });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function unassignAllSubscriptions(schoolID) {
  try {
    const addTeacherToSchoolFunction = httpsCallable(
      functions,
      "unassignSubscriptions"
    );
    let response = await addTeacherToSchoolFunction({
      SchoolID: schoolID,
    });
    return response.data.Status === "Success";
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function assignSubscriptionToTeacher(schoolID, teacherID) {
  try {
    const q = query(
      collection(firestoreDb, `schools/${schoolID}/accessIDs`),
      where("Assigned", "in", [false])
    );
    let snapshot = await getDocs(q);

    if (snapshot.docs.length === 0) {
      return false;
    }

    let id = snapshot.docs[0].data().Guid;

    const accessID = query(
      doc(firestoreDb, `schools/${schoolID}/accessIDs/${id}`)
    );
    let res2 = await updateDoc(accessID, {
      AccountID: teacherID,
      Assigned: true,
    });

    const userRef = query(
      doc(firestoreDb, `schools/${schoolID}/users/${teacherID}`)
    );
    let res3 = await updateDoc(userRef, { HasAccess: true });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function assignExactSubscriptionToTeacher(
  schoolID,
  teacherID,
  subscriptionID
) {
  try {
    const accessID = query(
      doc(firestoreDb, `schools/${schoolID}/accessIDs/${subscriptionID}`)
    );
    let res2 = await updateDoc(accessID, {
      AccountID: teacherID,
      Assigned: true,
    });

    const userRef = query(
      doc(firestoreDb, `schools/${schoolID}/users/${teacherID}`)
    );
    let res3 = await updateDoc(userRef, { HasAccess: true });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function updateSchoolAdminUsers(
  schoolID,
  adminUsers,
  subscriptionRef
) {
  try {
    const q = query(doc(firestoreDb, `schools/${schoolID}`));
    let res = await updateDoc(q, { AdminID: adminUsers });
    let res2 = await updateSubscriptionAdminUsers(subscriptionRef, adminUsers);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function createInitialSchool(initialData) {
  let school = getEmptySchool();
  let schoolID = uuidv4().toString();
  school = {
    ...school,
    ...initialData,
    Guid: schoolID,
  };

  try {
    const q = query(doc(firestoreDb, `schools/${schoolID}`));
    let res = await setDoc(q, school);
    return schoolID;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function assignAllSubscriptions(schoolID) {
  try {
    const assignSubscriptionsFunction = httpsCallable(
      functions,
      "assignSubscriptions"
    );
    let response = await assignSubscriptionsFunction({
      SchoolID: schoolID,
    });
    return response.data.Status === "Success";
  } catch (e) {
    console.log(e);
    return false;
  }
}
