import { useState } from "react";
import { Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const StudentBox = (props) => {
  const [imageUrl, setImageUrl] = useState(
    `/avatars/${props.Student.Avatar}`
  );

  const navigate = useNavigate();
  const goToStudentPage = () => {
    navigate(`/student/${props.Student.Guid}`);
  };
  return (
    <Card
      style={{ margin: 10 }}
      className="grow border-0"
      onClick={goToStudentPage}
    >
      <Card.Img variant="top" src={imageUrl} style={{ height: "10rem" }} />
      <Card.Title className="text-center">{props.Student.FirstName}</Card.Title>
      <Card.Subtitle className="mb-2 text-muted text-center">
        {props.Student.Name}
      </Card.Subtitle>
    </Card>
  );
};

export default StudentBox;
