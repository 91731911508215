import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { auth, getRandomExercisesByYear } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import HomeworkPreviewPage from "./HomeworkPreviewPage";

function HomeworkRandomPage() {
  const [homeworkName, setHomeworkName] = useState("");
  const [numberOfExercises, setNumberOfExercises] = useState(1);
  const [noeError, setNoeError] = useState("");
  const [nameError, setNameError] = useState("");
  const [exercises, setExercises] = useState([]);
  const navigate = useNavigate();
  const {data} = useContext(UserContext);
  const handleValidation = () => {
    let formIsValid = true;

    if (homeworkName === "") {
      setNameError("Please enter a name for your homework");
      formIsValid = false;
    }

    return formIsValid;
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Homework Random";
  }, [data])

  const submitRandom = async () => {
    if (handleValidation()) {
      let random = await getRandomExercisesByYear(data.Year, numberOfExercises);
      setExercises(random);
    }
  };

  return (
    <Container className="full-page-length">
      {exercises.length === 0 ? (
        <div>
          <h1 className="pageTitle title-margin">Random Workout Generator</h1>
          <hr />
          <div className="d-flex justify-content-center">
            {" "}
            <img
              src="/icons/icon_random.png"
              style={{ height: "20rem", margin: "-60px" }}
            />
          </div>
          <h4 className="text-center">
            <b>Add in some details</b>
          </h4>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Workout Name</Form.Label>
              <Form.Control
                placeholder="Enter name"
                aria-desscribedby="homeworkNameHelpBlock"
                onChange={(event) => setHomeworkName(event.target.value)}
              />
              <Form.Text id="homeworkNameHelpBlock" muted>
                {nameError}
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Number of Exercises</Form.Label>
              <Form.Select
                onChange={(event) => setNumberOfExercises(event.target.value)}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
              </Form.Select>
            </Form.Group>
            <hr />
            <div className="d-grid">
              <Button variant="primary" onClick={submitRandom}>
                Generate Workout
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <HomeworkPreviewPage name={homeworkName} exercises={exercises} />
      )}
    </Container>
  );
}

export default HomeworkRandomPage;
