import { useEffect, useState } from "react";
import { Card, Button, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const FMSCategoryBox = ({ title, finishedPromise, id }) => {
  const [imageUrl, setImageUrl] = useState("");
  const [finished, setFinished] = useState(false)
  const [colour, setColour] = useState("");
  useEffect(() => {
    finishedPromise.then(res => {
      setFinished(res)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const navigate = useNavigate();

  const goToFMSPage = () => {
    navigate(`/classroom/fms/${id}`);
  };
  return (
    <div
      className={`fms-box-${finished ? "finished" : "unfinished"} grow`}
      onClick={goToFMSPage}
    >
      <h3> {title}</h3>
      <div >
        {finished ? (
          <div className="text-green">Complete</div>
        ) : (
          <div className="text-red">Incomplete</div>
        )}
      </div>
    </div>
  );
};

export default FMSCategoryBox;
