export const getEmptySchool = () => {
  let school = {
    AdminID: [],
    Country: "",
    County: "",
    CreationDate: new Date(),
    Guid: "",
    IsTrial: true,
    Name: "",
    RollNumber: "",
    Town: "",
    TrialEnd: new Date(),
    SubscriptionStart: new Date(),
    StripeCustomerRef: "",
    SubscriptionReference: "",
  };

  return school;
};
