import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Container,
  Form,
} from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/generic/CustomModal";
import { addStudentToClassroom } from "../../functions/classroomFunctions";
import { getEmptyStudent } from "../../models/student";
import { UserContext } from "../../providers/UserProvider";

function ImportStudentsPage() {
  const { data2 } = useContext(UserContext);

  const [nameList, setNameList] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [uploadingData, setUploadingData] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Daly Exercise+ | Add Pupils";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2]);
  const placeholder = `Ryan McCann
Ellen Flaherty
Caoimhe Rush
...

  `;

  const confirmNameList = async () => {
    let names = nameList.split("\n");
    if (names.length > 0) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const confirmModal = async () => {
    const names = nameList.split("\n");
    const teacherID = data2.Guid;
    let functionPromises = [];
    closeModal();
    setUploadingData(true);
    names.forEach((name) => {
      let student = getEmptyStudent();
      student = {
        ...student,
        Name: name,
      };
      functionPromises.push(
        addStudentToClassroom(teacherID, student).then((res) => {
          return { Success: res, Name: name };
        })
      );
    });

    const result = await Promise.all(functionPromises);

    if (result.every((v) => v.Success === true)) {
      navigate(-1);
      return;
    }

    let errorMessage = "Error uploading these names:\n";
    result.forEach((res) => {
      if (!res.Success) {
        errorMessage += res.Name + " \n";
      }
    });

    setUploadingData(false);

    window.alert(errorMessage);
  };

  const formatNameList = () => {
    let comp = (
      <div>
        {nameList.split("\n").map((name, i) => {
          return <div key={i}>{name}</div>;
        })}
      </div>
    );

    return comp;
  };

  return (
    <Container fluid>
      <CustomModal
        onClose={closeModal}
        onConfirm={confirmModal}
        show={showModal}
        title="Please Confirm"
        text="Are these names correct?"
        subtext={formatNameList()}
      />
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Add Pupils</h1>
          <br />
          {uploadingData ? (
            <div className="custom-loader"></div>
          ) : (
            <div className="school-container">
              <div className="header-text">
                Copy and paste your pupil's details
              </div>
              <br />
              <div className="emphasis-text">
                Paste the names of your pupil's to add to your classroom. Each
                name should be separated by a new line.
              </div>
              <br />
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={10}
                    placeholder={placeholder}
                    onChange={(event) => setNameList(event.target.value)}
                  />
                </Form.Group>
              </Form>
              <button
                className="custom-button-big float-end"
                type="button"
                onClick={() => confirmNameList()}
              >
                Add
              </button>
              <br />
              <br />
              <br />
            </div>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default ImportStudentsPage;
