import { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import StudentBox from "../components/StudentBox";
import { getClassData, getClassroomStudentsFromList } from "../firebase";
import { UserContext } from "../providers/UserProvider";

function Classroom() {
  const [className, setClassName] = useState("");
  const [students, setStudents] = useState([]);
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Classroom";
    if ("UserName" in data) {
      if (className === "") {
        getClassData(data.ClassId).then((result) => {
          console.log(result);
          if (!result.Name) {
            navigate("/class-details");
            return;
          }
          setClassName(result.Name);
          getClassroomStudentsFromList(result.students).then((studentData) => {
            setStudents(studentData);
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Container className="full-page-length">
      <h1 className="pageTitle title-margin">Classroom</h1>
      <hr />
      <h2 className="pageTitle">{className}</h2>

      <p className="text-center">Click on a pupil to view more details</p>
      <Link to={`classcode`} className="text-link d-grid">
        <Button variant="primary" className="mainButton" size="lg">
          Manage Class
        </Button>
      </Link>
      <br />
      <Row>
        {students.length > 0 ? (
          students?.map((student) => {
            return (
              <Col xs={6} sm={4} md={3} xl={2} xxl={2} key={student.Guid}>
                <StudentBox Student={student}></StudentBox>
              </Col>
            );
          })
        ) : (
          <h4 className="text-center">Add some students to your class!</h4>
        )}
      </Row>
      {/* <hr />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Student</th>
            <th>Head</th>
            <th>Trunks</th>
            <th>Legs</th>
            <th>Arms</th>
          </tr>
        </thead>
        <tbody>
          {students.length > 0 ? (
            students?.map((student, i) => {
              return (
                <tr key={i}>
                  <th>{student.FirstName}</th>
                  <th>1</th>
                  <th>1</th>
                  <th>1</th>
                  <th>1</th>
                </tr>
              );
            })
          ) : (
            <tr></tr>
          )}
        </tbody>
      </Table> */}
    </Container>
  );
}

export default Classroom;
