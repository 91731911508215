import { useEffect, useState } from "react";
import { Card, Button, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const StructuredLessonItem = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const [colour, setColour] = useState("");
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const getBoxClasses = () => {
    return `structured-lesson-item box-${props.color.toLowerCase()} ${
      props.disabled ? "box-disabled" : "box-enabled"
    }`;
  };
  return (
    <div className={getBoxClasses()} onClick={() => props.click()}>
      <div className="structured-lesson-item-title">
        {props.icon ? (
            <div className="strand-icon-wrapper">
            <img src={`/icons/${props.icon}.png`} className="updated-strand-icon"/>
            </div>
          
        ) : (
          <div className="structured-lesson-item-title-text">{props.title}</div>
        )}
      </div>
      <div className={"structured-lesson-item-content box-" + `white`}>{props.content}</div>
    </div>
  );
};

export default StructuredLessonItem;
