import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Accordion,
  Stack,
  Modal,
} from "react-bootstrap";
import {
  auth,
  getAllExercisesByYear,
  getExerciseUrl,
  getRandomExercisesByYear,
} from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import HomeworkPreviewPage from "./HomeworkPreviewPage";
import ExercisePreviewBox from "../components/ExercisePreviewBox";
import ReactPlayer from "react-player";
import ExerciseCustomBox from "../components/ExerciseCustomBox";

function HomeworkCustomisePage() {
  const [homeworkName, setHomeworkName] = useState("");
  const [numberOfExercises, setNumberOfExercises] = useState(1);
  const [noeError, setNoeError] = useState("");
  const [nameError, setNameError] = useState("");
  const [exercises, setExercises] = useState([]);
  const [allExercises, setAllExercises] = useState({});
  const navigate = useNavigate();
  const { data } = useContext(UserContext);
  const [gifUrl, setGifUrl] = useState("");

  const [showExercise, setShowExercise] = useState(false);
  const [exerciseName, setExerciseName] = useState("");
  const [confirm, setConfirm] = useState(false);
  const handleValidation = () => {
    let formIsValid = true;

    if (homeworkName === "") {
      setNameError("Please enter a name for your workout");
      formIsValid = false;
    }

    return formIsValid;
  };

  const submitRandom = async () => {
    if (handleValidation()) {
      setConfirm(true);
    }
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Homework Customise";
    if ("UserName" in data) {
      getAllExercisesByYear(data.Year).then((result) => {
        setAllExercises(result);
      });
    }
  }, [data]);

  const changeSelectedExercise = async (exercise) => {
    let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
    setGifUrl(url);
    setExerciseName(exercise.Name);
    setShowExercise(true);
  };

  const handleCloseExercise = () => {
    setShowExercise(false);
  };

  const exerciseClicked = (exercise) => {
    if (exercises.includes(exercise)) {
      setExercises(exercises.filter((e) => e !== exercise));
    } else {
      exercises.push(exercise);
    }
  };

  return (
    <Container className="full-page-length">
      <Modal
        show={showExercise}
        onHide={handleCloseExercise}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {exerciseName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <ReactPlayer url={gifUrl} loop={true} playing={true} muted={true} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseExercise}>Close</Button>
        </Modal.Footer>
      </Modal>
      {!confirm ? (
        <div>
          <h1 className="pageTitle title-margin">Customise Your Workout</h1>
          <hr />
          <div className="d-flex justify-content-center">
            <img
              src="/icons/icon_tool_singular.png"
              style={{ height: "20rem", margin: "-60px" }}
            />
          </div>
          <h4 className="text-center">
            <b>Select Your Exercises</b>
          </h4>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Workout Name</Form.Label>
              <Form.Control
                placeholder="Enter name"
                aria-describedby="homeworkNameHelpBlock"
                onChange={(event) => setHomeworkName(event.target.value)}
              />
              <Form.Text id="homeworkNameHelpBlock" muted>
                {nameError}
              </Form.Text>
            </Form.Group>

            {Object.keys(allExercises) === 0 ? (
              <p>Loading</p>
            ) : (
              <Accordion>
                {Object.entries(allExercises).map((x, i) => {
                  return (
                    <Accordion.Item key={i} eventKey={i}>
                      <Accordion.Header className="headingColour">
                        {x[0]}
                      </Accordion.Header>
                      <Accordion.Body>
                        {Object.values(x[1])
                          .sort((a, b) => a.Name.localeCompare(b.Name))
                          .map((exercise, j) => {
                            return (
                              <ExerciseCustomBox
                                exercise={exercise}
                                key={j}
                                PreviewExercise={changeSelectedExercise}
                                AddExercise={exerciseClicked}
                              />
                            );
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            )}
            <hr />
            <div className="d-grid">
              <Button variant="primary" onClick={submitRandom}>
                Make Workout
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <HomeworkPreviewPage name={homeworkName} exercises={exercises} />
      )}
    </Container>
  );
}

export default HomeworkCustomisePage;
