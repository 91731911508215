import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import {
  auth,
  createFreePremiumAccount,
  getAdminDetails,
  moveToCheckout,
  UploadAdminSchoolDetails,
  UploadClassName,
  UploadUserYear,
} from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { useNavigate } from "react-router";
import { AiFillRedditSquare } from "react-icons/ai";
import StructuredLessonItem from "../components/StructuredLessonItem";

function NewSubscriptionPage() {
  const [firstName, setFirstName] = useState("");
  const [firstNameHelpText, setFirstNameHelpText] = useState("");

  const [secondName, setSecondName] = useState("");
  const [secondNameHelpText, setSecondNameHelpText] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberHelpText, setPhoneNumberHelpText] = useState("");

  const [userRole, setUserRole] = useState("");
  const [userRoleHelpText, setUserRoleHelpText] = useState("");

  const [schoolName, setSchoolName] = useState("");
  const [schoolNameHelpText, setSchoolNameHelpText] = useState("");

  const [schoolRollNumber, setSchoolRollNumber] = useState("");
  const [schoolRollNumberHelpText, setSchoolRollNumberHelpText] = useState("");

  const [schoolTown, setSchoolTown] = useState("");
  const [schoolTownHelpText, setSchoolTownHelpText] = useState("");

  const [schoolCounty, setSchoolCounty] = useState("");
  const [schoolCountyHelpText, setCountyTownHelpText] = useState("");

  const [schoolCountry, setSchoolCountry] = useState("Ireland");

  const [numOfTeachers, setNumOfTeachers] = useState(1);
  const [numOfTeachersHelpText, setNumOfTeachersHelpText] = useState("");

  const [frequencyText, setFrequecyText] = useState("per year");

  const [subscriptionType, setSubscriptionType] = useState("74.99");
  const [subscriptionTypeText, setSubscriptionTypeText] = useState("");

  const [couponCode, setCouponCode] = useState("");
  const [hasCode, setHasCode] = useState(false);

  const [continueClicked, setContinueClicked] = useState(false);

  const changeSubscriptionType = async (e) => {
    setSubscriptionType(e.target.value);
    if (e.target.value === "9.99") {
      setFrequecyText("per month");
    } else {
      setFrequecyText("per year");
    }
  };

  const changeNumOfTeachers = async (e) => {
    if (
      e.target.value === "" ||
      isNaN(e.target.value) ||
      parseInt(e.target.value) <= 0
    ) {
      setNumOfTeachers("1");
    } else {
      setNumOfTeachers(e.target.value);
    }

    if (!(e.target.value === "1" || e.target.value === "")) {
      setFrequecyText("per year");
      setSubscriptionType("74.99");
    }
  };

  const { data } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Daly Exercise+ | Subscription";

    if ("Guid" in data) {
      getAdminDetails(data.Guid).then((res) => {
        if (res !== {}) {
          setFirstName(res.FirstName ? res.FirstName : "");
          setSecondName(res.SecondName ? res.SecondName : "");
          setPhoneNumber(res.ContactNumber ? res.ContactNumber : "");
          setUserRole(res.Roll ? res.Roll : "");
          setSchoolName(res.SchoolName ? res.SchoolName : "");
          setSchoolRollNumber(res.SchoolRoleNumber ? res.SchoolRoleNumber : "");
          setSchoolTown(res.SchoolTown ? res.SchoolTown : "");
          setSchoolCounty(res.SchoolCounty ? res.SchoolCounty : "");
          setSchoolCountry(res.SchoolCountry ? res.SchoolCountry : "");
        }
      });
    }
  }, [data]);

  const registerSubmit = async (e) => {
    e.preventDefault();
  };

  const subscribe = async (e) => {
    e.preventDefault();
    let passCheck = true;

    if (firstName === "") {
      setFirstNameHelpText("Please enter your first name");
      passCheck = false;
    } else {
      setFirstNameHelpText("");
    }

    if (secondName === "") {
      setSecondNameHelpText("Please enter your second name");
      passCheck = false;
    } else {
      setSecondNameHelpText("");
    }

    if (phoneNumber === "") {
      setPhoneNumberHelpText("Please enter your contact number");
      passCheck = false;
    } else {
      setPhoneNumberHelpText("");
    }

    if (userRole === "") {
      setUserRoleHelpText("Please enter your roll in your school");
      passCheck = false;
    } else {
      setUserRoleHelpText("");
    }

    if (schoolName === "") {
      setSchoolNameHelpText("Please enter your school's name");
      passCheck = false;
    } else {
      setSchoolNameHelpText("");
    }

    if (schoolTown === "") {
      setSchoolTownHelpText("Please enter your school's town");
      passCheck = false;
    } else {
      setSchoolTownHelpText("");
    }

    if (numOfTeachers === "" || isNaN(numOfTeachers)) {
      setNumOfTeachersHelpText("Please enter a valid number of teachers");
      passCheck = false;
    } else {
      setNumOfTeachersHelpText("");
    }

    let adminDetails = {
      FirstName: firstName,
      SecondName: secondName,
      ContactNumber: phoneNumber,
      Roll: userRole,
      SchoolName: schoolName,
      SchoolRoleNumber: schoolRollNumber,
      SchoolTown: schoolTown,
      County: schoolCounty ? schoolCounty : "",
      Country: schoolCountry ? schoolCountry : "",
      Email: data.UserName,
      Guid: data.Guid,
      HasCouponCode: hasCode,
      Status: "trialing",
      Created: new Date().toDateString(),
      CouponCode: couponCode,
    };

    setContinueClicked(true);

    if (hasCode) {
      adminDetails.Status = "active";

      let res = await createFreePremiumAccount(
        couponCode,
        data.Guid,
        numOfTeachers
      );
      if (!res) {
        alert("Your coupon code was invalid");
      } else {
        adminDetails.SubscriptionId = res.id;
        await UploadAdminSchoolDetails(adminDetails, data.Guid);
        navigate("/admin");
      }
      setContinueClicked(false);
      return;
    }

    if (passCheck) {
      if (subscriptionType === "74.99") {
        var itemPrice = "price_1JdeKYFlmgYLxX3G48LVhml5";
        // var itemPrice = "price_1Jb4QhFlmgYLxX3GXnc4mvNs";
      } else if (subscriptionType === "9.99") {
        var itemPrice = "price_1Je3VKFlmgYLxX3GpCbfOmbW";
        // var itemPrice = "price_1Jb4QhFlmgYLxX3G2hMAfrbl";
      } else {
        setContinueClicked(false);
        return;
      }
      UploadAdminSchoolDetails(adminDetails, data.Guid);

      await moveToCheckout(itemPrice, data.Guid, numOfTeachers);
    }
  };

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">
            Sign Up Your School To Daly Exercise +
          </h1>
          <br />
          <div className="subscription-details-box">
            <Form onSubmit={subscribe}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label className="form-label-text">
                      First Name
                    </Form.Label>
                    <Form.Control
                      required
                      placeholder="First Name"
                      aria-describedby="firstNameHelpBlock"
                      onChange={(event) => setFirstName(event.target.value)}
                      value={firstName}
                    />
                    <Form.Text
                      id="firstNameHelpBlock"
                      muted
                      className="text-danger"
                    >
                      {firstNameHelpText}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="secondName">
                    <Form.Label className="form-label-text">
                      Second Name
                    </Form.Label>
                    <Form.Control
                      value={secondName}
                      required
                      placeholder="Second Name"
                      aria-describedby="secondNameHelpBlock"
                      onChange={(event) => setSecondName(event.target.value)}
                    />
                    <Form.Text
                      id="secondNameHelpBlock"
                      muted
                      className="text-danger"
                    >
                      {secondNameHelpText}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="contactNumber">
                    <Form.Label className="form-label-text">
                      Contact Number
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>+353</InputGroup.Text>
                      <Form.Control
                        type="tel"
                        value={phoneNumber}
                        required
                        placeholder=""
                        aria-describedby="phoneNumberHelpBlock"
                        onChange={(event) => setPhoneNumber(event.target.value)}
                      />
                    </InputGroup>

                    <Form.Text
                      id="phoneNumberHelpBlock"
                      muted
                      className="text-danger"
                    >
                      {phoneNumberHelpText}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="userRole">
                    <Form.Label className="form-label-text">Role</Form.Label>
                    <Form.Control
                      required
                      value={userRole}
                      placeholder="Principal, Teacher etc"
                      aria-describedby="userRoleHelpBlock"
                      onChange={(event) => setUserRole(event.target.value)}
                    />

                    <Form.Text
                      id="userRoleHelpBlock"
                      muted
                      className="text-danger"
                    >
                      {userRoleHelpText}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="schoolName">
                    <Form.Label className="form-label-text">
                      School Name
                    </Form.Label>
                    <Form.Control
                      value={schoolName}
                      required
                      placeholder="School Name"
                      aria-describedby="schoolNameHelpBlock"
                      onChange={(event) => setSchoolName(event.target.value)}
                    />
                    <Form.Text
                      id="schoolNameHelpBlock"
                      muted
                      className="text-danger"
                    >
                      {schoolNameHelpText}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="rollNumber">
                    <Form.Label className="form-label-text">
                      Roll Number (Optional)
                    </Form.Label>
                    <Form.Control
                      value={schoolRollNumber}
                      placeholder="Roll Number"
                      aria-describedby="rollNumberHelpBlock"
                      onChange={(event) =>
                        setSchoolRollNumber(event.target.value)
                      }
                    />
                    <Form.Text
                      id="rollNumberHelpBlock"
                      muted
                      className="text-danger"
                    >
                      {schoolRollNumberHelpText}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="schoolTown">
                    <Form.Label className="form-label-text">Town</Form.Label>
                    <Form.Control
                      value={schoolTown}
                      placeholder="Town"
                      aria-describedby="schoolTownHelpBlock"
                      onChange={(event) => setSchoolTown(event.target.value)}
                    />
                    <Form.Text
                      id="schoolTownHelpBlock"
                      muted
                      className="text-danger"
                    >
                      {schoolTownHelpText}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="schoolCounty">
                    <Form.Label className="form-label-text">County</Form.Label>
                    <Form.Control
                      value={schoolCounty}
                      placeholder="County"
                      aria-describedby="schoolCountyHelpBlock"
                      onChange={(event) => setSchoolCounty(event.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="country">
                    <Form.Label className="form-label-text">Country</Form.Label>
                    <Form.Select
                      onChange={(event) => setSchoolCountry(event.target.value)}
                    >
                      <option value="Ireland">Ireland</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Åland Islands">Åland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">
                        British Indian Ocean Territory
                      </option>
                      <option value="Brunei Darussalam">
                        Brunei Darussalam
                      </option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">
                        Cocos (Keeling) Islands
                      </option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Congo, The Democratic Republic of The">
                        Congo, The Democratic Republic of The
                      </option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D'ivoire">Cote D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">
                        Falkland Islands (Malvinas)
                      </option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">
                        French Southern Territories
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-bissau">Guinea-bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">
                        Heard Island and Mcdonald Islands
                      </option>
                      <option value="Holy See (Vatican City State)">
                        Holy See (Vatican City State)
                      </option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">
                        Iran, Islamic Republic of
                      </option>
                      <option value="Iraq">Iraq</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, Democratic People's Republic of">
                        Korea, Democratic People's Republic of
                      </option>
                      <option value="Korea, Republic of">
                        Korea, Republic of
                      </option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao People's Democratic Republic">
                        Lao People's Democratic Republic
                      </option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libyan Arab Jamahiriya">
                        Libyan Arab Jamahiriya
                      </option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Macedonia, The Former Yugoslav Republic of">
                        Macedonia, The Former Yugoslav Republic of
                      </option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">
                        Micronesia, Federated States of
                      </option>
                      <option value="Moldova, Republic of">
                        Moldova, Republic of
                      </option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">
                        Netherlands Antilles
                      </option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">
                        Palestinian Territory, Occupied
                      </option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">
                        Russian Federation
                      </option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">
                        Saint Kitts and Nevis
                      </option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Pierre and Miquelon">
                        Saint Pierre and Miquelon
                      </option>
                      <option value="Saint Vincent and The Grenadines">
                        Saint Vincent and The Grenadines
                      </option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">
                        Sao Tome and Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and The South Sandwich Islands">
                        South Georgia and The South Sandwich Islands
                      </option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">
                        Svalbard and Jan Mayen
                      </option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">
                        Syrian Arab Republic
                      </option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">
                        Tanzania, United Republic of
                      </option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">
                        Trinidad and Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">
                        Turks and Caicos Islands
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">
                        United Arab Emirates
                      </option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">
                        United States Minor Outlying Islands
                      </option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">
                        Virgin Islands, British
                      </option>
                      <option value="Virgin Islands, U.S.">
                        Virgin Islands, U.S.
                      </option>
                      <option value="Wallis and Futuna">
                        Wallis and Futuna
                      </option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="numOfTeachers">
                    <Form.Label className="form-label-text">
                      Number of teachers: {numOfTeachers}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="1"
                      aria-describedby="numOfTeachersHelpBlock"
                      onChange={(event) => changeNumOfTeachers(event)}
                    />
                    <Form.Text id="numOfTeachersHelpBlock" muted>
                      {numOfTeachersHelpText}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  {/* <Form.Group className="mb-3">
                    <Form.Label className="form-label-text">
                      Trial Code?
                    </Form.Label>
                    <Form.Check // prettier-ignore
                      id={`code`}
                      label={` Tick this if you have a trial code for your school.`}
                      checked={hasCode}
                      onChange={() => setHasCode(!hasCode)}
                    />
                    <Form.Text id="areaHelpBlock" muted></Form.Text>
                  </Form.Group> */}
                </Col>
              </Row>

              <hr />
              {hasCode ? (
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label className="form-label-text">
                        Trial Code
                      </Form.Label>
                      <Form.Control
                        placeholder=""
                        onChange={(event) => setCouponCode(event.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label className="form-label-text">
                        Subscription Type
                      </Form.Label>
                      <Form.Select
                        onChange={(event) => changeSubscriptionType(event)}
                      >
                        {parseInt(numOfTeachers) === 1 ? (
                          <>
                            <option value={74.99}>
                              €74.99 yearly subscription
                            </option>
                            <option value={9.99}>
                              €9.99 monthly subscription
                            </option>
                          </>
                        ) : (
                          <option value={74.99}>
                            €74.99 yearly subscription
                          </option>
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label className="form-label-text">
                        Total Price
                      </Form.Label>
                      <Form.Control
                        disabled
                        placeholder={
                          "€" +
                          (
                            parseFloat(numOfTeachers) *
                            parseFloat(subscriptionType)
                          ).toFixed(2) +
                          " " +
                          frequencyText
                        }
                        aria-describedby="areaHelpBlock"
                        onChange={(event) =>
                          setNumOfTeachers(event.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <hr />
              <div className="d-grid">
                {!continueClicked ? (
                  <Button
                    type="submit"
                    className="form-submit-button mainButton"
                  >
                    Continue
                  </Button>
                ) : (
                  <div className="custom-loader"></div>
                )}
              </div>
            </Form>
          </div>
          <br />
          <br />
        </Container>
      </div>
    </Container>
  );
}

export default NewSubscriptionPage;
