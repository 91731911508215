import { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Container,
  Form,
  Stack,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import {
  getAllCategoriesNew,
  getAllLessons,
  getAllStrandsNew,
  getAllSubcategoriesNew,
  getImageUrl,
  getLessonNew,
  lessonAnalytics,
  uploadAllCategoriesNew,
  uploadAllLessonsNew,
  uploadAllSubCategoriesNew,
} from "../../firebase";
import CategoryBox from "../../components/CategoryBox";
import SearchBarLink from "../../components/SearchBarLink";
import { getEventStrandsByYear, getStrandsByYear } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { Link } from "react-router-dom";
import {
  getClassroomStudents,
  getFMSAreas,
  getFMSCategory,
  updateStudent,
} from "../../functions/classroomFunctions";
import FMSItem from "../../components/new-components/FMSItem";

function FMSPage() {
  let { id } = useParams();

  const [details, setDetails] = useState({ Title: "Loading" });
  const [fmsAreas, setFmsAreas] = useState([]);
  const [students, setStudents] = useState([]);
  const { data2 } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Daly Exercise+ | Classroom";

    if ("Guid" in data2) {
      getFMSDetails(data2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2]);

  const getFMSDetails = async (data) => {
    setLoading(true);
    // Get FMS Details
    let details = await getFMSCategory(id);
    let fmsAreas = await getFMSAreas(id);
    setDetails(details);
    setFmsAreas(fmsAreas);
    // Get Student Details

    let students = await getClassroomStudents(data.Guid);
    setStudents(students);
    setLoading(false);
  };

  const verifyStudentFMSScore = (student, fmsID, areaID) => {
    try {
      return student.FMSScores[fmsID][areaID].Score;
    } catch (e) {
      // console.log(e);
      return 0;
    }
  };

  const verifyStudentFMSComment = (student, fmsID, areaID) => {
    try {
      return student.FMSScores[fmsID][areaID].Comment;
    } catch (e) {
      // console.log(e);
      return "";
    }
  };

  const updateStudentFMSScore = (score, fmsID, areaID, student) => {
    let studentIndex = students.indexOf(student);
    if (!("FMSScores" in student)) {
      student["FMSScores"] = {
        [fmsID]: {
          [areaID]: {
            Score: score,
            Comment: "",
          },
        },
      };
    } else if (!(fmsID in student["FMSScores"])) {
      student["FMSScores"][fmsID] = {
        [areaID]: {
          Score: score,
          Comment: "",
        },
      };
    } else if (!(areaID in student["FMSScores"][fmsID])) {
      student["FMSScores"][fmsID][areaID] = {
        Score: score,
        Comment: "",
      };
    } else {
      student["FMSScores"][fmsID][areaID]["Score"] = score;
    }

    let studentsCopy = [...students];
    studentsCopy[studentIndex] = student;
    setStudents(studentsCopy);
  };

  const updateStudentFMSComment = (comment, fmsID, areaID, student) => {
    let studentIndex = students.indexOf(student);
    if (!("FMSScores" in student)) {
      student["FMSScores"] = {
        [fmsID]: {
          [areaID]: {
            Score: 0,
            Comment: comment,
          },
        },
      };
    } else if (!(fmsID in student["FMSScores"])) {
      student["FMSScores"][fmsID] = {
        [areaID]: {
          Score: 0,
          Comment: comment,
        },
      };
    } else if (!(areaID in student["FMSScores"][fmsID])) {
      student["FMSScores"][fmsID][areaID] = {
        Score: 0,
        Comment: comment,
      };
    } else {
      student["FMSScores"][fmsID][areaID]["Comment"] = comment;
    }

    let studentsCopy = [...students];
    studentsCopy[studentIndex] = student;
    setStudents(studentsCopy);
  };

  const checkIfStudentIsFinished = (student) => {
    let finished = false;
    if (student["FMSScores"] === undefined || !(id in student["FMSScores"])) {
      finished = false;
    } else {
      let count = 0;
      Object.values(student["FMSScores"][id]).forEach((area) => {
        if ("Score" in area && area.Score !== 0) {
          count += 1;
        }
      });

      if (count === fmsAreas.length) {
        finished = true;
      }
    }

    return finished;
  };

  const countFinishedStudents = (students) => {
    let total = 0;
    students.forEach((student) => {
      if (checkIfStudentIsFinished(student)) {
        total += 1;
      }
    });

    return total;
  };

  const saveScores = async () => {
    let promises = [];
    students.forEach((student) => {
      promises.push(updateStudent(data2.Guid, student));
    });
    await Promise.all(promises);
    await getFMSDetails(data2);
  };

  const cancelScores = async () => {
    getFMSDetails(data2);
  };
  return (
    <div className="dashboard-body">
      <Container>
        <h1 className="pageTitle title-margin">FMS: {details.Title}</h1>
        <br />
        <Tabs className="manage-school-tabs" fill variant="pills">
          <Tab eventKey="help" title="About">
            {loading ? (
              <div className="custom-loader" />
            ) : (
              <div>
                <div className="structured-lesson-video-box">
                  <div className=" structured-lesson-video ">
                    <div className=" video-responsive-structured">
                      <iframe
                        src={details.VideoLink}
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                  </div>
                </div>
                <Container className="content-item-container content-item-container-variant-1 ">
                  <br />

                  <div className=" px-3">
                    <div className="structured-content-text">
                      {details.Description}
                    </div>
                    <br />

                    <h2 className="structured-content-heading">Hints</h2>
                    <br />
                    <br />
                    <h2 className="structured-content-heading">Resources</h2>
                  </div>
                  <br />
                </Container>
              </div>
            )}
          </Tab>
          <Tab eventKey="students" title="Students">
            {loading ? (
              <div className="custom-loader" />
            ) : (
              <div>
                <div className="fms-button-container">
                  <h4 className="pageTitle title-margin">{details.Title}</h4>
                  <h4
                    className={`title-margin ms-3 ${
                      countFinishedStudents(students) === students.length
                        ? "text-green"
                        : "text-red"
                    }`}
                  >
                    {`${countFinishedStudents(students)} /  ${students.length}`}
                  </h4>

                  <button
                    className="custom-button-big custom-button-variant-good ms-auto me-3"
                    onClick={saveScores}
                  >
                    Save
                  </button>
                  <button
                    className="custom-button-big custom-button-variant-bad"
                    onClick={cancelScores}
                  >
                    Cancel
                  </button>
                </div>

                <Accordion
                  alwaysOpen
                  defaultActiveKey={[0, 1, 2, 3, 4]}
                  //   className="content-item-container content-item-container-variant-1 "
                >
                  {students.map((student, i) => {
                    return (
                      <Accordion.Item eventKey={i} key={i}>
                        <Accordion.Header>
                          <div
                            className={`${
                              checkIfStudentIsFinished(student)
                                ? "text-green"
                                : "text-red"
                            }`}
                          >
                            {student.Name}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th>Cateogry</th>
                                <th>Task</th>
                                <th>Score</th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fmsAreas.map((fms, j) => (
                                <FMSItem
                                  comment={verifyStudentFMSComment(
                                    student,
                                    id,
                                    fms.Guid
                                  )}
                                  setComment={(comment) =>
                                    updateStudentFMSComment(
                                      comment,
                                      id,
                                      fms.Guid,
                                      student
                                    )
                                  }
                                  score={verifyStudentFMSScore(
                                    student,
                                    id,
                                    fms.Guid
                                  )}
                                  setScore={(score) =>
                                    updateStudentFMSScore(
                                      score,
                                      id,
                                      fms.Guid,
                                      student
                                    )
                                  }
                                  key={j}
                                  description={fms.Description}
                                  category={fms.Category}
                                />
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            )}
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default FMSPage;
