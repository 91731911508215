import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { auth, UploadClassName, UploadUserYear } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { useNavigate } from "react-router";

function ClassroomDetailsPage() {
  const [className, setClassName] = useState("");
  const [yearGroup, setYearGroup] = useState(0);
  const [classNameError, setClassNameError] = useState("");
  const {data} = useContext(UserContext);

  const navigate = useNavigate();

  const handleValidation = async (event) => {
    let formIsValid = true;

    if (className == "") {
      formIsValid = false;
      setClassNameError("Please enter a name");
      return false;
    } else {
      setClassNameError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  useEffect(() => {
    document.title = "Daly Exercise+ | Classroom Details";
  }, [data])

  const registerSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      data.Year = yearGroup;
      let result2 = await UploadClassName(data.ClassId, className);
      if (result2) {
        navigate(-1);
      } else {
        setClassNameError("Something went wrong, please try again");
      }
    }
  };

  return (
    <Container className="register-form-box full-page-length">
      <h2 className="pageTitle title-margin">
        We just need a name for your classroom!
      </h2>

      <h5 className="page-subtext title-margin">
        You can always change this later.
      </h5>
      <Form onSubmit={registerSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Classroom name: </Form.Label>
          <Form.Control
            placeholder="Name"
            aria-describedby="emailHelpBlock"
            onChange={(event) => setClassName(event.target.value)}
          />
          <Form.Text id="emailHelpBlock" muted>
            {classNameError}
          </Form.Text>
        </Form.Group>
        <div className="d-grid">
          <Button variant="primary" type="submit" className="mainButton">
            Save
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default ClassroomDetailsPage;
