import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { v4 as uuidv4 } from "uuid";

import { httpsCallable } from "firebase/functions";

import {
  firestoreDb,
  functions,
  getCategoryNew,
  getSubCategoryNew,
} from "../firebase";
import { getEmptySchool } from "../models/school";
import { updateSubscriptionAdminUsers } from "./subscriptionFunctions";

export async function getAllLessonsByYear(year) {
  try {
    const q = query(
      collection(firestoreDb, `lessons`),
      where("Year", "==", year)
    );
    let snapshot = await getDocs(q);
    let lessons = snapshot.docs.map((doc) => doc.data());
    return lessons;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function checkIfSubcategoryIsEmpty(subcategoryLessons, year) {
  try {
    const chunkSize = 20;
    const queries = [];

    // Step 1: Split the input array into chunks of up to 10 elements
    for (let i = 0; i < subcategoryLessons.length; i += chunkSize) {
      const chunk = subcategoryLessons.slice(i, i + chunkSize);
      const q = query(
        collection(firestoreDb, `lessons`),
        where("Year", "==", year),
        where("Guid", "in", chunk)
      );
      queries.push(getDocs(q).then((s) => s.empty)); // Store each query promise
    }

    let res = await Promise.all(queries);

    return res.every((value) => value === true || value === undefined);
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function checkIfCategoryIsEmpty(category, year) {
  try {
    // if (year < 6) {
    //   return false;
    // }
    let promises = [];
    category.SubCategories.forEach((sub) => {
      promises.push(
        getSubCategoryNew(sub).then((res) => {
          if (res) {
            return checkIfSubcategoryIsEmpty(res.Lessons, year);
          }
        })
      );
    });
    let res = await Promise.all(promises);
    return res.every((value) => value === true || value === undefined);
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function checkIfStrandIsEmpty(strand, year) {
  try {
    if (year < 6) {
      return false;
    } else if (strand.Title === "NEW PE GAMES!") {
      return false;
    }
    return true;
    let promises = [];
    strand.Categories.forEach((cat) => {
      if (cat) {
        promises.push(
          getCategoryNew(cat).then((res) => {
            if (res) {
              return checkIfCategoryIsEmpty(res, year);
            }
          })
        );
      }
    });
    let res = await Promise.all(promises);

    return res.every((value) => value === true || value === undefined);
  } catch (e) {
    console.log(e);
    return false;
  }
}
