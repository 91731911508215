import { useEffect, useRef } from "react";
import { useState } from "react";
import { Container, Button, Stack } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getImageUrl, getLessonById } from "../firebase";
import { useInView } from "react-intersection-observer";

function WorksheetBox(props) {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const loadResource = async () => {
    const link = document.createElement("a");
    link.href = props.worksheet.url;
    link.target = "_blank";
    link.download = props.worksheet.title;
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Container fluid className="worksheet-box">
      <div className="worksheet-box-title">Download</div>
      <Stack direction="horizontal" className="worksheet-url-box">
        <Stack direction="horizontal" onClick={() => loadResource()}>
          <span className="material-symbols-outlined ">download</span>
          <div target="_blank">{props.worksheet.title}</div>
          
        </Stack>
        {props.delete ? (
            <div className="ms-auto">
              <span
                className="material-symbols-outlined"
                onClick={(e) => props.deleteFunction()}
              >
                close
              </span>
              
            </div>
          ) : (
            <div></div>
          )}
      </Stack>
    </Container>
  );
}

export default WorksheetBox;
