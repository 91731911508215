import { useEffect } from "react";
import { useState } from "react";
import { Container, Button, Card, Row, Form } from "react-bootstrap";
import { useParams } from "react-router";
import {
  getLessonById,
  getMBCategoryDetailsFromId,
  getMovementBreakById,
  getStrandDetailsFromUnitId,
  uploadToSchedule,
} from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { useNavigate } from "react-router-dom";

function AddToSchedulePage(props) {
  const navigate = useNavigate();
  const [lesson, setLesson] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const { data } = useContext(UserContext);
  let { id } = useParams();
  useEffect(() => {
    document.title = "Daly Exercise+ | Add To Schedule";
    if ("UserName" in data) {
      if (Object.keys(lesson).length === 0) {
        if (props.Type === "Lesson") {
          getLessonById(id).then((data) => {
            if (data) {
              setLesson(data);
            }
            setImageUrl(
              `https://img.youtube.com/vi${data.Url.substring(
                data.Url.lastIndexOf("/")
              )}/maxresdefault.jpg`
            );
          });
        } else {
          getMovementBreakById(id).then((data) => {
            if (data) {
              setLesson(data);
            }
            setImageUrl(
              `https://img.youtube.com/vi${data.Url.substring(
                data.Url.lastIndexOf("/")
              )}/sddefault.jpg`
            );
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const addToSchedule = async (e) => {
    let strandData = {};
    if (props.Type === "Lesson") {
      strandData = await getStrandDetailsFromUnitId(lesson.UnitGuid, data.Year);
    } else {
      strandData = await getMBCategoryDetailsFromId(lesson.CategoryGuid);
    }

    let item = {
      Title: lesson.Title,
      Type: props.Type,
      Guid: uuidv4().toString(),
      Date: startDate.toJSON(),
      Description: lesson.Description,
      ContentId: lesson.Guid,
      StrandColourName: strandData.ColourName,
      StrandImageUri: strandData.ImageUri,
    };
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    const day = startDate.getDate();
    await uploadToSchedule(item, data.Guid, year, month + 1, day);
    navigate("/schedule");
  };

  return (
    <Container className="full-page-length">
      <h1 className="pageTitle title-margin">Add to Schedule</h1>
      <hr />
      <div className="d-flex justify-content-md-center m-1">
        <Card style={{ width: "40rem" }}>
          <Card.Img
            variant="top"
            src={imageUrl}
            style={{ objectFit: "cover" }}
          />
          <div className="m-3">
            <Card.Title className="text-center headingColour">
              {lesson.Title}
            </Card.Title>
            <Form.Group controlId="dob">
              <Form.Label>Select Date</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                placeholder="Date"
                selected={startDate}
                onChange={(e) => setStartDate(new Date(e.target.value))}
              />
            </Form.Group>
            <br />
            <div className="d-grid">
              <Button variant="primary" onClick={addToSchedule}>
                Save
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </Container>
  );
}

export default AddToSchedulePage;
