import { useEffect } from "react";

const CustomCard = (props) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  return (
    <div className={`custom-card custom-card-variant-${props.style ? props.style : 0}`}>
      <div className={`custom-card-title custom-card-title-variant-${props.style ? props.style : 0}`}>{props.title}</div>


      {props.loading ? (
        <div className="custom-loader"></div>
      ) : (
        <div className="">
          <div className={`custom-card-text custom-card-text-variant-${props.style ? props.style : 0}`}>{props.text}</div>
          <button
            disabled={props.buttonDisabled}
            className={`custom-card-button custom-card-button-variant-${props.style ? props.style : 0}`}
            onClick={props.callToAction}
          >
            {props.buttonText}
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomCard;
