import Stripe from "stripe";
import { getSchoolDetails } from "./schoolFunctions";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import { httpsCallable } from "firebase/functions";

import { firestoreDb, functions } from "../firebase";
const stripe = new Stripe(
  "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"
);

export async function createSubscriptionPortalLink(schoolID) {
  let schoolDetails = await getSchoolDetails(schoolID);
  const ref = schoolDetails.StripeCustomerRef;
  try {
    const session = await stripe.billingPortal.sessions.create({
      customer: ref,
      return_url: window.location.href,
    });
    return session.url;
  } catch (e) {
    console.log(e);
  }
}

export async function updateSubscriptionAdminUsers(ref, adminUsers) {
  try {
    let res = await updateDoc(ref, { AdminID: adminUsers });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getSchoolSubscription(ref) {
  if (ref === "") {
    return {
      status: "Not Subscribed",
    };
  }

  const q = query(
    collection(ref, `subscriptions`),
    where("status", "in", ["trialing", "active"])
  );
  return getDocs(q)
    .then((snapshot) => {
      let doc = snapshot.docs[0].data();
      let docId = snapshot.docs[0].id;
      return {
        id: docId,
        status: doc.status,
        quantity: doc.items[0].quantity,
        price: doc.items[0].price.unit_amount,
        interval: doc.items[0].price.recurring.interval,
        created: doc.date ? doc.date : doc.created,
        isFree: doc.free,
        nextPaymentDue: doc.current_period_end,
      };
    })
    .catch((error) => {
      console.log(error);
      return {};
    });
}

export async function getExactSchoolSubscription(ref) {
  if (ref === "") {
    return {
      status: "Not Subscribed",
    };
  }
  return getDoc(ref)
    .then((snapshot) => {
      let doc = snapshot.data();
      let docId = snapshot.id;
      return {
        id: docId,
        status: doc.status,
        quantity: doc.items[0].quantity,
        price: doc.items[0].price.unit_amount,
        interval: doc.items[0].price.recurring.interval,
        created: doc.date ? doc.date : doc.created,
        isFree: doc.free,
        nextPaymentDue: doc.current_period_end,
      };
    })
    .catch((error) => {
      console.log(error);
      return {};
    });
}

export async function getUserStripeId(ID) {
  try {
    const q = query(doc(firestoreDb, `customers/${ID}`));
    let snapshot = await getDoc(q);
    let details = snapshot.data();
    return details.stripeId;
  } catch (e) {
    console.log(e);
    return "";
  }
}

export async function createSubscriptionRef(userID) {
  try {
    const q = doc(firestoreDb, `customers/${userID}`);
    return q;
  } catch (e) {
    console.log(e);
    return "";
  }
}
