export const getEmptyWorkout = () => {
  let workout = {
    Guid: "",
    Sets: 1,
    Name: "",
    CreationDate: new Date(),
    Exercises: [],
    Thumbnail: "",
  };

  return workout;
};
